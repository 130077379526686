import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useScanQrPopup } from '@vkruglikov/react-telegram-web-app';
import { NoAssets } from '@pages/Send/NoAssets';
import { MyAccounts } from '@pages/Send/MyAccounts';

//constants
import { ethRegex, PageRoutes, zeroAddress } from '../../constants';

//helpers
import { greaterThan } from '@helpers/bignumber';
import { toWei } from '@helpers/numbers';
import { getEllipsisTxt } from '@helpers/formatters';

//hooks
import { useDebounce } from '@hooks/useDebounce';
import { useResponseHandler } from '@hooks/useResponseHandler';
import { useStores } from '@hooks/useStores';
import { withLayout } from '../../layouts/LayoutOrderPage/WithLayout';

//img
import arrow from '@assets/icons/arrow-down.svg';
import qrScan from '@assets/icons/qr-scan.svg';

//types
import { InputShape } from '@interfaces/input.types';
import { ApiGasPrice, GasPrice, IAsset } from '../../types';
import { SendFormParams } from '@pages/Send/Send';

//components
import { WalletIdentity } from '@components/common/WalletIdentity';
import { SideGradientWrapper } from '@components/common/SideGradientWrapper';
import { Button } from '@components/common/Button';
import { Input } from '@components/common/Input';
import { NetworkSelection } from '@components/NetworkSelection';
import { Preloader } from '@components/common/Preloader';
import { chainToken } from '@helpers/chains';
import CoinSelection from '@components/CoinSelection';
import { TokenSelect } from '@components/TokenSelect';

const SendForm = observer((props: SendFormParams) => {
  const {
    network,
    setNetwork,
    asset,
    nativeToken,
    address,
    setAddress,
    numericAmount,
    setAmount,
    assets,
    assetSend,
    accounts,
    handleSetAsset,
    portfolio,
  } = props;
  const { accountStore } = useStores();
  const [show, close] = useScanQrPopup();
  const { address: accountAddress } = accountStore;
  const navigate = useNavigate();
  const [selectedGas, setSelectedGas] = useState<GasPrice | null>(null);
  const [selectedAsset, setSelectedAsset] = useState<IAsset | null>(null);
  const [gas, setGas] = useState<ApiGasPrice | null>(null);
  const [isGasLoaded, setIsGasLoaded] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null); // Track selected account
  const handleResponse = useResponseHandler();
  const debounceAmount = useDebounce(numericAmount, 1000);

  const isAddressValid = useCallback((address: string): boolean => {
    const ethRegex = /^0x[a-fA-F0-9]{40}$/;
    return ethRegex.test(address);
  }, []);

  const showAccountsList = !selectedAccount && !isAddressValid(address);

  useEffect(() => {
    if (isAddressValid(address) && debounceAmount && !new BigNumber(debounceAmount).eq(0)) {
      const tokenPrice = assetSend?.price || 2354.12;
      const tokenAmount = new BigNumber(numericAmount).div(asset?.price || tokenPrice);
      setIsGasLoaded(false);
      accountStore
        .calculateGas({
          walletAddress: accountAddress,
          tokenAmount: toWei(tokenAmount.toString(), asset.decimals),
          tokenAddress: asset.contractAddress || zeroAddress,
          network,
          receiverAddress: address,
        })
        .then((response) => {
          setIsGasLoaded(true);
          if (!response?.gasPrice) {
            handleResponse(response);
            setGas(null);
            return;
          }
          setGas(response);
          setSelectedGas(response.gasPrice.medium);
        })
        .catch((e) => {
          console.log(e, 'er');
          setIsGasLoaded(true);
          handleResponse(e);
        });
    } else {
      setGas(null);
    }
  }, [
    address,
    accountAddress,
    debounceAmount,
    asset,
    network,
    isAddressValid,
    accountStore,
    handleResponse,
    nativeToken,
  ]);

  const onScan = (result: string) => {
    let value = result;
    if (result.split(':').length > 1) {
      value = result.split(':')[1];
    }
    if (isAddressValid(value)) {
      setSelectedAccount(value);
      setAddress(value);
      close();
    }
  };

  const feeAmount = useMemo(() => {
    return gas && selectedGas
      ? new BigNumber(gas.optimalGasLimit)
          .times(selectedGas.gwei)
          .times(10 ** 9)
          .div(10 ** 18)
          .toString()
      : null;
  }, [gas, selectedGas]);

  const isInvalidAmount = useMemo(() => {
    return !!numericAmount && !!asset?.quantity && greaterThan(numericAmount, asset.quantity);
  }, [numericAmount, asset]);

  const isEnoughAmount = useMemo(() => {
    if (!feeAmount) {
      console.log('Fee amount is missing');
      return 0;
    }
    if (!nativeToken) {
      console.log('Native token is missing');
      return 0;
    }

    const reqAmount =
      asset.assetId === nativeToken.assetId
        ? new BigNumber(numericAmount || 0).plus(feeAmount || 0).toString()
        : feeAmount || 0;

    console.log('Required amount for transaction:', reqAmount);
    console.log('Native token quantity:', nativeToken.quantity);

    return new BigNumber(nativeToken.quantity).minus(reqAmount).toNumber();
  }, [asset, numericAmount, nativeToken, feeAmount]);

  const isNativeTokenEnough = useMemo(() => isEnoughAmount >= 0, [isEnoughAmount]);

  const handleSubmit = async () => {
    if (!isAddressValid(address) || !isNativeTokenEnough || !numericAmount) {
      return;
    }
    navigate(PageRoutes.SEND_CONFIRM, {
      state: { amount: numericAmount, address, network, assetId: asset.assetId, gas, selectedGas },
    });
  };

  const handleAccountSelect = (accountId: string) => {
    setSelectedAccount(accountId);
    setAddress(accountId);
  };

  const handleSetAdress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const address = event.target.value;
    setAddress(address);
    if (isAddressValid(address)) {
      setSelectedAccount(address);
    }
  };

  const handleTokenSelectChange = (selectedToken: IAsset) => {
    setSelectedAsset(selectedToken); // Зберігаємо вибраний токен
    setAmount(''); // Очищаємо попередню кількість після зміни токена
  };

  const sendAccounts = accounts?.filter(
    (account) => account.blockchain === network && account.address !== accountAddress
  );

  return (
    <SideGradientWrapper className="vh-100 py-3 gap-0">
      <div className="flex-grow-1 d-flex flex-column">
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-2">
            <span className="tx-14">From</span>
            <NetworkSelection network={network} onChange={setNetwork} buttonClassName="w-100">
              <div className="card w-100 rounded-3 p-2 flex-row gap-2">
                <WalletIdentity address={getEllipsisTxt(accountAddress, 8, '0x')}>
                  <img src={arrow} alt="Arrow" className="me-1" />
                </WalletIdentity>
              </div>
            </NetworkSelection>
            {selectedAccount && (
              <TokenSelect
                assets={assets}
                address={address}
                network={network}
                assetSend={assetSend}
                onChange={handleSetAsset}
                portfolio={portfolio}
                setAmount={setAmount}
              />
            )}
          </div>

          {/* To */}
          <div className="d-flex flex-column gap-2">
            <span className="tx-14">To</span>
            {!selectedAccount ? (
              <Input
                value={address}
                onChange={handleSetAdress}
                // onChange={(e) => setSelectedAccount(e.target.value)}
                shape={InputShape.Default}
                placeholder="Enter public address (Ox) or ENS name"
                icon={
                  <img
                    src={qrScan}
                    alt="QR Scan"
                    className="wd-25 ht-25"
                    onClick={() => show({ text: 'Scan address QR code' }, onScan)}
                  />
                }
              />
            ) : (
              <div className="d-flex flex-column gap-2">
                <div className="card w-100 rounded-3 p-2 flex-row gap-2">
                  <WalletIdentity address={getEllipsisTxt(selectedAccount || accountAddress, 8, '0x')}>
                    <img src={arrow} alt="Arrow" className="me-1" />
                  </WalletIdentity>
                </div>
                {/* <NetworkSelection network={network} onChange={setNetwork} buttonClassName="w-100">
                  <div className="card w-100 rounded-3 p-2 flex-row gap-2">
                    <WalletIdentity address={getEllipsisTxt(selectedAccount || accountAddress, 8, '0x')}>
                      <img src={arrow} alt="Arrow" className="me-1" />
                    </WalletIdentity>
                  </div>
                </NetworkSelection> */}
                {/* <TokenSelect
                  disabled={true}
                  portfolio={portfolio}
                  address={address}
                  cryptoRate={asset?.price}
                  setAmount={setAmount}
                  assets={assets}
                  network={network}
                  // onClick={() => console.log('test')}
                  onChange={handleSetAsset}
                /> */}
              </div>
            )}
          </div>
        </div>

        {/* Conditionally show accounts list */}
        {showAccountsList && sendAccounts?.length ? (
          <MyAccounts onAccountSelect={handleAccountSelect} accounts={sendAccounts} />
        ) : (
          <>
            {!isNativeTokenEnough && (
              <div className="card bg-transparent border border-solid border-danger tx-muted d-block mt-4">
                <div>You have not enough {chainToken(network)} to pay the transaction fee.</div>
                <div>You need more for gas to complete this swap</div>
              </div>
            )}
          </>
        )}

        <div className="position-fixed bottom-0 start-0 end-0 p-3">
          {isGasLoaded ? (
            <Button
              className="w-100"
              // disabled={!gas || !numericAmount || !isAddressValid(address) || !isNativeTokenEnough}
              disabled={!isAddressValid(address) || !isNativeTokenEnough || !numericAmount}
              onClick={handleSubmit}
            >
              Review
            </Button>
          ) : (
            <Preloader text="Calculating Gas..." />
          )}
        </div>
      </div>
    </SideGradientWrapper>
  );
});

export default SendForm;
