import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FormattedNumber } from '@components/common/FormattedNumber';
import { CopyButton } from '@components/common/CopyButton';
import dLogo from '@assets/images/logo/logo-dark.svg';

import { ReactComponent as Support } from '@assets/icons/support.svg';
import { ReactComponent as ArrowUp } from '@assets/icons/arrow-t-up.svg';
import { ReactComponent as Swap } from '@assets/icons/swap.svg';
import { ReactComponent as TrendUp } from '@assets/icons/trend-up.svg';

import styles from '@styles/components/modules/wallet.module.scss';

// helpers
import { getEllipsisTxt } from '@helpers/formatters';
import { isNegative } from '@helpers/bignumber';

// Types
import { WalletStats } from '../../../types';
import { ButtonShape, ButtonSize, ButtonType } from '@interfaces/button.types';

// constants
import { PageRoutes } from '../../../constants';

//components
import { Button } from '@components/common/Button';

interface WalletCardProps {
  address: string;
  portfolio: WalletStats | null;
}

const actions = [
  {
    route: PageRoutes.RECEIVE,
    Icon: ArrowUp,
    label: 'Receive',
  },
  {
    route: PageRoutes.BRIDGE,
    Icon: Swap,
    label: 'Bridge',
  },
  {
    route: PageRoutes.SEND,
    Icon: ArrowUp,
    label: 'Send',
  },
  {
    route: PageRoutes.WALLET,
    Icon: Support,
    label: 'Support',
  },
];

export const WalletCard = ({ address, portfolio }: WalletCardProps) => {
  const navigate = useNavigate();
  const isNegativeValue = isNegative(portfolio?.totalChangePercent1d!);

  return (
    <div className={styles.walletCard}>
      <div className={styles.blurWhiteHeader} />
      <div className="d-flex align-items-center justify-content-between position-relative">
        <CopyButton text={address}>
          <div className="tx-14 tx-normal">{getEllipsisTxt(address, 4)}</div>
        </CopyButton>
        <img className="bg-black rounded-circle p-1" src={dLogo} alt="logo" />
      </div>
      <div>
        <FormattedNumber
          value={portfolio?.totalBalance}
          decimals={2}
          subZeros
          className="tx-32 tx-bold"
          suffix="$"
          floor
        />
        {portfolio?.totalChange1d !== undefined && (
          <div className="tx-normal tx-14">
            <TrendUp
              className={classNames('me-2', {
                'tx-danger mirror-vertical': isNegativeValue,
                'tx-success': !isNegativeValue,
              })}
            />
            <FormattedNumber value={portfolio?.totalChange1d} decimals={2} className="me-1" suffix="$" withSign floor />
            <FormattedNumber value={portfolio?.totalChangePercent1d} decimals={2} suffix="(" postfix="%)" floor />
          </div>
        )}
      </div>
      <div className="d-flex gap-4 justify-content-between mx-2 mt-3 flex-1">
        {actions.map(({ route, label, Icon }) => (
          <div key={label} className="d-flex flex-column align-items-center gap-2">
            <Button
              type={ButtonType.SecondaryDark}
              shape={ButtonShape.Round}
              size={ButtonSize.Auto}
              onClick={() => navigate(route)}
              icon={<Icon />}
              className={classNames('p-3', { 'rotate-180': label === 'Receive' })}
            />

            <span className="tx-14 tx-normal">{label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
