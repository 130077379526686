import React from 'react';

import { CoinModal } from '@pages/Bridge/components/CoinModal';

//hooks
import { useModal } from '@hooks/useModal';
import { useNotification } from '@hooks/useNotification';

//helpers
import {
  allowedChains,
  chainLogo,
  excludedEvmChainList,
  excludedSolChainList,
  excludedTonChainList,
} from '@helpers/chains';
import { smallerThan } from '@helpers/bignumber';

//constants
import { ChainId } from '../../../constants';

//types
import type { UseBridgeStateReturn } from '@pages/Bridge/hooks/useBridgeState';
import type { ExchangeRange } from '@pages/Bridge/hooks/useFetchExchangeRange';
import type { AccountChain, Currency, IAsset, SwapData } from '../../../types';
import { ButtonShape, ButtonSize, ButtonType } from '@interfaces/button.types';

//img
import arrow from '@assets/icons/arrow-down.svg';
import { ReactComponent as Swap } from '@assets/icons/swap.svg';

//components
import { NetworkSelection } from '@components/NetworkSelection';
import { Button } from '@components/common/Button';
import { FormattedNumber } from '@components/common/FormattedNumber';

const coinImgStyle: React.CSSProperties = {
  objectFit: 'scale-down',
};

interface BridgeSelectNetworkProps {
  onChange: (arg: ChainId) => void;
  network: ChainId;
  excludedChainList?: ChainId[];
}

interface BridgeWidgetProps extends UseBridgeStateReturn {
  fromAssets: IAsset[];
  toCurrencyList: Currency[];
  swapData?: SwapData;
  toCoinIcon?: string;
  accountChain: AccountChain;
  rangeData: ExchangeRange | null;
  isLoadingRangeData: boolean;
  setNetwork: (arg: ChainId) => void;
}

const BridgeSelectNetwork = ({ onChange, network, excludedChainList }: BridgeSelectNetworkProps) => {
  const excludedChainsMap: { [key in ChainId]?: ChainId[] } = {
    [ChainId.SOL]: excludedSolChainList,
    [ChainId.TON]: excludedTonChainList,
  };

  const excludedChainsList = excludedChainsMap[network] || excludedEvmChainList;

  return (
    <div className="col-6">
      <NetworkSelection
        network={network}
        onChange={onChange}
        isHideCoinIcon
        hasHeader={false}
        excludedChains={excludedChainList ?? excludedChainsList}
      >
        <div className="d-flex flex-column">
          <span className="tx-muted tx-14">Network</span>
          <span className="d-flex align-items-center gap-2">
            <span className="network-name text-truncate">{network}</span>
            <img src={arrow} alt="Choose network" className="pe-1" />
          </span>
        </div>
      </NetworkSelection>
    </div>
  );
};

export const BridgeWidget = ({
  fromCurrency,
  toCurrency,
  toNetwork,
  fromNetwork,
  amount,
  swapData,
  onFromChainChange,
  onFromCoinChange,
  onToCoinChange,
  onAmountChange,
  excludedToChainList,
  onToChainChange,
  fromAssets,
  toCoinIcon,
  accountChain,
  toCurrencyList,
  rangeData,
  isLoadingRangeData,
  setNetwork,
}: BridgeWidgetProps) => {
  const { isOpen: isOpenFromCoin, toggleModal: toggleFromCoin } = useModal();
  const { isOpen: isOpenToCoin, toggleModal: toggleToCoin } = useModal();
  const notify = useNotification();

  if (!fromNetwork || !fromCurrency || !toNetwork) {
    return null;
  }

  const hasAssets = Boolean(fromAssets.length);
  const fromAsset = fromAssets.find((asset) => asset.assetSymbol === fromCurrency);
  const isFromNetworkEqualAssetChain = fromNetwork === fromAsset?.assetChain;

  const showError = (message: string, duration: number = 4000) => {
    notify(message, {
      duration,
      type: 'popup-danger',
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!Number(fromAsset?.quantity ?? 0)) {
      showError('Insufficient balance. The amount entered exceeds your available cryptocurrency balance.', 2000);

      return;
    } else if (smallerThan(fromAsset?.quantity ?? 0, value)) {
      showError('You do not have enough balance for this amount.', 2000);

      return;
    }

    onAmountChange(value);
  };

  const handleMaxClick = () => {
    if (!hasAssets) {
      return;
    }

    onAmountChange(fromAsset?.quantity ?? '0');
  };

  const handleMinClick = () => {
    if (isLoadingRangeData) {
      return;
    }

    onAmountChange(rangeData?.minAmount.toString() ?? '0');
  };

  const handleReverseSwap = () => {
    const hasReversedCoin = fromAssets.find((asset) => asset.assetSymbol === toCurrency);

    if (!allowedChains[accountChain].includes(toNetwork)) {
      showError(`Network ${fromNetwork} does not contain network ${toNetwork}.`);
      return;
    }

    if (!hasReversedCoin) {
      showError('Cannot reverse the swap as you don’t hold the target currency in your wallet.');
      return;
    }

    onAmountChange('0');
    onToCoinChange(fromCurrency);
    onToChainChange(fromNetwork);
    onFromCoinChange(hasReversedCoin.assetSymbol);
    onFromChainChange(hasReversedCoin.assetChain);
  };

  const handleFromChainChange = (chainId: ChainId) => {
    setNetwork(chainId);
    onFromChainChange(chainId);
  };

  return (
    <div className="card rounded-20">
      <div className="row my-3">
        <div className="col-6">
          <Button
            onClick={hasAssets ? toggleFromCoin : undefined}
            type={ButtonType.SecondaryLight}
            size={ButtonSize.Auto}
            icon={
              <img
                src={
                  fromAsset?.imageUrl ??
                  (fromNetwork === ChainId.BASE ? chainLogo(ChainId.ETHER) : chainLogo(fromNetwork))
                }
                alt="Coin icon"
                height={25}
                width={25}
                loading="lazy"
                className="rounded-circle"
                style={coinImgStyle}
              />
            }
            className="p-2"
          >
            <span className="tx-medium tx-16">{fromCurrency}</span>
            <img src={arrow} alt="Choose network" className="pe-1" />
          </Button>
        </div>
        <BridgeSelectNetwork onChange={handleFromChainChange} network={fromNetwork} />
      </div>
      <div className="d-flex align-items-center gap-2 tx-muted tx-14 tx-normal">
        <div className="d-flex align-items-center gap-1 flex-1">
          <span>
            <button className="btn btn-transparent tx-success p-0" onClick={handleMaxClick}>
              Max
            </button>
            <button className="btn btn-transparent tx-success p-0" onClick={handleMinClick}>
              Min
            </button>
          </span>
          <input
            disabled={isLoadingRangeData}
            type="text"
            className="form-control appearance-none py-0 ps-2 tx-20 tx-normal bg-transparent tx-muted border-0 text-start"
            value={amount}
            onChange={handleInputChange}
          />
        </div>
        <div className="d-flex flex-column">
          <span className="tx-muted tx-14">Balance</span>
          <FormattedNumber
            value={isFromNetworkEqualAssetChain ? (fromAsset?.quantity ?? 0) : 0}
            decimals={2}
            subZeros
            postfix={isFromNetworkEqualAssetChain ? fromAsset?.assetSymbol : undefined}
            floor
            className="flex-1 text-end"
          />
        </div>
      </div>

      <div className="d-flex align-items-center my-1 position-relative">
        <div className="flex-grow-1 ht-1 bg-gray-500" />

        <Button
          onClick={handleReverseSwap}
          shape={ButtonShape.Round}
          type={ButtonType.SecondaryLight}
          size={ButtonSize.Auto}
          icon={<Swap className="wd-30 ht-30 tx-muted" />}
        />

        <div className="flex-grow-1 ht-1 bg-gray-500" />
      </div>

      <div className="row my-3">
        <div className="col-6">
          <Button
            onClick={toggleToCoin}
            type={ButtonType.SecondaryLight}
            size={ButtonSize.Auto}
            icon={
              <img
                src={toCoinIcon}
                alt="Coin icon"
                height={25}
                width={25}
                loading="lazy"
                className="rounded-circle"
                style={coinImgStyle}
              />
            }
            className="p-2"
          >
            <span className="tx-medium tx-16">{toCurrency}</span>
            <img src={arrow} alt="Choose network" className="pe-1" />
          </Button>
        </div>
        <BridgeSelectNetwork onChange={onToChainChange} network={toNetwork} excludedChainList={excludedToChainList} />
      </div>
      <div className="d-flex align-items-center gap-2 tx-muted tx-14 tx-normal">
        <div className="flex-1">
          <FormattedNumber
            value={Number(amount) === 0 ? 0 : swapData?.estimatedAmount.amount}
            decimals={5}
            floor
            useSpinner
          />
        </div>
        <div className="d-flex flex-column">
          <span className="tx-muted tx-14">USD</span>
          <FormattedNumber
            value={swapData?.estimatedAmount.amountUsd ?? 0}
            decimals={2}
            subZeros
            suffix="$"
            floor
            className="flex-1 text-end"
          />
        </div>
      </div>
      <CoinModal
        show={isOpenFromCoin}
        onClose={toggleFromCoin}
        data={fromAssets}
        currentCoin={fromCurrency}
        onCoinChange={onFromCoinChange}
      />
      <CoinModal
        show={isOpenToCoin}
        onClose={toggleToCoin}
        data={toCurrencyList}
        currentCoin={toCurrency}
        onCoinChange={onToCoinChange}
      />
    </div>
  );
};
