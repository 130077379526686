import { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import { TabsCard } from '@components/common/TabsCard';

import { ButtonShape } from '@interfaces/button.types';

import { PageRoutes } from '../../../constants';

import cart from '@assets/icons/cart-color.svg';
import hand from '@assets/icons/hand-color.svg';

interface TradeModalProps {
  show: boolean;
  onClose?: () => void;
}

interface OrderCardProps {
  title: string;
  description: string;
  onClick: () => void;
}

const tabList = [
  { value: 'Buy', imgSrc: cart },
  { value: 'Sell', imgSrc: hand },
];

export const OrderCard = ({ title, description, onClick }: OrderCardProps) => (
  <div className="sell-list-container__card" onClick={onClick}>
    <div className="tx-20 tx-bold">{title}</div>
    <div className="tx-muted tx-14">{description}</div>
  </div>
);

export const TradeModal = memo(({ show, onClose }: TradeModalProps) => {
  const [activeTradeTab, setActiveTradeTab] = useState<'Buy' | 'Sell'>('Buy');
  const navigate = useNavigate();

  const handleMarketOrderNavigation = () => {
    navigate(PageRoutes.TOKEN_SNIPE, { replace: true });
  };

  return (
    <Modal show={show} onHide={onClose} aria-modal="true">
      <Modal.Header closeButton className="p-3 justify-content-end" />
      <Modal.Body className="sell-modal-container">
        <TabsCard
          list={tabList}
          active={activeTradeTab}
          onClick={setActiveTradeTab}
          tabShape={ButtonShape.Pill}
          className="rounded-pill bg-gray-800"
          tabClassName="tx-20 tx-bold p-1"
        >
          <div className="tab-element tab-Buy">
            <div className="sell-list-container">
              <OrderCard
                title="Market Order"
                description="Buy immediately at the current market price."
                onClick={handleMarketOrderNavigation}
              />
              <OrderCard
                title="Limit Order"
                description="Set a specific price to buy. Order executes when the market reaches your price."
                onClick={handleMarketOrderNavigation}
              />
            </div>
          </div>
          <div className="tab-element tab-Sell">
            {' '}
            <div className="sell-list-container">
              <OrderCard
                title="Market Order"
                description="Sell immediately at the current market price."
                onClick={handleMarketOrderNavigation}
              />
              <OrderCard
                title="Limit Order"
                description="Set a specific price to sell. Order executes when the market reaches your price."
                onClick={handleMarketOrderNavigation}
              />
              <OrderCard
                title="Stop Loss Order"
                description="Automatically sell when the price drops to your set level to minimize losses."
                onClick={handleMarketOrderNavigation}
              />
            </div>
          </div>
        </TabsCard>
      </Modal.Body>
    </Modal>
  );
});
