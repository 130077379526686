import { useQuery } from '@tanstack/react-query';
import { apiRaw } from '@helpers/api';

//types
import type { Currency } from '../../../types';
import { ChainId } from '../../../constants';

function currenciesQueryOptions(fromCurrency: string | null, fromNetwork: ChainId | null) {
  return {
    queryKey: ['currencies', fromNetwork],
    queryFn: () =>
      apiRaw<Currency[]>({
        method: 'get',
        path: `/swap/currencies`,
        data: { fromCurrency: fromCurrency?.toLowerCase(), fromNetwork },
      }).then((re) => re.data.data ?? []),
    enabled: !!fromCurrency && !!fromNetwork,
  };
}

export const useFetchCurrencies = (fromCurrency: string | null, fromNetwork: ChainId | null) => {
  return useQuery(currenciesQueryOptions(fromCurrency, fromNetwork));
};
