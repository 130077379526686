// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success_blurGreenHeader__8DhhW {
  position: absolute;
  top: -40px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  background-color: rgba(7, 177, 149, 0.48);
  filter: blur(117px);
}`, "",{"version":3,"sources":["webpack://./src/styles/components/modules/success.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,yCAAA;EACA,mBAAA;AACF","sourcesContent":[".blurGreenHeader {\n  position: absolute;\n  top: -40px;\n  left: calc(50% - 50px);\n  width: 100px;\n  height: 100px;\n  background-color: rgba(7, 177, 149, 0.48);\n  filter: blur(117px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blurGreenHeader": `success_blurGreenHeader__8DhhW`
};
export default ___CSS_LOADER_EXPORT___;
