import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';

export interface IconWithLoadingProps {
  icon?: IconDefinition;
  className?: string;
  isLoading?: boolean;
}

export function IconWithLoading({ icon, className = 'me-1', isLoading = false }: IconWithLoadingProps) {
  return !icon && !isLoading ? null : (
    <FontAwesomeIcon icon={isLoading ? faCircleNotch : icon!} className={className} spin={isLoading} />
  );
}
