import { useEffect, useReducer } from 'react';
import _ from 'lodash';

import { ChainId } from '../../../constants';
import { BridgeActionType, bridgeReducer, type BridgeState, initialState } from '@stores/reducers/bridgeReducer';

//types
import type { Currency, IAsset, UserAccount } from '../../../types';

export interface UseBridgeStateReturn extends BridgeState {
  onFromChainChange: (chain: ChainId) => void;
  onToChainChange: (chain: ChainId) => void;
  onFromChainNoAssetsChange: (chain: ChainId) => void;
  onFromCoinChange: (chain: string) => void;
  onToCoinChange: (chain: string) => void;
  onAmountChange: (amount: string) => void;
  onToChainList: (amount: Currency[], chain: ChainId | null) => void;
}

export const useBridgeState = (account: UserAccount | null, assets: IAsset[]): UseBridgeStateReturn => {
  const [state, dispatch] = useReducer(bridgeReducer, initialState);

  const onFromChainChange = (chain: ChainId) => {
    dispatch({ type: BridgeActionType.SET_FROM_CHAIN, payload: chain });
  };

  const onToCoinChange = (coin: string) => {
    dispatch({ type: BridgeActionType.SET_TO_COIN, payload: coin });
  };

  const onToChainChange = (chain: ChainId) => {
    dispatch({ type: BridgeActionType.SET_TO_CHAIN, payload: chain });
  };

  const onFromCoinChange = (coin: string) => {
    dispatch({ type: BridgeActionType.SET_FROM_COIN, payload: coin });
  };

  const onFromChainNoAssetsChange = (chain: ChainId) => {
    dispatch({ type: BridgeActionType.SET_FROM_CHAIN_NO_ASSETS, payload: chain });
  };

  const onAmountChange = (value: string) => {
    const numberValue = _.toNumber(value);

    if ((_.isFinite(numberValue) && numberValue >= 0) || value === '') {
      dispatch({ type: BridgeActionType.SET_AMOUNT, payload: value });
    }
  };

  const onToChainList = (data: Currency[], chain: ChainId | null) => {
    const chainIdValues = Object.values(ChainId);
    const networksInData = _.uniq(data.map((item) => item.network));
    const filteredChainIds = _.difference(chainIdValues, networksInData);
    const filteredToCoin = _.filter(data, { network: chain ?? networksInData[0] });

    onToCoinChange(filteredToCoin[0]?.symbol ?? null);
    onToChainChange(chain ?? networksInData[0] ?? null);
    dispatch({ type: BridgeActionType.SET_EXCLUDE_LIST, payload: filteredChainIds });
  };

  useEffect(() => {
    if (!account) {
      return;
    }

    if (!assets.length) {
      onFromChainNoAssetsChange(state?.fromNetwork ?? ChainId.ETHER);
    } else {
      onFromChainChange(assets[0].assetChain);
      onFromCoinChange(assets[0].assetSymbol);
    }
  }, [account, assets, state.fromNetwork]);

  return {
    ...state,
    onFromChainChange,
    onToChainChange,
    onFromChainNoAssetsChange,
    onFromCoinChange,
    onAmountChange,
    onToChainList,
    onToCoinChange,
  };
};
