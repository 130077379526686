import { useEffect } from 'react';

//hooks
import { useModal } from '@hooks/useModal';

export const useDisclaimer = () => {
  const { isOpen, closeModal, openModal } = useModal();

  useEffect(() => {
    const hasAgreed = localStorage.getItem('disclaimerAgreed');
    if (!hasAgreed) {
      openModal();
    }
  }, []);

  const handleAgree = () => {
    localStorage.setItem('disclaimerAgreed', 'true');
    closeModal();
  };

  return {
    showDisclaimer: isOpen,
    handleAgree,
  };
};
