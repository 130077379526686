import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import { TokenLogo } from './common/TokenLogo';
import { chainLogo, chainName } from '@helpers/chains';
import { useModal } from '@hooks/useModal';
import { IAsset, WalletStats } from 'types';
import { ChainId } from '../constants';
import arrow from '@assets/icons/arrow-down.svg';

interface TokenSelectProps {
  isHideCoinIcon?: boolean;
  network: ChainId;
  assets: IAsset[];
  address?: string;
  disabled?: boolean;
  assetSend?: IAsset | null;
  children?: React.ReactNode;
  onClick?: () => void;
  onChange: (network: IAsset) => void;
  cryptoRate?: number;
  buttonClassName?: string;
  portfolio: WalletStats | null;
  setAmount: (amount: string) => void;
}

export const TokenSelect = ({
  address,
  disabled,
  assets,
  onClick,
  onChange,
  buttonClassName,
  children,
  setAmount,
  assetSend,
  network,
  isHideCoinIcon,
  portfolio,
}: TokenSelectProps) => {
  const { toggleModal, isOpen, closeModal, openModal } = useModal();

  const [selectedAsset, setSelectedAsset] = useState<IAsset | null>(null); // Вибраний токен
  // const [usdAmount, setUsdAmount] = useState<number>(0);
  const [usdAmount, setUsdAmount] = useState<string>('0');
  const [cryptoRate, setCryptoRater] = useState<number>(selectedAsset?.price || 2354.12);
  const [cryptoAmount, setCryptoAmount] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCryptoRater(selectedAsset?.price || 2354.12);
  }, [selectedAsset, assets]);

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!(e.target as HTMLElement).closest('.arrow-icon')) {
      inputRef.current?.focus();
    }
  };

  const handleChange = (value: IAsset) => {
    setSelectedAsset(value);
    closeModal();
    onChange(value);
  };

  const calculateCryptoAmount = (usdValue: number): number => {
    if (cryptoRate) {
      return usdValue / cryptoRate;
    }
    return 0;
  };

  // const handleUsdChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;

  //   if (value === '') {
  //     setUsdAmount(0);
  //     setAmount('');
  //     setCryptoAmount(0);
  //     return;
  //   }

  //   // const isValid = /^([1-9][0-9]*|0?\.\d+|[1-9][0-9]*\.\d+)$/.test(value);
  //   const isValid = /^(0|[1-9][0-9]*)(\.\d{0,2})?$/.test(value);

  //   if (isValid) {
  //     const numericValue = parseFloat(value) || 0;
  //     setUsdAmount(numericValue);
  //     setAmount(value);
  //     const cryptoValue = calculateCryptoAmount(numericValue);
  //     setCryptoAmount(cryptoValue);
  //   }
  // };

  const handleUsdChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (usdAmount === '0' && value === '0.') {
      setUsdAmount('0.');
      return;
    }

    if (usdAmount === '0' && value !== '.' && value !== '0') {
      value = value.replace('0', '');
    }

    if (value === '') {
      setUsdAmount('0');
      setAmount('');
      setCryptoAmount(0);
      return;
    }

    const isValid = /^(0|[1-9][0-9]*)(\.\d{0,2})?$/.test(value);

    if (isValid) {
      const numericValue = parseFloat(value) || 0;
      setUsdAmount(value);
      setAmount(value);
      const cryptoValue = calculateCryptoAmount(numericValue);
      setCryptoAmount(cryptoValue);
    }
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(0, 0);
    }
  };

  return (
    <>
      <div className="container" onClick={handleContainerClick}>
        {selectedAsset || assetSend ? (
          <div className="d-flex align-items-center">
            <TokenLogo
              logo={selectedAsset?.imageUrl || assetSend?.imageUrl || ''}
              address={selectedAsset?.assetId || assetSend?.assetId || ''}
              name={selectedAsset?.assetName || assetSend?.assetName || ''}
              size={40}
              chain={selectedAsset?.assetChain || assetSend?.assetChain || ''}
            />
            <div className="ms-3">
              <div className="d-flex align-items-center">
                <span className="asset-name me-2">{selectedAsset?.assetName || assetSend?.assetName || ''}</span>
                {!disabled && <img src={arrow} alt="Choose network" className="pe-1 arrow-icon" onClick={openModal} />}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center gap-2 flex-1">
            {!isHideCoinIcon && <img src={chainLogo(network)} alt={network} className="wd-40 ht-40" />}
            <span className="tx-14 tx-normal">{chainName(network)}</span>
            {!disabled && <img src={arrow} alt="Choose network" className="pe-1 arrow-icon" onClick={openModal} />}
          </div>
        )}

        <div className="convert-wrapper">
          <input
            ref={inputRef}
            type="text"
            disabled={disabled}
            placeholder="0"
            // value={usdAmount === 0 ? '' : usdAmount}
            value={usdAmount}
            onChange={handleUsdChange}
            className="usd-input"
            autoFocus={true}
            onFocus={handleFocus}
          />
          <span>
            {cryptoAmount.toFixed(4)} {selectedAsset?.assetSymbol || 'ETH'}
          </span>{' '}
        </div>
      </div>

      <Modal show={isOpen} onHide={toggleModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {assets.map((item) => {
            const { imageUrl, assetId, assetName, assetChain, assetSymbol, value, quantity } = item;
            const formattedQuantity = typeof quantity === 'string' ? parseFloat(quantity) : quantity;
            return (
              <button
                className={classNames(
                  'btn btn-secondary d-flex align-items-center wd-100p mb-3 tx-16 tx-medium rounded-16px bg-gray-900 gap-3 border-transparent'
                )}
                onClick={() => handleChange(item)}
                key={`network-selection-item-${value}`}
              >
                <div className="d-flex align-items-center">
                  <TokenLogo logo={imageUrl} address={assetId} name={assetName || ''} size={40} chain={assetChain} />
                  <div className="ms-3">
                    <div className="d-flex align-items-center">
                      <span className="asset-name me-2">{assetName}</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span className="value-usd">{value.toFixed(2)} USD</span>
                  <span className="quantity-crypto text-muted">
                    {formattedQuantity ? formattedQuantity.toFixed(2) : '0.0000'} {assetSymbol}
                  </span>
                </div>
              </button>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};
