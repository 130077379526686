import { chainToken } from '@helpers/chains';
import { ChainId } from '../../constants';

export enum BridgeActionType {
  SET_FROM_CHAIN = 'SET_FROM_CHAIN',
  SET_FROM_CHAIN_NO_ASSETS = 'SET_FROM_CHAIN_NO_ASSETS',
  SET_FROM_COIN = 'SET_FROM_COIN',
  SET_AMOUNT = 'SET_AMOUNT',
  SET_TO_CHAIN = 'SET_TO_CHAIN',
  SET_TO_COIN = 'SET_TO_COIN',
  SET_EXCLUDE_LIST = 'SET_EXCLUDE_LIST',
}

export type SwapNetworkParams = {
  fromNetwork: ChainId | null;
  toNetwork: ChainId | null;
  fromCurrency: string | null;
  toCurrency: string | null;
};

export interface BridgeState extends SwapNetworkParams {
  amount: string;
  excludedToChainList: ChainId[];
}

type BridgeAction =
  | { type: BridgeActionType.SET_FROM_CHAIN; payload: ChainId }
  | { type: BridgeActionType.SET_FROM_CHAIN_NO_ASSETS; payload: ChainId }
  | { type: BridgeActionType.SET_FROM_COIN; payload: string }
  | { type: BridgeActionType.SET_AMOUNT; payload: string }
  | { type: BridgeActionType.SET_TO_CHAIN; payload: ChainId }
  | { type: BridgeActionType.SET_EXCLUDE_LIST; payload: ChainId[] }
  | { type: BridgeActionType.SET_TO_COIN; payload: string };

export const initialState: BridgeState = {
  fromNetwork: null,
  toNetwork: null,
  toCurrency: null,
  fromCurrency: null,
  amount: '0',
  excludedToChainList: [],
};

export const bridgeReducer = (state: BridgeState, action: BridgeAction): BridgeState => {
  switch (action.type) {
    case BridgeActionType.SET_FROM_CHAIN:
      return {
        ...state,
        fromNetwork: action.payload,
      };
    case BridgeActionType.SET_TO_CHAIN:
      return {
        ...state,
        toNetwork: action.payload,
      };
    case BridgeActionType.SET_FROM_CHAIN_NO_ASSETS:
      return {
        ...state,
        fromNetwork: action.payload,
        fromCurrency: action.payload === ChainId.BASE ? chainToken(ChainId.ETHER) : chainToken(action.payload),
      };
    case BridgeActionType.SET_FROM_COIN:
      return {
        ...state,
        fromCurrency: action.payload,
      };
    case BridgeActionType.SET_AMOUNT:
      return { ...state, amount: action.payload };
    case BridgeActionType.SET_EXCLUDE_LIST:
      return { ...state, excludedToChainList: action.payload };
    case BridgeActionType.SET_TO_COIN:
      return {
        ...state,
        toCurrency: action.payload,
      };

    default:
      return state;
  }
};
