import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

//helpers
import { chainLogo, chainToken } from '@helpers/chains';

//img
import logo from '@assets/images/chains/all-chains.svg';
import iconBadge from '@assets/images/icon-badge.svg';

//types
import type { UserAccount } from '../../../types';
import { ButtonSize, ButtonType } from '@interfaces/button.types';

//constants
import { PageRoutes } from '../../../constants';

//components
import { Button } from '@components/common/Button';

interface AccountListProps {
  accounts?: UserAccount[];
  onSelect: (accountId: string) => void;
  selectedAccountId?: string;
}

export const AccountList = ({ accounts, onSelect, selectedAccountId }: AccountListProps) => {
  if (!accounts || !selectedAccountId) {
    return null;
  }

  return (
    <div className="d-flex flex-column gap-3">
      {accounts.map(({ blockchain, id: accountId }, i) => (
        <Button
          size={ButtonSize.Auto}
          type={selectedAccountId === accountId ? ButtonType.SecondaryDark : ButtonType.SecondaryLight}
          key={accountId}
          onClick={() => onSelect(accountId)}
          icon={<img src={logo} alt="Account icon" className="me-2" />}
          className={classNames('flex-shrink-0 px-3', {
            'border-pink': selectedAccountId === accountId,
            'btn-outline-secondary': selectedAccountId !== accountId,
          })}
        >
          <div className="d-flex gap-2 w-100 align-items-center">
            <span className="d-flex flex-column flex-1 align-items-start tx-medium gap-2">
              <div className="tx-16">{`Account ${i + 1}`}</div>
              <div className="d-flex gap-2 align-items-center tx-14">
                <img src={chainLogo(blockchain)} alt="Chain logo" loading="lazy" className="wd-20 ht-20" />
                <span>{chainToken(blockchain) === 'ETH' ? 'EVM' : chainToken(blockchain)}</span>
              </div>
            </span>
            <Link to={PageRoutes.ACCOUNT_SETTINGS}>
              <img src={iconBadge} alt="Badge icon" className="px-2" />
            </Link>
          </div>
        </Button>
      ))}
    </div>
  );
};
