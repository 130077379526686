import React from 'react';
import { WalletIdentity } from '@components/common/WalletIdentity';
import { FormattedNumber } from '@components/common/FormattedNumber';

//helpers
import { chainLogo } from '@helpers/chains';
import { getEllipsisTxt } from '@helpers/formatters';

import { UserAccount } from 'types';

interface MyAccountsProps {
  onAccountSelect: (address: string) => void;
  accounts?: UserAccount[];
}

export const MyAccounts = ({ onAccountSelect, accounts }: MyAccountsProps) => {
  return (
    <div className="tx-12 tx-muted mt-4">
      <h5 className="tx-white tx-bold">Your Accounts</h5>
      <div className="tx-14">Or select an existing account</div>
      {accounts?.map((account) => (
        <div
          key={account.id}
          className="d-flex align-items-center my-3 gap-3"
          onClick={() => onAccountSelect(account.address)}
        >
          <WalletIdentity address={getEllipsisTxt(account.address, 4, '0x')} walletName={account.id}>
            <div className="d-flex flex-column text-end gap-1">
              <div className="tx-white tx-medium tx-14">{1000}</div>
              <div className="d-flex align-items-center justify-content-end gap-2">
                <img src={chainLogo('ethereum')} alt="crypto icon" className="wd-16 ht-16" />
                <div className="text-end">
                  <FormattedNumber value={0.5} decimals={2} subZeros suffix="$" floor />
                </div>
              </div>
            </div>
          </WalletIdentity>
        </div>
      ))}
    </div>
  );
};
