import React, { useState } from 'react';
import filter from 'lodash/filter';
import { observer } from 'mobx-react-lite';
import { AccountFilterPanel } from '@pages/Account/components/AccountFilterPanel';
import { AccountList } from '@pages/Account/components/AccountList';
import { ModalManagerImport } from '@pages/Account/components/ModalManagerImport';
import { ModalManagerCreate } from '@pages/Account/components/ModalManagerCreate';

//constants
import { ChainId } from '../../constants';

//hooks
import { useStores } from '@hooks/useStores';
import { useQuery } from '@hooks/useQuery';

const tabList = [ChainId.ALL, ChainId.ETHER, ChainId.SOL, ChainId.TON];

export const Account = observer(() => {
  const query = useQuery();
  const { accountStore } = useStores();
  const { account, profile, setAccount } = accountStore;

  const [tab, setTab] = useState(tabList[0]);

  const filteredAccounts = tab === ChainId.ALL ? profile?.accounts : filter(profile?.accounts, { blockchain: tab });
  const hideActions = query.get('hideActions') !== 'true';

  return (
    <div className="d-flex flex-column gap-3 py-3 full-page">
      <div className="d-flex gap-3 flex-column flex-1 overflow-y-auto">
        <span>
          <div className="tx-20 tx-bold">Wallets</div>
          <div className="tx-muted tx-14">Manage multiple wallets and filter by chains.</div>
        </span>
        <AccountFilterPanel onSelect={setTab} currentTab={tab} filterList={tabList} />
        <AccountList onSelect={setAccount} selectedAccountId={account?.id} accounts={filteredAccounts} />
      </div>
      {hideActions && (
        <div className="d-flex flex-column gap-3">
          <ModalManagerImport />
          <ModalManagerCreate />
        </div>
      )}
    </div>
  );
});
