import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ResearchItem } from '@pages/Research/components/ResearchItem';
import { ResearchHeader } from '@pages/Research/components/ResearchHeader';

//img
import warning from '@assets/icons/warning.svg';

//hooks
import { useResponseHandler } from '@hooks/useResponseHandler';
import { useStores } from '@hooks/useStores';
import { useDisclaimer } from '@pages/Research/hooks/useDisclaimer';
import { useModal } from '@hooks/useModal';

//types
import { type HotToken } from '../../types';
import { Position } from '@interfaces/imageCard.type';

//components
import { Preloader } from '@components/common/Preloader';
import { DetailModal } from '@components/common/DetailModal';
import { TradeModal } from '@pages/Research/components/TradeModal';

export const Research = observer(() => {
  const { showDisclaimer, handleAgree } = useDisclaimer();
  const { isOpen, toggleModal } = useModal();
  const { accountStore } = useStores();
  const navigate = useNavigate();
  // const [search, setSearch] = useState('');
  // const debounceSearch = useDebounce(search, 500);
  // const [isSearching, setIsSearching] = useState(false);
  // const [isInvalid, setIsInvalid] = useState(false);
  const [tokens, setTokens] = useState<HotToken[]>([]);
  // const [positions, setPositions] = useState<PositionModel[]>([]);
  const [isTokensLoaded, setIsTokensLoaded] = useState(false);
  const handleResponse = useResponseHandler();

  // const isAddressValid = useMemo(() => {
  //   return ethRegex.test(search);
  // }, [search]);

  // useEffect(() => {
  //   if (!isPositionsLoaded) {
  //     accountStore.getSnipePositions()
  //       .then((response) => {
  //         if (response) {
  //           setPositions(response);
  //           setIsPositionsLoaded(true);
  //         } else {
  //           handleResponse(response);
  //         }
  //       })
  //       .catch((e) => {
  //         console.error(e);
  //         handleResponse(e.response);
  //         setIsPositionsLoaded(true);
  //       })
  //   }
  // }, [isPositionsLoaded]);

  useEffect(() => {
    if (isTokensLoaded) return;

    accountStore
      .getHotTokens()
      .then((response) => {
        setIsTokensLoaded(true);
        if (Array.isArray(response)) {
          setTokens(response.slice(0, 20));
        } else {
          handleResponse(response);
        }
      })
      .catch((e) => {
        handleResponse(e.response);
        setIsTokensLoaded(true);
      });
  }, [isTokensLoaded]);

  // useEffect(() => {
  //   if (search) {
  //     setIsSearching(true);
  //   }
  //   setIsInvalid(false);
  // }, [search]);

  // useEffect(() => {
  //   if (debounceSearch && isAddressValid) {
  //     accountStore
  //       .getTokenDetails(debounceSearch)
  //       .then((response) => {
  //         setIsSearching(false);
  //         if (response && response.hasOwnProperty('pairAddress')) {
  //           navigate('/token-snipe/' + response.pairAddress);
  //         } else {
  //           setIsInvalid(true);
  //         }
  //       })
  //       .catch(() => {
  //         setIsInvalid(true);
  //         setIsSearching(false);
  //       });
  //   }
  // }, [debounceSearch, isAddressValid]);

  // useEffect(() => {
  //   if (search && search === debounceSearch && !isAddressValid) {
  //     setIsSearching(false);
  //     setIsInvalid(true);
  //   }
  // }, [search, debounceSearch, isAddressValid]);

  return (
    <div className="pt-3 pb-5 d-flex flex-column full-page">
      <ResearchHeader onChange={() => {}} />
      {!isTokensLoaded && (
        <div className="tx-center pt-5">
          <Preloader
            inline
            iconSize={64}
            className="d-flex flex-column align-items-center"
            textClass="d-block tx-semibold tx-28 mt-4"
            iconClass="d-block"
            text="Loading hot tokens..."
          />
        </div>
      )}

      {/*<div className={`input-group mt-2 mb-3 rounded-2 ${isInvalid ? 'is-invalid' : ''}`}>*/}
      {/*  <div className="input-group-text py-0 ht-35 pe-1 ps-2 border-0">*/}
      {/*    <img src={searchIcon} alt="search" width={16} height={16} />*/}
      {/*  </div>*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    className={`form-control ht-35 ps-0 py-1 border-0 outline-none ${isInvalid ? 'tx-danger is-invalid' : 'tx-gray-400'}`}*/}
      {/*    value={search}*/}
      {/*    onChange={(e) => setSearch(e.target.value)}*/}
      {/*    placeholder="Token address"*/}
      {/*  />*/}
      {/*  {!!search && (*/}
      {/*    <div className="input-group-text py-0 ht-35 pe-2 ps-1 border-0">*/}
      {/*      <img src={cross} alt="Clear" width={16} height={16} onClick={() => setSearch('')} />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}

      {/*{search && isSearching && (*/}
      {/*  <div className="tx-center pt-5">*/}
      {/*    <Preloader*/}
      {/*      inline*/}
      {/*      iconSize={64}*/}
      {/*      className="d-flex flex-column align-items-center"*/}
      {/*      textClass="d-block tx-semibold tx-28 mt-4"*/}
      {/*      iconClass="d-block"*/}
      {/*      text="Searching token..."*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}

      {/*{search && !isSearching && (*/}
      {/*  <div className="tx-center">*/}
      {/*    <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">*/}
      {/*      🔎*/}
      {/*    </BadgeIcon>*/}
      {/*    <h1 className="tx-28 mt-4">It's empty :(</h1>*/}
      {/*    <div className="tx-muted my-3">*/}
      {/*      Nothing was found for your request. Try specifying a query based on other data*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

      {
        <div className="d-flex flex-column gap-3 flex-1 overflow-y-auto pb-4">
          {/*<div className="d-flex align-items-center justify-content-between gap-2">*/}
          {/*  <button className="btn btn-secondary wd-100p tx-12 lh-2">*/}
          {/*    <img src={whale} alt="Whale Signals" width={24} className="mb-1" />*/}
          {/*    <div>Whale Signals</div>*/}
          {/*  </button>*/}
          {/*  <button className="btn btn-secondary wd-100p tx-12 lh-2">*/}
          {/*    <img src={whale} alt="Whale Signals" width={24} className="mb-1" />*/}
          {/*    <div>Whale Signals</div>*/}
          {/*  </button>*/}
          {/*  <button className="btn btn-secondary wd-100p tx-12 lh-2">*/}
          {/*    <img src={whale} alt="Whale Signals" width={24} className="mb-1" />*/}
          {/*    <div>Whale Signals</div>*/}
          {/*  </button>*/}
          {/*</div>*/}
          {tokens.map((item, index) => {
            const handleTrade = () => {
              toggleModal();
              // TODO: fix crash the page
              // navigate('/token-snipe/' + item.pairAddress);
            };

            return <ResearchItem key={String(index)} data={item} index={index} onTrade={handleTrade} />;
          })}

          {/*//TODO: when search would be ready*/}
          {/*{search && (*/}
          {/*  <div className="flex-1 d-flex flex-column justify-content-center">*/}
          {/*    <ImageCard*/}
          {/*      title="No Results Found"*/}
          {/*      subtitle="Please make sure all words are spelled correctly"*/}
          {/*      imageSrc={colorSearch}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      }
      <DetailModal
        show={showDisclaimer}
        title="Disclaimer"
        alignment={Position.Left}
        imageSrc={warning}
        primaryText="I Agree"
        hasCloseButton={false}
        onPrimary={handleAgree}
      >
        <div className="tx-muted d-flex flex-column gap-4 py-3">
          <span>
            We strongly advise you to do your own research before buying any tokens. Black Bunny doesn't give financial
            advice and isn't responsible for any tokens listed on our platform.
          </span>
          <span className="mb-3">Always check various sources of information to make smart decisions.</span>
        </div>
      </DetailModal>
      <TradeModal show={isOpen} onClose={toggleModal} />
    </div>
  );
});
