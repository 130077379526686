import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import classNames from 'classnames';

//types
import { ButtonType, ButtonSize, ButtonShape, IconPosition, ContentAlignment } from '@interfaces/button.types';

type ButtonProps = {
  type?: ButtonType;
  size?: ButtonSize;
  shape?: ButtonShape;
  icon?: React.ReactNode;
  iconPosition?: IconPosition;
  isLoading?: boolean;
  contentAlignment?: ContentAlignment;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;

export const Button = ({
  type = ButtonType.Primary,
  size = ButtonSize.Full,
  shape = ButtonShape.Pill,
  icon,
  iconPosition = IconPosition.Left,
  className,
  children,
  isLoading = false,
  contentAlignment = ContentAlignment.Center,
  ...rest
}: ButtonProps) => {
  const btnClass = classNames(
    'btn d-flex align-items-center gap-2',
    {
      'justify-content-start': contentAlignment === ContentAlignment.Left,
      'justify-content-center': contentAlignment === ContentAlignment.Center,
      'justify-content-end': contentAlignment === ContentAlignment.Right,
      'btn-main-pink': type === ButtonType.Primary,
      'btn-main-secondary': type === ButtonType.Secondary,
      'btn-main-light': type === ButtonType.SecondaryLight,
      'btn-main-dark': type === ButtonType.SecondaryDark,
      'btn-transparent': type === ButtonType.Transparent,
      'w-100': size === ButtonSize.Full,
      'w-auto': size === ButtonSize.Auto,
      'rounded-pill': shape === ButtonShape.Pill,
      'rounded-circle p-2': shape === ButtonShape.Round,
      'rounded-3 px-2': shape === ButtonShape.Normal,
    },
    className
  );

  const isRightPosition = icon && iconPosition === IconPosition.Right;

  return (
    <button className={btnClass} {...rest}>
      {isLoading ? (
        <Spinner animation="border" variant="light" />
      ) : (
        <>
          {!isRightPosition && <>{icon}</>}
          {children}
          {isRightPosition && <>{icon}</>}
        </>
      )}
    </button>
  );
};
