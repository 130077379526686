import React, { type PropsWithChildren } from 'react';
import IdentIcon from '@components/common/IndetIcon';

//img
import { ReactComponent as Chevron } from '@assets/icons/chevron.svg';

//types
import { ButtonSize, ButtonType, IconPosition } from '@interfaces/button.types';

//components
import { Button } from '@components/common/Button';

interface WalletIdentityProps extends PropsWithChildren {
  address: string;
  walletName?: string;
  iconSize?: number;
  isHideAddress?: boolean;
  onWalletNameClick?: () => void;
}

export const WalletIdentity = ({
  address,
  walletName = 'EVM Account 1',
  children,
  iconSize = 40,
  isHideAddress = false,
  onWalletNameClick,
}: WalletIdentityProps) => {
  return (
    <div className="d-flex justify-content-between align-items-center gap-3 w-100">
      <IdentIcon string={address} className="rounded-circle" size={iconSize} />
      <div className="flex-1 d-flex flex-column gap-1">
        <div className="d-flex align-items-center gap-2">
          {onWalletNameClick ? (
            <Button
              type={ButtonType.Transparent}
              size={ButtonSize.Auto}
              iconPosition={IconPosition.Right}
              onClick={onWalletNameClick}
              icon={<Chevron />}
              className="p-0 tx-medium tx-14 tx-white"
            >
              {walletName}
            </Button>
          ) : (
            <div className="tx-medium tx-14 tx-white">{walletName}</div>
          )}
        </div>
        {!isHideAddress && <div className="tx-12 tx-muted tx-normal">{address}</div>}
      </div>
      {children}
    </div>
  );
};
