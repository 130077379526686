import React, { type PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import { BridgeErrorEffect } from '@pages/Bridge/components/BridgeErrorEffect';

//constants
import { PageRoutes } from '../../../constants';

//hooks
import { type CreateSwap, useCreateSwap } from '@pages/Bridge/hooks/useCreateSwap';

//types
import type { IAsset, SwapData } from '../../../types';
import { ButtonType } from '@interfaces/button.types';

//img
import swapIcon from '@assets/icons/swap-modal-icon.svg';

//helpers
import { divide, formatFixedDecimals, multiply, subtract } from '@helpers/bignumber';

//components
import { Button } from '@components/common/Button';
import { FormattedNumber } from '@components/common/FormattedNumber';

interface PreviewModalProps {
  show: boolean;
  onClose: () => void;
  previewData?: SwapData;
  toCoinIcon?: string;
  fromCoinAsset?: IAsset;
  previewSwapData: CreateSwap;
}

interface ConfirmationCardProps extends PropsWithChildren {
  amount: number | string;
  value: number | string;
  title: 'TO' | 'FROM';
  labels: 'Available' | 'Rate';
  icon?: string;
  coinSymbol: string;
  network: string;
}

const ConfirmationCard = ({
  amount,
  labels,
  title,
  value,
  icon,
  children,
  coinSymbol,
  network,
}: ConfirmationCardProps) => (
  <div className="rounded-3 border-gray-800 bd-1 bd-solid d-flex flex-column gap-2 p-3">
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center gap-2">
        <img src={icon} alt="Coin icon" loading="lazy" className="wd-20 ht-20 rounded-circle" />
        <span className="text-uppercase">{coinSymbol}</span>
        <span className="text-capitalize">{network + ' network'}</span>
      </div>
      <span className="tx-small tx-medium">{title}</span>
    </div>
    <div className="d-flex align-items-stretch gap-3 justify-content-center w-100">
      <div className="d-flex flex-column gap-1 w-100 text-start align-self-start">
        <div className="tx-muted tx-small tx-medium">Amount:</div>
        <div className="tx-normal tx-12 text-capitalize">
          <FormattedNumber value={amount} decimals={5} subZeros postfix={coinSymbol.toUpperCase()} floor />
        </div>
      </div>
      <div className="d-flex flex-column gap-1 w-100 text-start align-self-start">
        <div className="tx-muted tx-small tx-medium">{labels}:</div>
        <div className="tx-normal tx-12">{children}</div>
      </div>
      <div className="d-flex flex-column gap-1 w-100 text-start align-self-start">
        <div className="tx-muted tx-small tx-medium">Value:</div>
        <div className="tx-normal tx-12">
          <FormattedNumber value={value} decimals={2} subZeros suffix="$" floor />
        </div>
      </div>
    </div>
  </div>
);

export const PreviewModal = ({
  show,
  onClose,
  previewData,
  toCoinIcon,
  fromCoinAsset,
  previewSwapData,
}: PreviewModalProps) => {
  const navigate = useNavigate();

  const { mutate, error, isSuccess, isPending } = useCreateSwap();

  useEffect(() => {
    if (isSuccess) {
      onClose();
      navigate(PageRoutes.SUCCESS, {
        state: { description: 'Your bridge transaction has been completed successfully' },
      });
    }
  }, [isSuccess]);

  if (!previewData || !fromCoinAsset) {
    return null;
  }

  const fromAvailableValue = subtract(fromCoinAsset.quantity, previewSwapData.fromAmount);
  const fromAmountValue = multiply(previewSwapData.fromAmount, fromCoinAsset.price);
  const rate = formatFixedDecimals(divide(previewSwapData.fromAmount, previewData.estimatedAmount.amount), 5);

  const handleConfirmSwap = () => {
    mutate(previewSwapData);
  };

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between align-items-center gap-3 w-100">
            <img src={swapIcon} alt="Title preview icon" loading="lazy" className="imageCircle p-2 my-0" />
            <div className="flex-1 d-flex flex-column gap-1">
              <div className="tx-medium tx-20 tx-white">Bridge Confirmation</div>
              <div className="tx-14 tx-muted tx-normal">Please review the details and confirm</div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex gap-2 flex-column pt-1">
          <ConfirmationCard
            value={fromAmountValue}
            amount={previewSwapData.fromAmount}
            title="FROM"
            labels="Available"
            icon={fromCoinAsset.imageUrl}
            coinSymbol={previewData.exchangeFee.totals.from.currency}
            network={previewData.exchangeFee.totals.from.network}
          >
            {`${fromAvailableValue} ${fromCoinAsset.assetSymbol}`}
          </ConfirmationCard>
          <ConfirmationCard
            value={previewData.estimatedAmount.amountUsd}
            amount={previewData.estimatedAmount.amount}
            title="TO"
            labels="Rate"
            icon={toCoinIcon}
            coinSymbol={previewData.exchangeFee.totals.to.currency}
            network={previewData.exchangeFee.totals.to.network}
          >
            {`${rate} ${fromCoinAsset.assetSymbol} per ${previewData.exchangeFee.totals.to.currency.toUpperCase()}`}
          </ConfirmationCard>
          <Button type={ButtonType.Secondary} onClick={onClose} className="mt-4">
            Cancel
          </Button>
          <Button onClick={handleConfirmSwap} isLoading={isPending}>
            Continue
          </Button>
        </Modal.Body>
      </Modal>
      <BridgeErrorEffect error={error} />
    </>
  );
};
