import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import Modal from 'react-bootstrap/Modal';

//types
import type { IAsset, Currency } from '../../../types';
import { ButtonType, ContentAlignment } from '@interfaces/button.types';

//img
import unknownToken from '@assets/icons/unknown-token.svg';

//components
import { Button } from '@components/common/Button';

interface CoinModalData {
  symbol: string;
  imageUrl: string;
}

interface CoinModalProps {
  show: boolean;
  onClose: () => void;
  onCoinChange: (arg: string) => void;
  data: IAsset[] | Currency[];
  currentCoin: string | null;
}

export const CoinModal = ({ show, onClose, data, currentCoin, onCoinChange }: CoinModalProps) => {
  const normalizeData = useMemo<CoinModalData[]>(
    () =>
      map(data, (item) => ({
        symbol: get(item, 'assetSymbol', get(item, 'symbol', '')),
        imageUrl: get(item, 'imageUrl', get(item, 'logo', '')),
      })),
    [data]
  );

  const handleCoinSelect = (coin: string) => {
    onCoinChange(coin);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton className="pt-3 px-3 pb-0 justify-content-end" />
      <Modal.Body className="d-flex flex-column gap-3">
        {normalizeData.map(({ symbol, imageUrl }, i) => {
          const isSelected = currentCoin === symbol;

          return (
            <Button
              key={String(i)}
              type={isSelected ? ButtonType.SecondaryDark : ButtonType.SecondaryLight}
              contentAlignment={ContentAlignment.Left}
              onClick={() => handleCoinSelect(symbol)}
              icon={
                <img
                  src={imageUrl || unknownToken}
                  alt="Account icon"
                  className="me-2 rounded-circle"
                  height={25}
                  width={25}
                  loading="lazy"
                  style={{ objectFit: 'scale-down' }}
                />
              }
              className={classNames('flex-shrink-0 px-3', {
                'border-pink': isSelected,
                'btn-outline-secondary': !isSelected,
              })}
            >
              {symbol}
            </Button>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};
