import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { BackButton, useExpand, useWebApp, WebAppProvider } from '@vkruglikov/react-telegram-web-app';

//styles
import './styles/main.scss';

//constants
import { autoTradeRoutes, PageRoutes, portfolioRoutes, profileRoutes, tokenTradeRoutes } from './constants';

//hooks
import { useQuery } from '@hooks/useQuery';
import { useStores } from '@hooks/useStores';

//routes
import { routes } from '@routes/routes';

//components
import { MainLayout } from '@components/MainLayout';
import { RouteWrapper } from '@components/RouteWrapper';

const bottomNavRoutes = [
  ...portfolioRoutes,
  // ...researchRoutes,
  ...autoTradeRoutes,
  ...tokenTradeRoutes,
  ...profileRoutes,
  PageRoutes.BRIDGE,
  PageRoutes.SEND,
];

const preserveRedirectRoutes = [
  '/bridge',
  '/position-processing',
  '/position-settings',
  '/token-snipe/',
  '/token-snipe-bot/',
  '/auto-trade/',
  '/test',
];

const noBackRoutes = [
  PageRoutes.HOME,
  PageRoutes.LOGIN,
  PageRoutes.WALLET,
  PageRoutes.WALLET_CREATED,
  PageRoutes.BOT_SETTINGS,
];

const noBackRoutesPatterns = ['/position-processing', '/position-settings/', '/token-snipe-bot/'];

const App = observer(() => {
  const [isExpanded, expand] = useExpand();
  const { accountStore, swapStore } = useStores();
  const { profile } = accountStore;
  const { isReceiveSelection, isTokenSelection } = swapStore;
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const WebApp = useWebApp();
  const [backAvailable, setBackAvailable] = useState(false);

  useEffect(() => {
    accountStore.handleGetParams(query);
  }, [accountStore, query]);

  useEffect(() => {
    if (
      !profile &&
      (routes.find((r) => r.isPrivate && r.path === location.pathname) ||
        preserveRedirectRoutes.some((p) => location.pathname.includes(p)))
    ) {
      accountStore.setRedirectTo(location.pathname);
      console.log('preserve route', location.pathname);
      navigate('/', { replace: true });
    }
  }, [profile, location.pathname]);

  useEffect(() => {
    if (
      noBackRoutes.includes(location.pathname as string as PageRoutes) ||
      noBackRoutesPatterns.some((p) => location.pathname.includes(p))
    ) {
      setBackAvailable(false);
      console.log('no back route', location.pathname);
    } else {
      if (location.pathname === PageRoutes.SEND_CONFIRM) {
        setBackAvailable(false);
      } else {
        setBackAvailable(true);
      }
      console.log('with back route', location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isExpanded) {
      expand();
    }
  }, [isExpanded, expand]);

  useEffect(() => {
    if (WebApp && WebApp.hasOwnProperty('setHeaderColor')) {
      WebApp.setHeaderColor('#1C1C1C');
    }
  }, [WebApp]);

  useEffect(() => {
    if (WebApp && WebApp.hasOwnProperty('setBackgroundColor')) {
      if (bottomNavRoutes.some((route) => location.pathname.includes(route))) {
        WebApp.setBackgroundColor('#2C2C2C');
      } else if (location.pathname.includes('/asset/')) {
        WebApp.setBackgroundColor('#282828');
      } else {
        WebApp.setBackgroundColor('#1C1C1C');
      }
    }
  }, [WebApp, location]);

  // useEffect(() => {
  //   if (WebApp && WebApp.hasOwnProperty('enableClosingConfirmation')) {
  //     WebApp.enableClosingConfirmation();
  //   }
  // }, [WebApp]);

  const handleBack = () => {
    if (isTokenSelection) {
      swapStore.setIsTokenSelection(false);
      swapStore.setIsReceiveSelection(false);
      return;
    }
    navigate(-1);
  };

  const wrap = useCallback((element: React.ReactNode) => {
    return <RouteWrapper>{element}</RouteWrapper>;
  }, []);

  return (
    <WebAppProvider
      options={{
        smoothButtonsTransition: true,
      }}
    >
      <MainLayout>
        {/*<AnimatedSwitch>*/}
        <Routes>
          {routes.map(({ path, component }) => (
            <Route path={path} element={wrap(component)} key={`all-routes-${path}`} />
          ))}
        </Routes>
        {/*</AnimatedSwitch>*/}
      </MainLayout>
      {backAvailable && <BackButton onClick={handleBack} />}
    </WebAppProvider>
  );
});

export default App;
