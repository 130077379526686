import React from 'react';
import classNames from 'classnames';

//types
import { ButtonShape, ButtonType } from '@interfaces/button.types';
import { IconPosition, InputShape, InputSize, InputType } from '@interfaces/input.types';

//components
import { Button } from '@components/common/Button';

type InputProps = {
  inputType?: InputType;
  shape?: InputShape;
  icon?: React.ReactNode;
  iconPosition?: IconPosition;
  className?: string;
  isDisabled?: boolean;
  inputSize?: InputSize;
  onClick?: () => void;
  onClickIcon?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = ({
  inputType = InputType.Secondary,
  shape = InputShape.Pill,
  icon,
  iconPosition = IconPosition.Right,
  className,
  isDisabled = false,
  inputSize = InputSize.Large,
  onClick,
  onClickIcon,
  ...rest
}: InputProps) => {
  const inputClass = classNames(
    'form-control',
    {
      'bg-gray-800': inputType === InputType.SecondaryLight,
      'rounded-50': shape === InputShape.Pill && !icon,
      'rounded-left-50': shape === InputShape.Pill && icon && iconPosition === IconPosition.Right,
      'rounded-right-50': shape === InputShape.Pill && icon && iconPosition === IconPosition.Left,
      'p-2': inputSize === InputSize.Small && iconPosition === IconPosition.Left,
      'py-2': inputSize === InputSize.Small && iconPosition === IconPosition.Right,
    },
    className
  );

  const iconClass = classNames('input-group-text', {
    'rounded-left-50': shape === InputShape.Pill && iconPosition === IconPosition.Left,
    'rounded-right-50': shape === InputShape.Pill && iconPosition === IconPosition.Right,
    'py-2 ps-3 pe-0': inputSize === InputSize.Small && iconPosition === IconPosition.Left,
    'py-2 ps-1 pe-3': inputSize === InputSize.Small && iconPosition === IconPosition.Right,
  });

  const isIconRight = iconPosition === IconPosition.Right;
  const isIconLeft = iconPosition === IconPosition.Left;

  return (
    <div className={classNames('input-group', { 'input-group-disabled': isDisabled })}>
      {icon && isIconLeft && <span className={iconClass}>{icon}</span>}
      <input className={inputClass} disabled={isDisabled} {...rest} />
      {icon && isIconRight && <span className={iconClass}>{icon}</span>}
      {onClickIcon && !isIconRight && (
        <span className="input-group-text rounded-right-50 py-0 px-2">
          <Button type={ButtonType.Transparent} shape={ButtonShape.Round} onClick={onClick} className="p-0">
            <img src={onClickIcon} alt="Input icon" loading="lazy" className="wd-9 ht-9" />
          </Button>
        </span>
      )}
    </div>
  );
};
