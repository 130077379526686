import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { autoTradeRoutes, PageRoutes, portfolioRoutes, profileRoutes, researchRoutes } from '../../constants';
import { ReactComponent as Wallet } from './icons/portfolio.svg';
import { ReactComponent as Research } from './icons/research.svg';
import { ReactComponent as TokenTrade } from './icons/swap.svg';
import { ReactComponent as AutoTrade } from './icons/auto-trade.svg';
import { ReactComponent as Profile } from './icons/profile.svg';
import { isIOS } from '@helpers/device';

const handleClassName = (route: string | PageRoutes, currentPathname: string) => {
  let result =
    'btn btn-link p-0 d-flex flex-column justify-content-center align-items-center cur-pointer wd-100p tx-10 tx-medium text-decoration-none gap-2';
  if (portfolioRoutes.includes(route) && portfolioRoutes.some((r) => currentPathname.includes(r))) {
    result += ' tx-pink';
  } else if (researchRoutes.includes(route) && researchRoutes.some((r) => currentPathname.includes(r))) {
    result += ' tx-pink';
  } else if (autoTradeRoutes.includes(route) && autoTradeRoutes.some((r) => currentPathname.includes(r))) {
    result += ' tx-pink';
  } else if (profileRoutes.includes(route) && profileRoutes.some((r) => currentPathname.includes(r))) {
    result += ' tx-pink';
  } else {
    result += ' tx-muted';
  }
  return result;
};

export const BottomNav = () => {
  const location = useLocation();

  return (
    <div id="bottom-nav" className={isIOS() ? 'with-notch' : ''}>
      <Link to={PageRoutes.WALLET} className={handleClassName(PageRoutes.WALLET, location.pathname)}>
        <Wallet />
        <span>Wallet</span>
      </Link>
      <Link to={PageRoutes.RESEARCH} className={handleClassName(PageRoutes.RESEARCH, location.pathname)}>
        <Research />
        <span>Hot tokens</span>
      </Link>
      <Link to={PageRoutes.TOKEN_SNIPE} className="btn btn-main-pink">
        <TokenTrade />
      </Link>
      <Link to={PageRoutes.AUTO_TRADE} className={handleClassName(PageRoutes.AUTO_TRADE, location.pathname)}>
        <AutoTrade />
        <span>Team</span>
      </Link>
      <Link to={PageRoutes.PROFILE} className={handleClassName(PageRoutes.PROFILE, location.pathname)}>
        <Profile />
        <span>Profile</span>
      </Link>
    </div>
  );
};
