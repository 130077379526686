import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Ellipse } from '@assets/icons/ellipse.svg';

//types
import { ButtonSize, ButtonType, ContentAlignment } from '@interfaces/button.types';

//constants
import { PageRoutes } from '../../constants';

//hooks
import { useStores } from '@hooks/useStores';
import { useResponseHandler } from '@hooks/useResponseHandler';

//img
import lock from '@assets/images/lock.svg';

//components
import { Preloader } from '@components/common/Preloader';
import { PinInput } from '@components/common/PinInput';
import { ImageCard } from '@components/common/ImageCard';
import { Button } from '@components/common/Button';
import { GreenHeaderGradientWrapper } from '@components/GreenHeaderGradientWrapper';

export const Login = observer(() => {
  const navigate = useNavigate();

  const { accountStore } = useStores();
  const { profile } = accountStore;

  const [pin, setPin] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const handleResponse = useResponseHandler();

  const handleSubmit = (value: string) => {
    accountStore.login(pin || value).catch((e) => {
      setPin('');
      setIsInvalid(true);
      handleResponse(e.response);
    });
  };

  const handleComplete = (value: string) => {
    if (!pin) {
      setPin(value);
      handleSubmit(value);
    }
  };
  const handleForgotPin = () => {
    navigate(PageRoutes.FORGOT_PIN);
  };

  useEffect(() => {
    if (pin && profile) {
      navigate(PageRoutes.WALLET, { replace: true });
    }
  }, [pin, profile]);

  if (pin) {
    return (
      <div className="tx-center pt-4">
        <Preloader
          inline
          iconSize={64}
          className="d-flex flex-column align-items-center"
          textClass="d-block mt-2"
          iconClass="d-block"
          text=""
        />
        <div className="tx-muted mt-3">
          Great! We are loading your wallet
          <br />
          «BlackBunny Wallet»
        </div>
      </div>
    );
  }

  return (
    <GreenHeaderGradientWrapper>
      <div className="mt-5">
        <ImageCard imageSrc={lock} title="PIN" subtitle="Enter your PIN below" />
      </div>
      <PinInput length={4} onChange={() => setIsInvalid(false)} onComplete={handleComplete} isInvalid={isInvalid} />
      <div className="flex-1 d-flex align-items-center gap-2 justify-content-between">
        <Button
          size={ButtonSize.Auto}
          contentAlignment={ContentAlignment.Right}
          type={ButtonType.Transparent}
          className="flex-grow-1 p-0 tx-12 tx-normal"
          onClick={handleForgotPin}
        >
          I forgot my PIN
        </Button>
        <Ellipse className="tx-gray-500 wd-6 ht-6" />
        <Button
          contentAlignment={ContentAlignment.Left}
          size={ButtonSize.Auto}
          type={ButtonType.Transparent}
          className="flex-grow-1 p-0 tx-12 tx-normal"
          onClick={() => {}}
        >
          Import using Seed Phrase
        </Button>
      </div>
    </GreenHeaderGradientWrapper>
  );
});
