import BigNumber from 'bignumber.js';
import arbitrum from '@assets/images/chains/arbitrum.svg';
import avalanche from '@assets/images/chains/avalanche.svg';
import bsc from '@assets/images/chains/bsc.svg';
import ether from '@assets/images/chains/ether.svg';
import polygon from '@assets/images/chains/polygon.svg';
import base from '@assets/images/chains/base.svg';
import allChains from '@assets/images/chains/all-chains.svg';
import solana from '@assets/images/chains/solana.svg';
import ton from '@assets/images/chains/ton.svg';
import { ChainId } from '../constants';

import type { UserAccount, AccountChain } from '../types';

export const chainName = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ALL:
      return 'All Chains';
    case ChainId.ETHER:
      return 'Ethereum';
    case ChainId.BSC:
      return 'Binance Smart Chain';
    case ChainId.POLYGON:
      return 'Polygon';
    case ChainId.AVALANCHE:
      return 'Avalanche';
    case ChainId.ARBITRUM:
      return 'Arbitrum';
    case ChainId.BASE:
      return 'Base Chain';
    case ChainId.TON:
      return 'Toncoin';
    case ChainId.SOL:
      return 'Solana';
    default:
      return 'Unknown';
  }
};

export const allowedChains = {
  [ChainId.ETHER]: [ChainId.ETHER, ChainId.BSC, ChainId.POLYGON, ChainId.AVALANCHE, ChainId.ARBITRUM, ChainId.BASE],
  [ChainId.TON]: [ChainId.TON],
  [ChainId.SOL]: [ChainId.SOL],
};
export const excludedEvmChainList = [ChainId.TON, ChainId.SOL, ChainId.ALL];
export const excludedSolChainList = Object.values(ChainId).filter((c) => c !== ChainId.SOL);
export const excludedTonChainList = Object.values(ChainId).filter((c) => c !== ChainId.TON);

export const chainLogo = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ALL:
      return allChains;
    case ChainId.ETHER:
      return ether;
    case ChainId.BSC:
      return bsc;
    case ChainId.POLYGON:
      return polygon;
    case ChainId.AVALANCHE:
      return avalanche;
    case ChainId.ARBITRUM:
      return arbitrum;
    case ChainId.BASE:
      return base;
    case ChainId.SOL:
      return solana;
    case ChainId.TON:
      return ton;
    default:
      return '';
  }
};

export const chainToken = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ETHER:
      return 'ETH';
    case ChainId.ARBITRUM:
      return 'ARB';
    case ChainId.BASE:
      return 'BASE';
    case ChainId.BSC:
      return 'BNB';
    case ChainId.POLYGON:
      return 'MATIC';
    case ChainId.AVALANCHE:
      return 'AVAX';
    case ChainId.SOL:
      return 'SOL';
    case ChainId.TON:
      return 'TON';
    default:
      return '';
  }
};

export const nativeChainToken = (chain: ChainId | string): string => {
  switch (chain) {
    case ChainId.ETHER:
      return 'ETH';
    case ChainId.ARBITRUM:
      return 'ETH';
    case ChainId.BASE:
      return 'ETH';
    case ChainId.BSC:
      return 'BNB';
    case ChainId.POLYGON:
      return 'MATIC';
    case ChainId.AVALANCHE:
      return 'AVAX';
    case ChainId.SOL:
      return 'SOL';
    case ChainId.TON:
      return 'TON';
    default:
      return '';
  }
};

export const chainIdNumber = (chain: ChainId | string): number => {
  switch (chain) {
    case ChainId.ETHER:
      return 1;
    case ChainId.BSC:
      return 56;
    case ChainId.POLYGON:
      return 137;
    case ChainId.AVALANCHE:
      return 43114;
    case ChainId.ARBITRUM:
      return 42161;
    case ChainId.BASE:
      return 8453;
    default:
      return 0;
  }
};

export const chainTxExplorer = (chain: ChainId | string, transactionHash: string): string => {
  switch (chain) {
    case ChainId.ETHER:
      return `https://etherscan.io/tx/${transactionHash}`;
    case ChainId.AVALANCHE:
      return `https://snowtrace.io/tx/${transactionHash}`;
    case ChainId.BSC:
      return `https://bscscan.com/tx/${transactionHash}`;
    case ChainId.ARBITRUM:
      return `https://arbiscan.io/tx/${transactionHash}`;
    case ChainId.POLYGON:
      return `https://polygonscan.com/tx/${transactionHash}`;
    case ChainId.BASE:
      return `https://basescan.org/tx/${transactionHash}`;
    default:
      return '';
  }
};

export const getAccountData = (account: UserAccount | null) => {
  const blockchain = chainToken(account?.blockchain ?? '');
  const isEvm = blockchain === chainToken(ChainId.ETHER);
  const isSol = blockchain === chainToken(ChainId.SOL);
  const isTon = blockchain === chainToken(ChainId.TON);

  const accountName = isEvm ? 'EVM Account' : `${blockchain} Account`;

  const accountChain: AccountChain = isEvm ? ChainId.ETHER : isSol ? ChainId.SOL : isTon ? ChainId.TON : ChainId.ETHER;

  return {
    isEvm,
    isSol,
    isTon,
    accountName,
    accountChain,
  };
};

export const toWei = (value: number | string, decimals: number = 18): string => {
  return new BigNumber(value).times(new BigNumber(10).pow(decimals)).toString();
};
