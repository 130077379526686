import {IAutoTradeFilters, PositionModel, PositionStatus, StrategyModel, TokenDetails} from "./types";
import {zeroAddress} from "./constants";

export const mockStrategies: StrategyModel[] = [
  {
    id: '1',
    status: PositionStatus.OPEN,
    chainId: 1,
    hidden: false,
    createdAt: new Date().getTime().toString(),
    updatedAt: new Date().getTime().toString(),
    walletId: '1',
    settings: {
      id: '1',
      label: 'Strategy 1',
      userId: '1',
      approveGasDelta: 10000,
      autoApprove: true,
      buyGasDelta: 10000,
      buySlippage: 0.1,
      failGuard: false,
      liquidityMax: 100,
      liquidityMin: 0,
      mCapMax: 100,
      mCapMin: 0,
      maxGasPrice: 100,
      mevGuardEnabled: false,
      sellGasDelta: 10000,
      sellSlippage: 0.1,
      stopLossEnabled: true,
      stopLossPercent: -30,
      stopLossSellPercent: 90,
      takeProfitEnabled: false,
      takeProfitPercent: 100,
      takeProfitSellPercent: 90,
      volumeMax: 100,
      volumeMin: 0,
      trailingStopLossEnabled: false,
      createdAt: new Date().toString(),
      updatedAt: new Date().toString(),
    },
  },
];

export const mockPositionModel: PositionModel = {
  id: '1',
  status: PositionStatus.OPEN,
  chainId: 1,
  hidden: false,
  createdAt: new Date().toString(),
  updatedAt: new Date().toString(),
  walletId: '1',
  buyAmount: 1,
  pair: zeroAddress,
  token: {
    address: zeroAddress,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  transactions: [],
  buyPrice: '1',
  curPrice: '1',
  dex: 'Uniswap',
  maxPrice: '1',
  poolFeePercent: 0.3,
  trailingPriceChange: 0.1,
  priceChange: 0.1,
  settings: {
    ...mockStrategies[0].settings,
    takeProfitSellPercent: 0.1,
    stopLossSellPercent: 0.1,
  },
  token0: zeroAddress,
  token1: zeroAddress,
};

export const mockFilters: IAutoTradeFilters = {
  buyTaxMin: null,
  buyTaxMax: null,
  sellTaxMin: null,
  sellTaxMax: null,
  mCapMax: null,
  mCapMin: null,
  ageMax: null,
  ageMin: null,
  sourceCodeVerification: false,
  renounce: false,
  initialLiquidityMin: null,
  initialLiquidityMax: null,
  currentLiquidityMin: null,
  currentLiquidityMax: null,
  volume24hMin: null,
  volume24hMax: null,
  volume6hMin: null,
  volume6hMax: null,
  volume1hMin: null,
  volume1hMax: null,
  volume5mMin: null,
  volume5mMax: null,
  buys24hMin: null,
  buys24hMax: null,
  buys6hMin: null,
  buys6hMax: null,
  buys1hMin: null,
  buys1hMax: null,
  buys5mMin: null,
  buys5mMax: null,
  sells24hMin: null,
  sells24hMax: null,
  sells6hMin: null,
  sells6hMax: null,
  sells1hMin: null,
  sells1hMax: null,
  sells5mMin: null,
  sells5mMax: null,
  priceChange24hMin: null,
  priceChange24hMax: null,
  priceChange6hMin: null,
  priceChange6hMax: null,
  priceChange1hMin: null,
  priceChange1hMax: null,
  priceChange5mMin: null,
  priceChange5mMax: null,
  holdersMin: null,
  holdersMax: null,
  lpLockPercent: null,
  lpLockDays: null,
  snipersBribeSumMin: null,
  snipersBribeSumMax: null,
  snipersMin: null,
  snipersMax: null,
};
