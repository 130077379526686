import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CreateWallet } from '@pages/Wallet/CreateWallet';
import { WalletCreated } from '@pages/Wallet/WalletCreated';
import { Wallet } from '@pages/Wallet/Wallet';
import { Receive } from '@pages/Receive/Receive';
import { Login } from '@pages/Login/Login';
import { SuccessPage } from '@pages/Success/SuccessPage';
import { Send, SendPageType } from '@pages/Send/Send';
import { Research } from '@pages/Research/Research';
import { Account } from '@pages/Account/Account';
import { AccountSettings } from '@pages/Account/AccountSettings';
import { Asset } from 'pages/Asset/Asset';
import { ForgotPin } from '@pages/ForgotPin/ForgotPin';
import { Bridge } from '@pages/Bridge/Bridge';
import Welcome from '@pages/Wallet/Welcome';
import GetSeedPhrase from '@pages/Wallet/GetSeedPhrase';
import SeedPhrase from '@pages/Wallet/SeedPhrase';
import WalletReady from '@pages/Wallet/WalletReady';
import ComingSoon from '@pages/Wallet/ComingSoon';
import Profile from 'pages/Profile';
import ChangePin from '@pages/Profile/ChangePin';
import SnipeSettings from '@pages/Profile/SnipeSettings';
import ExportSeedPhrase from '@pages/Profile/ExportSeedPhrase';
import TokenSnipe from '@pages/TokenSnipe';
import PositionProcessing from '@pages/TokenSnipe/PositionProcessing';
import SnipeDetails from '@pages/TokenSnipe/SnipeDetails';
import WhaleSignals from '@pages/WhaleSignals';
import PositionSettings from '@pages/TokenSnipe/components/PositionSettings';
import ImportWallet from '@pages/Wallet/ImportWallet';
import AutoTrade from '@pages/AutoTrade';
import StrategySettings from '@pages/AutoTrade/components/StrategySettings';
import StrategyFilters from '@pages/AutoTrade/components/StrategyFilters';
import Referral from '@pages/Referral';
import AmountSettings from '@pages/Profile/AmountSettings';

//constants
import { PageRoutes } from '../constants';

// Create a client
const queryClient = new QueryClient();

export const routes = [
  {
    path: PageRoutes.HOME,
    component: <Welcome />,
  },
  {
    path: PageRoutes.LOGIN,
    component: <Login />,
  },
  {
    path: PageRoutes.CREATE_WALLET,
    component: <CreateWallet />,
  },
  {
    path: PageRoutes.IMPORT_PIN,
    component: <CreateWallet isImport />,
  },
  {
    path: PageRoutes.IMPORT_WALLET,
    component: <ImportWallet />,
  },
  {
    path: PageRoutes.WALLET_CREATED,
    component: <WalletCreated />,
  },
  {
    path: PageRoutes.GET_SEED_PHRASE,
    component: <GetSeedPhrase />,
  },
  {
    path: PageRoutes.SEED_PHRASE,
    component: <SeedPhrase isRegister />,
  },
  {
    path: PageRoutes.WALLET_READY,
    component: <WalletReady />,
  },
  {
    path: PageRoutes.WALLET,
    component: <Wallet />,
    isPrivate: true,
  },
  {
    path: PageRoutes.RECEIVE,
    component: <Receive />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SEND,
    component: <Send />,
    isPrivate: true,
  },
  {
    path: PageRoutes.BRIDGE,
    component: (
      <QueryClientProvider client={queryClient}>
        <Bridge />
      </QueryClientProvider>
    ),

    isPrivate: true,
  },
  {
    path: PageRoutes.SEND_CONFIRM,
    component: <Send type={SendPageType.CONFIRM} />,
    isPrivate: true,
  },
  {
    path: PageRoutes.ASSET,
    component: <Asset />,
    isPrivate: true,
  },
  {
    path: PageRoutes.POSITION_PROCESSING,
    component: <PositionProcessing />,
    isPrivate: true,
  },
  {
    path: PageRoutes.POSITION_PROCESSING_BOT,
    component: <PositionProcessing isBot />,
    isPrivate: true,
  },
  {
    path: PageRoutes.TOKEN_SNIPE_POSITION,
    component: <SnipeDetails />,
    isPrivate: true,
  },
  {
    path: PageRoutes.TOKEN_SNIPE_POSITION_BOT,
    component: <SnipeDetails isBot />,
    isPrivate: true,
  },
  {
    path: PageRoutes.TOKEN_SNIPE,
    component: <TokenSnipe />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_SNIPE,
    component: <ComingSoon />,
    isPrivate: true,
  },
  {
    path: PageRoutes.LIMIT_ORDERS,
    component: <ComingSoon />,
    isPrivate: true,
  },
  {
    path: PageRoutes.PROFILE,
    component: <Profile />,
    isPrivate: true,
  },
  {
    path: PageRoutes.RESEARCH,
    component: <Research />,
    isPrivate: true,
  },
  {
    path: PageRoutes.CHANGE_PIN,
    component: <ChangePin />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SNIPE_SETTINGS,
    component: <SnipeSettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.BOT_SETTINGS,
    component: <SnipeSettings isBot />,
    isPrivate: true,
  },
  {
    path: PageRoutes.POSITION_SETTINGS,
    component: <PositionSettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.EXPORT_SEED_PHRASE,
    component: <ExportSeedPhrase />,
    isPrivate: true,
  },
  {
    path: PageRoutes.WHALE_SIGNALS,
    component: <WhaleSignals />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE,
    component: <AutoTrade />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE_CREATE,
    component: <StrategySettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE_EDIT,
    component: <StrategySettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE_FILTERS,
    component: <StrategyFilters />,
    isPrivate: true,
  },
  {
    path: PageRoutes.REFERRAL,
    component: <Referral />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AMOUNT_SETTINGS,
    component: <AmountSettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SUCCESS,
    component: <SuccessPage />,
    isPrivate: true,
  },
  {
    path: PageRoutes.ACCOUNT,
    component: <Account />,
    isPrivate: true,
  },
  {
    path: PageRoutes.ACCOUNT_SETTINGS,
    component: <AccountSettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.FORGOT_PIN,
    component: <ForgotPin />,
  },
];
