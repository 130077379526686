import React, { ReactNode } from 'react';
import classNames from 'classnames';

// styles
import styles from '@styles/components/modules/gradient.module.scss';

interface SideGradientWrapperProps {
  children: ReactNode;
  className?: string;
}

export const SideGradientWrapper = ({ children, className }: SideGradientWrapperProps) => {
  return (
    <div className={classNames('full-page d-flex flex-column gap-3 position-relative', className)}>
      <div className={styles.blurRedHeader} />
      <div className={styles.blurGreenHeader} />
      {children}
    </div>
  );
};
