import { useQuery } from '@tanstack/react-query';
import { apiRaw } from '@helpers/api';

import type { SwapNetworkParams } from '@stores/reducers/bridgeReducer';

export interface ExchangeRange {
  minAmount: number;
  maxAmount: number | null;
}

function exchangeRangeQueryOptions({ fromCurrency, fromNetwork, toCurrency, toNetwork }: SwapNetworkParams) {
  return {
    queryKey: ['exchangeRange', fromCurrency, toCurrency],
    queryFn: () =>
      apiRaw<ExchangeRange>({
        method: 'get',
        path: `/swap/exchange-range`,
        data: {
          fromCurrency: fromCurrency?.toLowerCase(),
          fromNetwork,
          toCurrency: toCurrency?.toLowerCase(),
          toNetwork,
        },
      }).then((re) => re.data.data ?? null),
    enabled: !!fromCurrency && !!fromNetwork && !!toCurrency && !!toNetwork,
  };
}

export const useFetchExchangeRange = (arg: SwapNetworkParams) => {
  return useQuery(exchangeRangeQueryOptions(arg));
};
