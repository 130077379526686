import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CopyButton } from '@components/common/CopyButton';
import QRCode from 'qrcode.react';
import logo from '@assets/images/logo/logo-dark.svg';
import copy from '@assets/icons/copy.svg';
import ReferralShareCard from '@pages/Referral/components/ReferralShareCard';
import qr from '@assets/icons/qr-scan.svg';

interface ReferralQrProps {
  link: string;
}

const ReferralQr = ({ link }: ReferralQrProps) => {
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Invite a friend</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tx-center">
          <CopyButton text={link} className="btn btn-link p-0 wd-100p" noIcon>
            <div className="bg-white rounded-10 d-flex p-4 qr-code my-4 wd-70p mx-auto pos-relative">
              <QRCode
                value={`${link}`}
                size={1000}
                bgColor="#FFFFFF"
                fgColor="#252525"
                imageSettings={{ src: logo, excavate: true, width: 198, height: 150 }}
              />
              <img src={logo} alt="BlackBunny Wallet" className="qr-code-logo" />
            </div>
          </CopyButton>

          <CopyButton text={link} className="btn btn-link p-0 wd-100p text-decoration-none" noIcon>
            <div className="card tx-center tx-17 tx-semibold">{link}</div>
          </CopyButton>

          <div className={`d-flex justify-content-between align-items-center`}>
            <CopyButton text={link} className="btn btn-secondary tx-17 tx-semibold wd-100p px-0 py-3" noIcon>
              <>
                <img src={copy} alt="Copy" width={24} className="me-2" />
                <span>Copy</span>
              </>
            </CopyButton>
          </div>
        </Modal.Body>
      </Modal>

      <ReferralShareCard title="QR Code" icon={qr} text="Show to friend" onClick={() => setShow(true)} />
    </>
  );
};

export default ReferralQr;
