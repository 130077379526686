// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_blurOrangeHeader__JMA\\+Z {
  position: absolute;
  top: -100px;
  left: calc(50% - 61px);
  width: 122px;
  height: 122px;
  background-color: rgba(255, 147, 21, 0.6);
  filter: blur(142px);
}

.login_blurGreenHeader__j-VPX {
  position: absolute;
  top: -100px;
  left: calc(50% - 61px);
  width: 122px;
  height: 122px;
  background-color: #07B195;
  filter: blur(142px);
}

.login_blurRedHeader__\\+9135 {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 150px;
  background-color: rgba(239, 42, 123, 0.8);
  filter: blur(132px);
}`, "",{"version":3,"sources":["webpack://./src/styles/components/modules/login.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,yCAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,yCAAA;EACA,mBAAA;AACF","sourcesContent":[".blurOrangeHeader {\n  position: absolute;\n  top: -100px;\n  left: calc(50% - 61px);\n  width: 122px;\n  height: 122px;\n  background-color: rgba(255, 147, 21, 0.60);\n  filter: blur(142px);\n}\n\n.blurGreenHeader {\n  position: absolute;\n  top: -100px;\n  left: calc(50% - 61px);\n  width: 122px;\n  height: 122px;\n  background-color: #07B195;\n  filter: blur(142px);\n}\n\n.blurRedHeader {\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 150px;\n  height: 150px;\n  background-color: rgba(239, 42, 123, 0.80);\n  filter: blur(132px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blurOrangeHeader": `login_blurOrangeHeader__JMA+Z`,
	"blurGreenHeader": `login_blurGreenHeader__j-VPX`,
	"blurRedHeader": `login_blurRedHeader__+9135`
};
export default ___CSS_LOADER_EXPORT___;
