import React from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

//constants
import { ChainId } from '../../../constants';

//types
import { HotToken } from '../../../types';

//components
import { Button } from '@components/common/Button';
import { FormattedNumber } from '@components/common/FormattedNumber';
import { LabelValueCard } from '@components/common/LabelValueCard';
import { CopyButton } from '@components/common/CopyButton';
import { HistoryLogo } from '@pages/Wallet/components/HistoryLogo';
import { ReactComponent as TrendUp } from '@assets/icons/trend-up.svg';

interface ResearchItemProps {
  data: HotToken;
  index: number;
  onTrade: () => void;
}

const formatTimeDistance = (date: string | number | Date) => {
  const distance = formatDistanceToNow(new Date(date)).replace('about ', '');

  return distance
    .replace('hours', 'h')
    .replace('minutes', 'm')
    .replace('seconds', 's')
    .replace('days', 'd')
    .replace('weeks', 'w')
    .replace('months', 'mo')
    .replace('years', 'y')
    .replace(/\s+/g, '');
};

export const ResearchItem = ({ data, index, onTrade }: ResearchItemProps) => {
  return (
    <div className="card bg-black rounded-3 d-flex flex-column gap-2">
      <div className="d-flex align-items-center gap-3 mb-2">
        <HistoryLogo mainLogo={undefined} symbolIn={data.token.symbol} size={45} chain={ChainId.ETHER} />

        <div className="d-flex flex-column gap-1">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className="mx-wd-120 lh-2">
              <span className="tx-teal me-1 tx-bold">#{index + 1}</span>
              {data.token.name}
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13">
            <CopyButton
              text={data.token.address}
              className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none"
            >
              <div className="tx-12">
                <span className="tx-white me-1">CA:</span>
                <span>{data.token.address.slice(0, 6)}</span>
              </div>
            </CopyButton>
            <CopyButton
              text={data.pairAddress}
              className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none ms-2 me-auto"
            >
              <div className="tx-12">
                <span className="tx-white me-1">P:</span>
                <span>{data.pairAddress.slice(0, 6)}</span>
              </div>
            </CopyButton>
          </div>
        </div>
      </div>

      <div className="card d-flex flex-row align-items-center gap-3 py-2 tx-12 rounded-2">
        <FormattedNumber value={data.price} decimals={6} suffix="$" subZeros className="word-break tx-20" />
        <div className={`${data.priceChange24h > 0 ? 'tx-success' : 'tx-danger'} d-flex align-items-center gap-2`}>
          <TrendUp className={data.priceChange24h > 0 ? '' : 'mirror-vertical'} />
          <FormattedNumber value={data.priceChange24h} withSign decimals={2} postfix="%" />
        </div>
        <div className="tx-pink flex-1 text-end">Analytic</div>
      </div>

      <div className="d-flex justify-content-between align-items-center w-100 gap-2">
        <LabelValueCard label="Liquidity" value={data.liquidity} unit="$" />
        <LabelValueCard label="Volume" value={data.volume} unit="$" />
        <LabelValueCard label="MCap" value={data.marketCap} unit="$" />
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2">
        <LabelValueCard label="Age" value={formatTimeDistance(data.createdAt)} unit="$" />
        <LabelValueCard label="Holders" value={data.audit.holders} unit="$" />
        <div className="w-100 card d-flex flex-column tx-start gap-2 p-2 rounded-2 text-nowrap">
          <span className="tx-muted tx-10 tx-medium">Tax</span>
          <div className="d-flex gap-2">
            <FormattedNumber value={data.audit.buyTax} decimals={2} postfix="%" className="tx-success" />
            <FormattedNumber value={data.audit.sellTax} decimals={2} postfix="%" className="tx-danger" />
          </div>
        </div>
      </div>
      <Button onClick={onTrade} className="py-1 mt-2">
        Trade
      </Button>
    </div>
  );
};
