import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedNumber } from '@components/common/FormattedNumber';
import { IconWithLoading } from '@components/common/IconWithLoading';
import { useNotification } from '@hooks/useNotification';
import { useStores } from '@hooks/useStores';
import { useResponseHandler } from '@hooks/useResponseHandler';

interface ReferralClaimModalProps {
  value: number;
}

const ReferralClaimModal = observer(({ value }: ReferralClaimModalProps) => {
  const { accountStore } = useStores();
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notify = useNotification();
  const handleResponse = useResponseHandler();

  const handleClose = () => {
    if (isLoading) return;
    setShow(false);
  };

  const handleSubmit = () => {
    if (!value) {
      notify('Nothing to claim', { type: 'popup-danger' });
      handleClose();
      return;
    }

    setIsLoading(true);

    accountStore
      .claimReferral()
      .then((response) => {
        if (response && response.transactionHash) {
          notify(`Claimed ${value} ETH`, { type: 'popup-success' });
        } else {
          notify(response.data.error, { type: 'popup-danger' });
        }
        setIsLoading(false);
        handleClose();
      })
      .catch((response) => {
        handleResponse(response);
        setIsLoading(false);
        handleClose();
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tx-center">
          <FormattedNumber value={value} suffix="Claim " postfix="ETH" className="tx-28 tx-semibold" floor />
          <div className="tx-17 tx-muted mt-2">When withdrawing funds, they are credited to your wallet</div>

          <button className="btn btn-light wd-100p mt-4" onClick={handleSubmit} disabled={isLoading}>
            <IconWithLoading isLoading={isLoading} />
            Claim
          </button>

          <button className="btn btn-transparent wd-100p mt-2 mb-3" onClick={handleClose}>
            Cancel
          </button>
        </Modal.Body>
      </Modal>

      <button className="btn btn-light py-2 ms-3" onClick={() => setShow(true)}>
        Claim
      </button>
    </>
  );
});

export default ReferralClaimModal;
