import React, { useEffect, useState } from 'react';
import loader from '@assets/images/loader.svg';

export interface PreloaderProps {
  className?: string;
  iconClass?: string;
  textClass?: string;
  text?: string;
  inline?: boolean;
  iconSize?: number;
  faster?: boolean;
}

export function Preloader({
  className = '',
  iconClass,
  textClass,
  text = 'Loading...',
  inline,
  iconSize,
  faster = false,
}: PreloaderProps) {
  const defaultClass = inline === true ? 'tx-center tx-md-left tx-22 ' : 'preloader tx-center ';
  const defaultIconClass = iconClass ? iconClass : inline === true ? 'me-2 ' : 'mb-2 ';
  const size = iconSize ? iconSize : inline ? 16 : 64;
  const [speedUp, setSpeedUp] = useState(false);
  const [textState, setText] = useState<string>(text);

  useEffect(() => {
    if (text && !speedUp) {
      setTimeout(() => {
        setSpeedUp(true);
        if (text === 'Loading...') {
          setText('Loading faster...');
        } else if (faster && text) {
          setText(text.includes('...') ? text.replace('...', ' faster...') : text + ' faster');
        }
      }, 4000);
    }
  }, [speedUp, text]);

  return (
    <div className={defaultClass + className}>
      <span className={`${defaultIconClass}`}>
        <img src={loader} alt="Loading" className={`spin ${speedUp ? 'faster' : ''} wd-${size} ht-${size}`} />
      </span>
      {!!textState && <span className={textClass}>{textState}</span>}
    </div>
  );
}
