import {action, makeAutoObservable, observable} from 'mobx';

const keys = [
  '_notifyEnabled',
  '_amounts',
];

const defaultAmounts = [
  '0.025',
  '0.05',
  '0.1',
];

export class SettingsStore {
  @observable
  private _initialized = false;

  @observable
  private _isSaving = false;

  @observable
  private _triggerUpdate = 0;

  @observable
  private _notifyEnabled: boolean = false;

  @observable
  private _amounts: string[] = defaultAmounts.slice();

  constructor() {
    makeAutoObservable(this);
  }

  get
  initialized() {
    return this._initialized;
  }

  get
  triggerUpdate() {
    return this._triggerUpdate;
  }

  get
  notifyEnabled() {
    return this._notifyEnabled;
  }

  get
  amounts() {
    return this._amounts;
  }

  get
  isSaving() {
    return this._isSaving;
  }

  @action
  handleUpdate() {
    this._triggerUpdate++;
    this._isSaving = true;
  }

  @action
  setNotifyEnabled(value: boolean) {
    this._notifyEnabled = value;
    this.handleUpdate();
  }

  @action
  setAmounts(value: string[]) {
    this._amounts = defaultAmounts.slice().map((amount, index) => {
      return value[index] || amount;
    });
    this.handleUpdate();
  }

  @action
  setSaved() {
    this._isSaving = false;
  }

  get
  state() {
    return keys.map((key) => {
      return {
        key,
        // @ts-ignore
        value: this[key],
      };
    });
  }

  @action
  restoreSettings(keys: string[], values: string[]) {
    keys.forEach((key, index) => {
      try {
        const value = values[index] ? JSON.parse(values[index]) : null;
        // @ts-ignore
        if (this.hasOwnProperty(key) && value && JSON.stringify(this[key]) !== JSON.stringify(value)) {
          // @ts-ignore
          this[key] = value;
        }
      } catch (e) {
        // console.log(e);
      }
    });
    this._initialized = true;
  }
}
