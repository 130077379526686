import React from 'react';
import { ReactComponent as Avatar } from '@assets/images/chains/all-chains.svg';
import { ReactComponent as Chevron } from '@assets/icons/chevron.svg';

//types
import { ButtonShape, ButtonSize, ButtonType, IconPosition } from '@interfaces/button.types';
import { InputShape, InputType } from '@interfaces/input.types';
import { Position } from '@interfaces/imageCard.type';

//helpers
import { chainLogo } from '@helpers/chains';

//img
import refresh from '@assets/icons/refresh.svg';
import pencil from '@assets/icons/pencil.svg';
import key from '@assets/icons/key.svg';
import warning from '@assets/icons/warning.svg';

//hooks
import { useModal } from '@hooks/useModal';

//components
import { SideGradientWrapper } from '@components/common/SideGradientWrapper';
import { Button } from '@components/common/Button';
import { CopyButton } from '@components/common/CopyButton';
import { DetailModal } from '@components/common/DetailModal';
import { Input } from '@components/common/Input';
import { CopyModal } from '@pages/Account/components/CopyModal';

export const AccountSettings = () => {
  const { isOpen: isOpenInfo, toggleModal: toggleInfo } = useModal();
  const { isOpen: isRevealKey, toggleModal: toggleRevealKey } = useModal();
  const { isOpen: isRenameAddress, toggleModal: toggleRenameAddress } = useModal();

  const onShowKey = () => {
    toggleInfo();
    toggleRevealKey();
  };

  return (
    <SideGradientWrapper className="my-3">
      <div className="card rounded-20 d-flex flex-column align-items-center mb-4 p-4">
        <div className="d-flex align-items-center gap-2 w-100 justify-content-end">
          <img src={chainLogo('ethereum')} alt="ethereum" className="wd-20 ht-20" />
          <span className="tx-14 tx-medium">EVM</span>
        </div>
        <div className="position-relative">
          <Avatar width={80} height={80} />
          <button className="btn w-auto rounded-circle p-2 btn-light-blur-12 bg-gray-500 position-absolute d-flex align-items-center r--0 b--5">
            <img src={refresh} alt="Refresh icon" loading="lazy" />
          </button>
        </div>
        <Button
          type={ButtonType.Transparent}
          iconPosition={IconPosition.Right}
          onClick={toggleRenameAddress}
          size={ButtonSize.Auto}
          icon={<img src={pencil} alt="Pencil icon" loading="lazy" />}
          className="tx-16 tx-medium mt-2"
        >
          EVM Account 1
        </Button>
      </div>

      <span>
        <div className="tx-medium">Wallet Address</div>
        <div className="tx-14 tx-muted">Your unique cryptocurrency wallet address</div>
      </span>
      <div className="card rounded-3 mb-4">
        <CopyButton text="wallet address 12345678" iconSize={24}>
          <span className="tx-14 tx-normal tx-white text-truncate">0x4B6C5aA04E2459175390eD97Db66d3B5760b6ab4</span>
        </CopyButton>
      </div>
      <span>
        <div className="tx-medium">Private Key</div>
        <div className="tx-14 tx-muted">
          Your private key is like a password that gives you access to all your wallets
        </div>
      </span>
      <Button
        type={ButtonType.SecondaryLight}
        size={ButtonSize.Auto}
        shape={ButtonShape.Normal}
        onClick={toggleInfo}
        icon={<img src={key} alt="Key icon" loading="lazy" />}
      >
        <div className="d-flex flex-1 w-100 align-items-center justify-content-between">
          <span className="tx-14 tx-medium">Your private key</span>
          <Chevron className="tx-muted rotate-r-90" />
        </div>
      </Button>
      <DetailModal
        show={isOpenInfo}
        onClose={toggleInfo}
        title="Export Private Key"
        subtitle="With your private key, anyone can access your funds. Protect it and never disclose it to others"
        imageSrc={warning}
        secondaryText="Cancel"
        primaryText="Show Key"
        onPrimary={onShowKey}
      />
      <CopyModal
        show={isRevealKey}
        onClose={toggleRevealKey}
        title="Your Private Key"
        subtitle="If you want to import a wallet, you will be able to do so thanks to this key"
        textToCopy="x4B6C5aA04E2459175390eD97Db66d3B5760b6ab4dfghjkl567mnbgv4567fghjkl"
        secondaryText="Close"
        onSecondary={toggleRevealKey}
      />
      <DetailModal
        alignment={Position.Left}
        show={isRenameAddress}
        onClose={toggleRenameAddress}
        secondaryText="Cancel"
        primaryText="Save"
        title="Rename Wallet"
      >
        <Input
          onChange={() => {}}
          shape={InputShape.Default}
          inputType={InputType.SecondaryLight}
          className="py-2 mt-3 mb-5 rounded-2"
          value="EVM Account 1"
        />
      </DetailModal>
    </SideGradientWrapper>
  );
};
