import React, { useEffect, useMemo, useState } from 'react';
import ReferralBadge from '@pages/Referral/components/ReferralBadge';
import { FormattedNumber } from '@components/common/FormattedNumber';
import Progress from '@components/common/Progress';
import ReferralShare from '@pages/Referral/components/ReferralShare';
import ReferralQr from '@pages/Referral/components/ReferralQr';
import { LabelValueCard } from '@components/common/LabelValueCard';
import { ApiReferral, referralLevels, ReferralLevelType } from '@pages/Referral/constants';
import ReferralListItem from '@pages/Referral/components/ReferralListItem';
import { observer } from 'mobx-react-lite';
import ReferralLevelModal from '@pages/Referral/components/ReferralLevelModal';
import ReferralClaimModal from '@pages/Referral/components/ReferralClaimModal';
import { useStores } from '@hooks/useStores';
import { useResponseHandler } from '@hooks/useResponseHandler';
import { PageLoader } from '@components/PageLoader';
import { ENV } from '../../constants';

const Referral = observer(() => {
  const { accountStore } = useStores();
  const handleResponse = useResponseHandler();
  const [data, setData] = useState<ApiReferral>();
  const [modalType, setModalType] = useState<ReferralLevelType | null>(null);

  useEffect(() => {
    if (!data) {
      accountStore
        .getReferralData()
        .then((data) => {
          setData(data);
        })
        .catch((response) => {
          handleResponse(response);
        });
    }
  }, [data]);

  const totalTurnover = useMemo(() => {
    return data?.total30dTurnover || 0;
  }, [data]);

  const unclaimedProfit = useMemo(() => {
    return data?.unclaimedProfit || 0;
  }, [data]);

  const nextLevel = useMemo(() => {
    return referralLevels.find((level) => level.turnover > totalTurnover);
  }, [totalTurnover]);

  const currentLevel = useMemo(() => {
    const nextIndex = referralLevels.findIndex((level) => level.turnover > totalTurnover);
    return referralLevels[(nextIndex >= 0 ? nextIndex : referralLevels.length) - 1];
  }, [totalTurnover]);

  const referralLink = useMemo(() => {
    if (!data) {
      return '';
    }

    return `${ENV.BOT_URL}?start=ref-${data.refId}`;
  }, [data]);

  const progressBg = useMemo(() => {
    switch (currentLevel.type) {
      case ReferralLevelType.BRONZE:
        return 'beige';
      case ReferralLevelType.SILVER:
        return 'silver';
      case ReferralLevelType.GOLD:
        return 'gold';
      case ReferralLevelType.PLATINUM:
        return 'white';
      case ReferralLevelType.DIAMOND:
        return 'diamond-gradient';
      case ReferralLevelType.MASTER:
        return 'master-gradient';
      default:
        return 'beige';
    }
  }, [currentLevel.type]);

  const firstLine = useMemo(() => {
    const line = data?.refLines.find((l) => l.line === 1);
    return (
      line || {
        turnover: '0',
        turnover30d: '0',
        refCount: 0,
        totalProfit: '0',
        profit: '0',
        line: 0,
        profit30d: '0',
        userId: '0',
      }
    );
  }, [data]);

  const secondLine = useMemo(() => {
    const line = data?.refLines.find((l) => l.line === 2);
    return (
      line || {
        turnover: '0',
        turnover30d: '0',
        refCount: 0,
        totalProfit: '0',
        profit: '0',
        line: 0,
        profit30d: '0',
        userId: '0',
      }
    );
  }, [data]);

  if (!data) {
    return <PageLoader />;
  }

  return (
    <div className="py-4">
      <h1 className="tx-28 mb-3">Referral Program</h1>
      <div className="card mb-4">
        <div className="d-flex flex-row justify-content-start align-items-center border-bottom border-semi-transparent pb-3 mb-3">
          <div className="wd-65 ht-65 mn-wd-65 mx-wd-65">
            <ReferralBadge type={currentLevel.type} />
          </div>
          <div className="d-flex flex-column ms-3 wd-100p">
            <div className="tx-17 tx-semibold">{currentLevel.label}</div>
            <Progress
              value={nextLevel ? totalTurnover : 100}
              max={nextLevel ? nextLevel.turnover : 100}
              forceColor={progressBg}
              className="mt-2 wd-100p"
            />
            {nextLevel && (
              <div className="tx-12 tx-muted d-flex align-items-center">
                <FormattedNumber value={totalTurnover} postfix="ETH" decimals={2} floor className="me-1" useFormat />
                of
                <FormattedNumber
                  value={nextLevel.turnover}
                  postfix="ETH"
                  decimals={2}
                  floor
                  className="mx-1"
                  useFormat
                />
                to
                <button
                  className="btn btn-transparent p-0 tx-right tx-capitalize tx-12 tx-muted text-decoration-underline ms-1"
                  onClick={() => setModalType(nextLevel.type)}
                >
                  {nextLevel.label}
                </button>
              </div>
            )}
            {!nextLevel && <div className="tx-12 tx-muted">Max Level</div>}
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-end">
          <div>
            <div className="tx-muted tx-12">Available:</div>
            <FormattedNumber value={unclaimedProfit} postfix="ETH" decimals={2} floor className="tx-22 tx-semibold" />
          </div>
          <ReferralClaimModal value={unclaimedProfit} />
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4 gap-3">
        <ReferralShare link={referralLink} text={`Referral ID: ${data.refId}`} />
        <ReferralQr link={referralLink} />
      </div>

      <div className="card px-0 d-block mb-4 py-0">
        <div className="flex-scroll gap-3 px-3">
          {referralLevels.map((level) => (
            <ReferralListItem
              level={level}
              isActive={currentLevel.type === level.type}
              onClick={() => setModalType(level.type)}
              key={`referral-list-item-${level.type}`}
            />
          ))}
        </div>
      </div>

      <div className="card tx-13">
        <div className="tx-18 tx-semibold mt-1 mb-3">Rewards</div>

        <div className="d-flex justify-content-between align-items-center tx-semibold mb-2">
          <div>Invited by you</div>
          <div>
            Referral bonus: <span className="tx-success">{currentLevel.refPercent1Line}%</span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
          <LabelValueCard label="Invited" value={`${firstLine.refCount} Users`} />
          <LabelValueCard label="Reward" value={firstLine.totalProfit} unit="ETH" />
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2">
          <LabelValueCard label="Turnover 30d" value={firstLine.turnover30d} unit="ETH" />
          <LabelValueCard label="Turnover all time" value={firstLine.turnover} unit="ETH" />
        </div>

        <div className="d-flex justify-content-between align-items-center tx-semibold mb-2 mt-4">
          <div>Invited by your friends</div>
          <div>
            Referral bonus: <span className="tx-success">{currentLevel.refPercent2Line}%</span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
          <LabelValueCard label="Invited" value={`${secondLine.refCount} Users`} />
          <LabelValueCard label="Reward" value={secondLine.totalProfit} unit="ETH" />
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2">
          <LabelValueCard label="Turnover 30d" value={secondLine.turnover30d} unit="ETH" />
          <LabelValueCard label="Turnover all time" value={secondLine.turnover} unit="ETH" />
        </div>
      </div>

      <ReferralLevelModal type={modalType} onClose={() => setModalType(null)} valueNow={totalTurnover} />
    </div>
  );
});

export default Referral;
