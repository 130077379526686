import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Ellipse } from '@assets/icons/ellipse.svg';

interface PinInputProps {
  length: number;
  onChange: () => void;
  onComplete: (value: string) => void;
  isInvalid?: boolean;
  disabled?: boolean;
}

const numbers = Array.from({ length: 9 }, (_, i) => (i + 1).toString());

export const PinInput = ({ length, onChange, onComplete, isInvalid, disabled }: PinInputProps) => {
  const [value, setValue] = useState('');

  const handleChange = (value: string) => {
    if (disabled) return;
    setValue(value);
    onChange();

    if (value.length === length) {
      onComplete(value);
    }
  };

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.key === 'Backspace') {
        handleChange(value.slice(0, -1));
      } else if ([...numbers, '0'].includes(e.key)) {
        handleChange(value + e.key);
      }
    },
    [value, numbers]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const buttonClass = classNames(
    'wd-100p ht-55 rounded-12 d-flex justify-content-center align-items-center bg-secondary',
    {
      'bd-danger-dark': isInvalid,
    }
  );
  const containerClass = classNames('mx-auto d-flex flex-wrap justify-content-between wd-100p row g-2', {
    'opacity-50': disabled,
  });

  return (
    <>
      <div className="d-flex justify-content-between px-1 mb-3 gap-2 wd-100p">
        {Array.from({ length }, (_, i) => (
          <div className={buttonClass} key={i}>
            {value[i] ? <Ellipse /> : ''}
          </div>
        ))}
      </div>

      <div className={containerClass}>
        {numbers.map((number) => (
          <div className="col-4" key={number}>
            <div
              className="btn btn-secondary wd-100p py-3 rounded-12"
              onClick={() => {
                if (value.length < length) {
                  handleChange(value + number);
                }
              }}
            >
              {number}
            </div>
          </div>
        ))}
        <div className="col-4">
          <div
            className="btn btn-secondary wd-100p py-3"
            onClick={() => {
              handleChange('');
            }}
          >
            Clear
          </div>
        </div>
        <div className="col-4">
          <div
            className="btn btn-secondary wd-100p py-3"
            onClick={() => {
              handleChange(value + '0');
            }}
          >
            0
          </div>
        </div>
        <div className="col-4">
          <div
            className="btn btn-secondary wd-100p py-3"
            style={{ backgroundColor: 'rgba(251, 25, 25, 0.15)' }}
            onClick={() => {
              handleChange(value.slice(0, -1));
            }}
          >
            Delete
          </div>
        </div>
      </div>
    </>
  );
};
