import React from "react";
import {useNavigate} from "react-router-dom";
import onboarding from "@assets/images/onboarding3.png";
import {PageRoutes} from "../../../constants";
import { motion } from "framer-motion";

const StorySwap = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column align-items-center justify-content-center ht-100p wd-100p pt-5">
      <motion.h2
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.1, duration: 0.5}}
        className="tx-28 mb-0 mt-auto">Cross-Chain Swap with Profit Goals, MEV and fail guard</motion.h2>

      <motion.img
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.2, duration: 0.5}}
        src={onboarding} alt="Pro DEX for everyone" className="wd-100p px-0" />

      <div className="wd-100p mt-auto mb-auto">
        <motion.button
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{delay: 0.3, duration: 0.5}}
          className="btn btn-light wd-100p"
          onClick={() => navigate(PageRoutes.CREATE_WALLET)}
        >
          Create Wallet
        </motion.button>

        <motion.button
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{delay: 0.4, duration: 0.5}}
          className="btn btn-secondary wd-100p mt-3"
          onClick={() => navigate(PageRoutes.IMPORT_PIN)}
        >
          Import Wallet
        </motion.button>
      </div>
    </div>
  );
}

export default StorySwap;
