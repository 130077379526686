// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient_blurGreenHeader__AEMlI {
  position: absolute;
  top: -100px;
  left: 0;
  width: 155px;
  height: 155px;
  background-color: #07B195;
  filter: blur(132px);
  z-index: -1;
}

.gradient_blurRedHeader__Daag- {
  position: absolute;
  top: -100px;
  right: 0;
  width: 155px;
  height: 155px;
  background-color: rgba(239, 42, 123, 0.8);
  filter: blur(132px);
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/modules/gradient.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;AACF;;AACA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,yCAAA;EACA,mBAAA;EACA,WAAA;AAEF","sourcesContent":[".blurGreenHeader {\n  position: absolute;\n  top: -100px;\n  left: 0;\n  width: 155px;\n  height: 155px;\n  background-color: #07B195;\n  filter: blur(132px);\n  z-index: -1;\n}\n.blurRedHeader {\n  position: absolute;\n  top: -100px;\n  right: 0;\n  width: 155px;\n  height: 155px;\n  background-color: rgba(239, 42, 123, 0.80);\n  filter: blur(132px);\n  z-index: -1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blurGreenHeader": `gradient_blurGreenHeader__AEMlI`,
	"blurRedHeader": `gradient_blurRedHeader__Daag-`
};
export default ___CSS_LOADER_EXPORT___;
