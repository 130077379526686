import React, { useState } from 'react';
import isFinite from 'lodash/isFinite';

//types
import { InputShape, InputType } from '@interfaces/input.types';

//hooks
import { useNotification } from '@hooks/useNotification';

// img
import asterisk from '@assets/icons/asterisk-color.svg';

// components
import { GreenHeaderGradientWrapper } from '@components/GreenHeaderGradientWrapper';
import { ImageCard } from '@components/common/ImageCard';
import { Input } from '@components/common/Input';
import { Button } from '@components/common/Button';

export const ForgotPin = () => {
  const notify = useNotification();

  const [pin, setPin] = useState(Array(5).fill(''));

  const handleChange = (value: string, index: number) => {
    if (!isFinite(Number(value)) || value === ' ') {
      notify('Please enter only numeric values.', { duration: 3000, type: 'popup-danger' });
      return;
    }

    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value.length === 1 && index < 4) {
      const nextInput = document.getElementById(`pin-input-${index + 1}`);
      nextInput?.focus();
    }
  };

  const isPinComplete = pin.some((value) => value === '');

  return (
    <GreenHeaderGradientWrapper className="pt-5">
      <ImageCard
        imageSrc={asterisk}
        title="You forgot your PIN"
        subtitle="Enter the code we sent to your phone number +******890 to change your PIN."
        className="flex-1"
      >
        <div className="d-flex justify-content-center gap-2">
          {pin.map((value, index) => (
            <Input
              key={String(index)}
              id={`pin-input-${index}`}
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
              maxLength={1}
              shape={InputShape.Default}
              inputType={InputType.SecondaryLight}
              className="text-center w-25 rounded-2"
            />
          ))}
        </div>
      </ImageCard>
      <Button className="mb-3" onClick={() => console.log(pin.join(''))} disabled={isPinComplete}>
        Continue
      </Button>
    </GreenHeaderGradientWrapper>
  );
};
