import React from 'react';
import find from 'lodash/find';

//type
import { ButtonType } from '@interfaces/button.types';

import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';

//hooks
import { useStores } from '@hooks/useStores';

//components
import { SideGradientWrapper } from '@components/common/SideGradientWrapper';
import { AssetItem } from '@pages/Wallet/components/AssetItem';
import { LabelValueCard } from '@components/common/LabelValueCard';
import { Button } from '@components/common/Button';
import { PageRoutes } from '../../constants';

export const Asset = observer(() => {
  const navigate = useNavigate();

  const { accountStore } = useStores();
  const { assets } = accountStore;
  const { assetId } = useParams();

  const asset = find(assets, { assetId });

  if (!asset) {
    return null;
  }

  const handleReceive = () => {
    navigate(PageRoutes.RECEIVE);
  };

  console.log(JSON.stringify(asset), 'asset');

  return (
    <SideGradientWrapper className="assets-wrapper">
      <AssetItem asset={asset!} />
      <div className="assets-wrapper__balance">
        <span className="assets-wrapper__balance-usd">1</span>
        <div className="assets-wrapper__balance-crypto-wrapper">
          <span className="assets-wrapper__balance-crypto">1</span>
          <span className="assets-wrapper__balance-percent">1</span>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center w-100 gap-2">
        <LabelValueCard label="Bought" value={asset?.price} unit="$" cryptoBalance={10} />
        <LabelValueCard label="Sold" value={0} unit="$" cryptoBalance={10} />
      </div>
      <div className="flex-1 overflow-y-auto">History</div>
      <div className="assets-wrapper__button-wrapper">
        <Button type={ButtonType.Secondary} onClick={() => {}}>
          Send
        </Button>
        <Button onClick={handleReceive}> Receive</Button>
      </div>
    </SideGradientWrapper>
  );
});
