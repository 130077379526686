import React, { type PropsWithChildren } from 'react';
import Modal from 'react-bootstrap/Modal';

//types
import { ButtonType } from '@interfaces/button.types';
import { Position } from '@interfaces/imageCard.type';

//components
import { Button } from '@components/common/Button';
import { ImageCard, type ImageCardProps } from '@components/common/ImageCard';

interface DetailModalProps extends PropsWithChildren {
  show: boolean;
  hasCloseButton?: boolean;
  onClose?: () => void;
  title?: string;
  subtitle?: string;
  onSecondary?: () => void;
  onPrimary?: () => void;
  imageSrc?: string;
  primaryText?: string;
  secondaryText?: string;
  alignment?: Position;
}

export const DetailModal = (props: DetailModalProps) => {
  const {
    show,
    onClose,
    onSecondary,
    onPrimary,
    primaryText,
    secondaryText,
    children,
    hasCloseButton = true,
    ...rest
  } = props;

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton={hasCloseButton} className="pt-3 px-3 pb-0 justify-content-end" />
      <Modal.Body className="pt-0">
        <ImageCard {...(rest as ImageCardProps)}>
          {children}
          <div className="d-flex flex-column gap-3 w-100">
            {secondaryText && (
              <Button type={ButtonType.Secondary} onClick={onSecondary ?? onClose}>
                {secondaryText}
              </Button>
            )}
            {primaryText && <Button onClick={onPrimary ?? onClose}>{primaryText}</Button>}
          </div>
        </ImageCard>
      </Modal.Body>
    </Modal>
  );
};
