import React from 'react'
import {AccountStore} from "@stores/account-store";
import {SettingsStore} from "@stores/settings-store";
import {SwapStore} from "@stores/swap-store";

export const storesContext = React.createContext({
  accountStore: new AccountStore(),
  settingsStore: new SettingsStore(),
  swapStore: new SwapStore(),
});
