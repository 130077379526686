import { ChainId } from './constants';

export interface TokenId {
  chain: string;
  exchange: string;
  pair: string;
  token: string;
  tokenRef: string;
}

export interface TokenPairMetrics {
  reserve: number;
  reserveRef: number;
  liquidity: number;
}

export interface TokenLock {
  _id: string;
  percent?: number;
  unlockDate: string;
  amount: number;
  providerId: string;
  api: string;
  type: string;
}

export interface TokenDextScore {
  information: number;
  trading: number;
  team: number;
  audit: number;
  community: number;
  total: number;
}

export interface TokenPair {
  symbol: string;
  name: string;
  symbolRef: string;
  nameRef: string;
  type: string;
  metrics: TokenPairMetrics;
  fee?: number;
  creationBlock: number;
  creationTime: string;
  locks: TokenLock[];
  dextScore: TokenDextScore;
}

export interface TokenAudit {
  codeVerified: boolean;
  date: string;
  lockTransactions: boolean;
  mint: boolean;
  proxy: boolean;
  status: string;
  unlimitedFees: boolean;
  version: number;
  is_contract_renounced: boolean;
  provider: string;
  external?: {
    goplus?: {
      anti_whale_modifiable: string;
      buy_tax: number;
      can_take_back_ownership: string;
      cannot_buy: string;
      cannot_sell_all: string;
      creator_address: string;
      creator_balance: number;
      creator_percent: number;
      external_call: string;
      hidden_owner: string;
      holder_count: number;
      honeypot_with_same_creator: string;
      is_anti_whale: string;
      is_blacklisted: string;
      is_honeypot: string;
      is_in_dex: string;
      is_mintable: string;
      is_open_source: string;
      is_proxy: string;
      is_whitelisted: string;
      lp_holder_count: number;
      lp_total_supply: number;
      owner_address: string;
      owner_balance: number;
      owner_change_balance: string;
      owner_percent: number;
      personal_slippage_modifiable: string;
      selfdestruct: string;
      sell_tax: number;
      slippage_modifiable: string;
      token_name: string;
      token_symbol: string;
      total_supply: number;
      trading_cooldown: string;
      transfer_pausable: string;
      updatedAt: string;
      updatedResult: true;
      holders: {
        address: string;
        balance: number;
        is_contract: number;
        is_locked: number;
        percent: number;
        tag: string;
      }[];
      dex: {
        liquidity: number;
        name: string;
        pair: string;
      }[];
    };
  };
}

export interface TokenInfo {
  blueCheckmark: boolean;
  cmc: string;
  coingecko: string;
  description: string;
  dextools: boolean;
  ventures: boolean;
  email: string;
  extraInfo: string;
  nftCollection: string;
}

export interface TokenLinks {
  github: string;
  website: string;
  bitbucket: string;
  discord: string;
  facebook: string;
  instagram: string;
  linkedin: string;
  medium: string;
  reddit: string;
  telegram: string;
  tiktok: string;
  twitter: string;
  youtube: string;
}

export interface TokenMetrics {
  maxSupply: number;
  totalSupply: number;
  holders: number;
  txCount: number;
  mcap: number | null;
  fdv: number;
}

export interface TokenReprPair {
  id: TokenId;
  updatedAt: string;
}

export interface TokenData {
  audit?: TokenAudit;
  decimals: string;
  info: TokenInfo;
  links: TokenLinks;
  logo: string;
  metrics: TokenMetrics;
  name: string;
  symbol: string;
  totalSupply: string;
  creationBlock: number;
  creationTime: string;
  reprPair: TokenReprPair;
}

export interface TokenInterface {
  _id: TokenId;
  pair: TokenPair;
  token: TokenData;
  price: number;
  price24h?: number;
  price6h?: number;
  price1h?: number;
  price5m?: number;
  volume24h: number;
  swaps24h: number;
}

export enum OperationStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum TokenHistoryOperationType {
  BUY = 'buy',
  SELL = 'sell',
  RECEIVE = 'receive',
  SEND = 'SEND',
}

export interface TokenHistoryOperation {
  id: number;
  amount: number;
  amountUsd: number;
  price: number;
  priceUsd: number;
  timestamp: number;
  type: TokenHistoryOperationType;
  status: OperationStatus;
}

export enum PositionType {
  Deposit = 'deposit',
  Loan = 'loan',
  Locked = 'locked',
  Margin = 'margin',
  Reward = 'reward',
  Staked = 'staked',
  Wallet = 'wallet',
}

export interface IAsset {
  assetId: string;
  positionType: PositionType;
  quantity: string;
  value: number;
  price: number;
  change1d: number;
  change1dInPercent: number;
  assetSymbol: string;
  assetName: string;
  assetChain: ChainId;
  imageUrl: string;
  fungibleId: string;
  contractAddress: string;
  decimals: number;
  flags: {
    verified?: boolean;
    isTrash: boolean;
    displayable: boolean;
  };
}

export interface AssetsRaw {
  positions: IAsset[];
}

export interface ITelegramUser {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  language_code: string;
}

export interface IWebApp {
  initData: string;
  initDataUnsafe: {
    query_id: string;
    user: ITelegramUser;
    auth_date: string;
    hash: string;
  };
  version: string;
  platform: string;
  colorScheme: string;
  themeParams: {
    link_color: string;
    button_color: string;
    button_text_color: string;
    secondary_bg_color: string;
    hint_color: string;
    bg_color: string;
    text_color: string;
  };
  isExpanded: boolean;
  viewportHeight: number;
  viewportStableHeight: number;
  isClosingConfirmationEnabled: boolean;
  headerColor: string;
  backgroundColor: string;
  BackButton: {
    isVisible: boolean;
    show: () => void;
    hide: () => void;
    onClick: (_c: Function) => void;
    offClick: (_c: Function) => void;
  };
  MainButton: {
    text: string;
    color: string;
    textColor: string;
    isVisible: boolean;
    isProgressVisible: boolean;
    isActive: boolean;
  };
  HapticFeedback: any;
  expand: () => void;
  ready: () => void;
}

export interface JWT {
  jwt: string;
}

export interface Mnemonic extends JWT {
  mnemonic: string;
}

export interface UserAccount {
  index: number;
  address: string;
  id: string;
  blockchain: ChainId;
}

export interface UserProfile {
  username: string;
  fullName: string;
  accounts: UserAccount[];
}

export interface WalletStats {
  walletAddress: string;
  totalBalance: number;
  totalChange1d: number;
  totalChangePercent1d: number;
  network: ChainId;
}

export interface Portfolio {
  summarize: WalletStats;
  balances: Array<{ portfolio: WalletStats }>;
}

export interface ApiGetAllowanceParams {
  network: ChainId;
  tokenAddress: string;
  walletAddress: string;
}

export interface ApiSimulateParams extends ApiGetAllowanceParams {
  tokenAmount: string;
}

export interface ApiAllowanceParams extends ApiSimulateParams {
  gasPrice: string;
}

export interface ApiCalculateGas extends ApiSimulateParams {
  receiverAddress: string;
}

export interface ApiSendTokenData extends ApiCalculateGas {
  gasPrice: string;
  gasLimit: string;
}

export interface GasPrice {
  gwei: string;
  usd: number;
}

export interface ApiTransactionHash {
  transactionHash: string;
}

export interface GasPriceList {
  low: GasPrice;
  medium: GasPrice;
  high: GasPrice;
}

export interface ApiGasPrice {
  gasUsed: string;
  optimalGasLimit: string;
  nativeCoinUsdPrice: number;
  gasPrice: GasPriceList;
}

export interface ApiQueue {
  fromTokenAddress: string;
  toTokenAddress: string;
  fromTokenAmount: string;
  toTokenAmount: string;
  gas: ApiGasPrice;
}

export interface ApiQueueParams {
  fromTokenAddress: string;
  toTokenAddress: string;
  fromTokenAmount: string;
  network: ChainId;
  walletAddress: string;
  slippagePercentage: string;
}

export interface ApiSwapParams extends ApiQueueParams {
  gasPrice: string;
  gasLimit: string;
}

export interface ApiAllowanceParams {
  tokenAddress: string;
  network: ChainId;
  walletAddress: string;
}

export enum TransactionStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
}

export enum TransactionType {
  APPROVE = 'APPROVE',
  SEND = 'SEND',
  TRADE = 'TRADE',
}

export interface ApiBlockchainTransaction {
  id: string;
  walletId: string;
  hash: string;
  status: TransactionStatus;
  type: TransactionType;
  request: {
    to: string;
    data: string;
    nonce: number;
    value: string;
    chainId: number;
    gasLimit: string;
    gasPrice: string;
  };
  network: ChainId;
  meta: {
    tokenTo: string;
    tokenFrom: string;
    tokenToImgUrl: string;
    tokenFromImgUrl: string;
  };
  createdAt: string;
  updatedAt: string;
}

export interface SwapTransactionMetaData {
  tokenFrom: string;
  tokenTo: string;
  tokenFromImgUrl: string;
  tokenToImgUrl: string;
}

export interface TransferTransactionMetaData {
  token: string;
  tokenImgUrl: string;
}

export interface ApproveTransactionMetaData {
  token: string;
  tokenImgUrl: string;
}

export interface BlockchainTransaction {
  hash: string;
  status: TransactionStatus;
  type: TransactionType;
  network: ChainId;
  createdAt: string;
  meta: SwapTransactionMetaData;
}

export interface TransactionDetails {
  blockNumber: number | null;
  blockHash: string | null;
  hash: string;
  to: string | null;
  from: string;
  gasLimit: number | null;
  gasPrice: number | null;
  value: number;
  chainId: number;
  isMined: boolean;
  status: number | null;
}

interface ConnectAddress {
  address: string;
  privateKey: string;
}

interface RpcUrl {
  url: string;
  chainId: number;
}

export interface ApiConnectWallet {
  addresses: ConnectAddress[];
  rpcUrls: RpcUrl[];
}

export interface ISnipeSettings {
  failGuard: boolean;
  autoApprove: boolean;
  stopLossEnabled: boolean;
  mevGuardEnabled: boolean;
  takeProfitEnabled: boolean;
  trailingStopLossEnabled: boolean;
  maxGasPrice: number | null;
  buyGasDelta: number | null;
  sellGasDelta: number | null;
  approveGasDelta: number | null;
  buySlippage: number | null;
  sellSlippage: number | null;
  stopLossPercent: number | null;
  takeProfitSellPercent: number | null;
  takeProfitPercent: number | null;
  stopLossSellPercent: number | null;
}

export interface ApiSnipeSettings extends ISnipeSettings {
  createdAt: string;
  updatedAt: string;
  id: string;
  userId: string;
  label: string;
}

export interface UpdatePinParams {
  currentPin: string;
  newPin: string;
}

interface PositionToken {
  address: string;
  name: string;
  symbol: string;
}

export interface TokenDetails {
  pairAddress: string;
  mcap: number;
  volume: number;
  liquidity: number;
  token: PositionToken;
}

export enum PositionStatus {
  PENDING = 'PENDING',
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  ARCHIVED = 'ARCHIVED',
  IN_PROGRESS = 'IN_PROGRESS',
  ABORTED = 'ABORTED',
}

export enum PositionTransactionType {
  BUY = 'BUY',
  SELL = 'SELL',
  APPROVE = 'APPROVE',
}

export enum PositionTransactionStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
}

export interface PositionTransactions {
  type: PositionTransactionType;
  status: PositionTransactionStatus;
  hash: string;
  trigger: string;
  errorDescription: string | null;
  meta: {
    gasFeeInEth?: string;
    receivedEth?: string;
    sellAmountPercent?: number;
  };
}

export enum PositionTrigger {
  USER_REQUEST = 'USER_REQUEST',
  AUTOTRADE = 'AUTOTRADE',
}

export interface PositionModel {
  id: string;
  walletId: string;
  chainId: number;
  dex: string;
  token0: string | null;
  token1: string | null;
  pair: string;
  poolFeePercent: number;
  status: PositionStatus;
  buyPrice: string;
  curPrice: string;
  maxPrice: string;
  buyAmount: number;
  settings: ApiSnipeSettings;
  createdAt: string;
  updatedAt: string;
  priceChange: number;
  trailingPriceChange: number;
  hidden: boolean;
  token: PositionToken;
  transactions: PositionTransactions[];
  trigger?: PositionTrigger;
}

export interface OpenPositionParams {
  pairAddress: string;
  ethAmount: string;
  settings: ISnipeSettings;
}

export interface WalletBalance {
  balance: number;
  balanceInUsd: number;
}

export interface IAutoTradeSettings {
  mCapMin: number | null;
  mCapMax: number | null;
  volumeMin: number | null;
  volumeMax: number | null;
  liquidityMin: number | null;
  liquidityMax: number | null;
  failGuard: boolean;
  autoApprove: boolean;
  stopLossEnabled: boolean;
  mevGuardEnabled: boolean;
  takeProfitEnabled: boolean;
  trailingStopLossEnabled: boolean;
  maxGasPrice: number | null;
  buyGasDelta: number | null;
  sellGasDelta: number | null;
  approveGasDelta: number | null;
  buySlippage: number | null;
  sellSlippage: number | null;
  stopLossPercent: number | null;
  stopLossSellPercent: number | null;
  takeProfitPercent: number | null;
  takeProfitSellPercent: number | null;
}

export interface IAutoTradeFilters {
  sourceCodeVerification: boolean;
  renounce: boolean;
  lpLockPercent: number | null;
  lpLockDays: number | null;
  buyTaxMin: number | null;
  buyTaxMax: number | null;
  sellTaxMin: number | null;
  sellTaxMax: number | null;
  initialLiquidityMin: number | null;
  initialLiquidityMax: number | null;
  currentLiquidityMin: number | null;
  currentLiquidityMax: number | null;
  mCapMin: number | null;
  mCapMax: number | null;
  volume24hMin: number | null;
  volume24hMax: number | null;
  volume6hMin: number | null;
  volume6hMax: number | null;
  volume1hMin: number | null;
  volume1hMax: number | null;
  volume5mMin: number | null;
  volume5mMax: number | null;
  buys24hMin: number | null;
  buys24hMax: number | null;
  buys6hMin: number | null;
  buys6hMax: number | null;
  buys1hMin: number | null;
  buys1hMax: number | null;
  buys5mMin: number | null;
  buys5mMax: number | null;
  sells24hMin: number | null;
  sells24hMax: number | null;
  sells6hMin: number | null;
  sells6hMax: number | null;
  sells1hMin: number | null;
  sells1hMax: number | null;
  sells5mMin: number | null;
  sells5mMax: number | null;
  priceChange24hMin: number | null;
  priceChange24hMax: number | null;
  priceChange6hMin: number | null;
  priceChange6hMax: number | null;
  priceChange1hMin: number | null;
  priceChange1hMax: number | null;
  priceChange5mMin: number | null;
  priceChange5mMax: number | null;
  holdersMin: number | null;
  holdersMax: number | null;
  snipersMin: number | null;
  snipersMax: number | null;
  snipersBribeSumMin: number | null;
  snipersBribeSumMax: number | null;
  ageMin: string | null;
  ageMax: string | null;
}

export interface ApiAutoTradeSettings extends IAutoTradeSettings {
  createdAt: string;
  updatedAt: string;
  id: string;
  userId: string;
  label: string;
}

export interface StrategyModel {
  id: string;
  walletId: string;
  chainId: number;
  status: PositionStatus;
  settings: ApiAutoTradeSettings;
  createdAt: string;
  updatedAt: string;
  hidden: boolean;
}

export enum AutoTradeSource {
  ETH = 'ETH',
  WHALE_SIGNALS = 'WHALE_SIGNALS',
  SNIPE_SCOPE = 'SNIPE_SCOPE',
  BLOCK_XRAY = 'BLOCK_XRAY',
}

export interface BlockchainGas {
  gasPrice: string;
  unit: string;
}

export interface HotToken {
  pairAddress: string;
  exchange: string;
  liquidity: number;
  price: number;
  priceChange24h: number;
  marketCap: number;
  volume: number;
  activeTraders: number;
  createdAt: string;
  swaps: number;
  token: {
    address: string;
    symbol: string;
    name: string;
  };
  tokenRef: {
    address: string;
    symbol: string;
    name: string;
  };
  audit: {
    buyTax: number;
    sellTax: number;
    holders: number;
    isHoneypot: boolean;
  };
}

export interface Currency {
  name: string;
  symbol: string;
  network: ChainId;
  logo: string;
  contract: string;
}

interface SwapRange {
  minAmount: number;
  maxAmount: number | null;
}

interface EstimatedAmount {
  amount: number;
  amountUsd: number;
}

interface FeeDetail {
  currency: string;
  network: ChainId;
  amount: number;
}

interface SwapFee {
  deposit: FeeDetail;
  withdrawal: FeeDetail;
  totals: {
    from: FeeDetail;
    to: FeeDetail;
  };
  converted: {
    currency: string;
    deposit: number;
    withdrawal: number;
    total: number;
  };
}

export interface SwapData {
  exchangeRange: SwapRange;
  estimatedAmount: EstimatedAmount;
  exchangeFee: SwapFee;
  rate: number;
}

export type AccountChain = ChainId.ETHER | ChainId.SOL | ChainId.TON;
