import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { AssetItem } from '@pages/Wallet/components/AssetItem';
import { WalletCard } from '@pages/Wallet/components/WalletCard';
import { WalletCarousel } from '@pages/Wallet/components/WalletCarousel';

//helpers
import { getAccountData } from '@helpers/chains';

//hooks
import { useStores } from '@hooks/useStores';

//img
import badSmile from '@assets/icons/bad-smile.svg';
import bag from '@assets/icons/bag.svg';

//constants
import { ChainId, PageRoutes } from '../../constants';

//components
import { NetworkSelection } from '@components/NetworkSelection';
import { TabsCard } from '@components/common/TabsCard';
import { Preloader } from '@components/common/Preloader';
import { WalletIdentity } from '@components/common/WalletIdentity';
import { ImageCard } from '@components/common/ImageCard';
import { History } from '@pages/Wallet/components/History';

const excludedEvmChainList = [ChainId.TON, ChainId.SOL];

export const Wallet = observer(() => {
  const navigate = useNavigate();

  const { accountStore } = useStores();
  const { address, portfolio, assets, assetsLoaded, transactions, transactionsLoaded, network, setNetwork, account } =
    accountStore;

  const [activeTab, setActiveTab] = useState('History');

  const handleIdentityTitleClick = () => {
    navigate(PageRoutes.ACCOUNT);
  };

  const { isEvm, accountName } = getAccountData(account);

  return (
    <div className="full-page d-flex flex-column">
      <div className="py-3">
        <WalletIdentity
          walletName={accountName}
          address={address}
          isHideAddress
          iconSize={32}
          onWalletNameClick={handleIdentityTitleClick}
        >
          {isEvm && (
            <NetworkSelection
              network={network}
              onChange={setNetwork}
              isHideCoinIcon
              excludedChains={excludedEvmChainList}
            />
          )}
        </WalletIdentity>
      </div>
      <WalletCard address={address} portfolio={portfolio} />
      <WalletCarousel />
      <div className="flex-1 mt-3 overflow-y-auto">
        <TabsCard list={[{ value: 'History' }, { value: 'Assets' }]} active={activeTab} onClick={setActiveTab}>
          <>
            <div className="tab-element tab-Assets pb-6">
              {assetsLoaded && <Preloader className="d-flex flex-column" iconSize={65} textClass="mt-3" inline />}
              {!assetsLoaded &&
                assets.map((asset, index) => {
                  const itemClick = () => {
                    navigate(`/asset/${asset.assetId}`);
                  };

                  return (
                    <AssetItem
                      asset={asset}
                      hide={network !== ChainId.ALL && asset.assetChain !== network}
                      handleNavigate={itemClick}
                      key={`token-list-item-${asset.assetId}-${index}`}
                    />
                  );
                })}
              {!assetsLoaded && !assets.length && (
                <ImageCard
                  imageSrc={bag}
                  title="Your Portfolio is Empty"
                  subtitle="Start tracking your investments here"
                />
              )}
              {!assetsLoaded && !!assets.length && !assets.length && (
                <ImageCard
                  imageSrc={bag}
                  title="No assets on this network"
                  subtitle="Try switching to another network"
                />
              )}
            </div>
            <div className="tab-element tab-History pb-6">
              {transactionsLoaded && <Preloader className="d-flex flex-column" iconSize={65} textClass="mt-3" inline />}
              {!transactionsLoaded && <History network={network} transactions={transactions} />}
              {!transactionsLoaded && !transactions.length && (
                <ImageCard
                  imageSrc={badSmile}
                  title="No Transactions Yet"
                  subtitle="Make your first purchase and see it here"
                />
              )}
            </div>
          </>
        </TabsCard>
      </div>
    </div>
  );
});
