import React from 'react';
import ContentLoader from 'react-content-loader';

export interface PlaceholderProps {
  width?: number;
  height?: number;
  className?: string;
}

export function Placeholder({ width = 80, height = 18, className = '' }: PlaceholderProps) {
  return (
    <span className={className}>
      <ContentLoader viewBox={'0 0 ' + width + ' ' + height} width={width} height={height} foregroundColor="#ddd">
        <rect x="0" y="0" rx="5" ry="5" width={width} height={height} />
      </ContentLoader>
    </span>
  );
}
