import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useInterval from '@hooks/useInterval';
import { FormattedNumber } from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import { chainLogo, chainToken, chainTxExplorer } from '@helpers/chains';
import { getEllipsisTxt } from '@helpers/formatters';
import swapArrow from '@assets/images/send-arrow.svg';
import gasIcon from '@assets/icons/gas.svg';
import { ChainId, PageRoutes, zeroAddress } from '../../constants';
import { toWei } from '@helpers/numbers';
import { Preloader } from '@components/common/Preloader';
import { BadgeIcon } from '@components/common/BadgeIcon';
import { ApiGasPrice, GasPrice, IAsset, TransactionDetails } from '../../types';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { TokenLogo } from '@components/common/TokenLogo';
import { useResponseHandler } from '@hooks/useResponseHandler';
import { IconWithLoading } from '@components/common/IconWithLoading';
import { useShowPopup } from '@vkruglikov/react-telegram-web-app';

interface RouteParams {
  gas?: ApiGasPrice;
  selectedGas?: GasPrice;
}

export interface SendConfirmProps {
  address?: string;
  amount?: string;
  network: ChainId;
  assetSend: IAsset;
  assetReceive?: IAsset | null;
  slippage?: string;
}

const SendConfirm = observer(
  ({ address, amount, network, assetSend: asset, assetReceive, slippage = '5', assetSend }: SendConfirmProps) => {
    const { accountStore } = useStores();
    const { assets, address: accountAddress } = accountStore;
    const navigate = useNavigate();
    const location = useLocation();
    const { gas, selectedGas } = location.state as RouteParams;
    const [tx, setTx] = useState<TransactionDetails | null>(null);
    const [txHash, setTxHash] = useState<string>('');
    const [timer, setTimer] = useState<number>(30);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const handleResponse = useResponseHandler();
    const showPopup = useShowPopup();

    console.log(amount, 'amount_____________________');

    const networkTokens = useMemo(() => {
      return assets.filter((asset) => asset.assetChain === network);
    }, [assets, network]);

    const nativeToken = useMemo(() => {
      const networkToken = chainToken(network);
      return networkTokens.find((asset) => asset.assetSymbol.toLowerCase() === networkToken.toLowerCase());
    }, [networkTokens, network]);

    const amountReceive = new BigNumber(amount || 0)
      .times(asset?.price)
      .div(assetReceive?.price || 1)
      .toNumber();

    const handleSubmit = async () => {
      if (!assetReceive && (!network || !address || !amount || !asset || !gas || !assetSend)) {
        return;
      }
      if (assetReceive && (!network || !amount || !asset || !gas || !assetSend)) {
        return;
      }

      const tokenPrice = 2354.12;
      const tokenAmount = amount ? new BigNumber(amount).div(asset?.price || tokenPrice) : 0;
      console.log(tokenAmount.toString(), 'amount');
      const safeTokenAmount = parseFloat(tokenAmount.toFixed(6));
      setIsSending(true);
      if (!assetReceive) {
        accountStore
          .sendToken({
            network: network || ChainId.ETHER,
            receiverAddress: address!,
            // tokenAmount: toWei(amount || '0', asset.decimals),
            tokenAmount: safeTokenAmount.toString(),
            // tokenAddress: asset.contractAddress || zeroAddress,
            tokenAddress: assetSend.contractAddress || zeroAddress,
            walletAddress: accountAddress,
            gasPrice: new BigNumber(selectedGas?.gwei || '0').times(10 ** 9).toString(),
            gasLimit: Math.ceil(new BigNumber(gas!.optimalGasLimit).toNumber()).toString(),
          })
          .then((response) => {
            if (response?.transactionHash) {
              setTxHash(response.transactionHash);
            } else {
              setIsSending(false);
              handleResponse(response);
            }
          })
          .catch((e) => {
            setIsSending(false);
            handleResponse(e);
          });
      } else {
        accountStore
          .swapTokens({
            network: network || ChainId.ETHER,
            fromTokenAmount: toWei(amount || '0', asset.decimals),
            fromTokenAddress: asset.contractAddress || zeroAddress,
            toTokenAddress: assetReceive.contractAddress || zeroAddress,
            walletAddress: accountAddress,
            gasPrice: new BigNumber(selectedGas?.gwei || '0').times(10 ** 9).toString(),
            gasLimit: Math.ceil(new BigNumber(gas!.optimalGasLimit).toNumber()).toString(),
            slippagePercentage: new BigNumber(slippage || '5').div(100).toString(),
          })
          .then((response) => {
            if (response?.transactionHash) {
              setTxHash(response.transactionHash);
            } else {
              setIsSending(false);
              handleResponse(response);
            }
          })
          .catch((e) => {
            setIsSending(false);
            handleResponse(e);
          });
      }
    };

    const fetchTx = () => {
      accountStore
        .getBlockchainTransaction(txHash, network || ChainId.ETHER)
        .then((response) => {
          if (response && response.hash) {
            setIsSending(false);
            setTx(response);
            setIsProcessing(true);
            accountStore.loadData();
          } else {
            if (response.data.error && response.data.error === 'Transaction not found') {
              // that's fine, trust me
              console.log('Transaction not found, but we know that');
            } else {
              console.log({ response });
              setIsSending(false);
              handleResponse(response);
            }
          }
        })
        .catch((e) => {
          console.log({ e });
          setIsSending(false);
          handleResponse(e);
        });
    };

    useInterval(
      () => {
        fetchTx();
      },
      txHash && (!tx || !tx.isMined || tx.status === null) ? 3000 : null
    );

    useEffect(() => {
      if (tx?.isMined) {
        setIsProcessing(false);
      }
    }, [tx]);

    useInterval(
      () => {
        setTimer(timer - 1);
      },
      timer > 0 ? 1000 : null
    );

    const feeAmount = useMemo(() => {
      return gas && selectedGas
        ? new BigNumber(gas.optimalGasLimit)
            .times(selectedGas.gwei)
            .times(10 ** 9)
            .div(10 ** 18)
            .toString()
        : 0;
    }, [gas, selectedGas]);

    const maxFeeAmount = useMemo(() => {
      return gas
        ? new BigNumber(gas.optimalGasLimit)
            .times(gas.gasPrice.high.gwei)
            .times(10 ** 9)
            .div(10 ** 18)
            .toString()
        : 0;
    }, [gas]);

    if (isProcessing || isSending) {
      return (
        <div className="py-4">
          <Preloader
            className="d-flex flex-column"
            iconSize={65}
            textClass="mt-3"
            text={`${assetReceive ? 'Waiting confirmations...' : 'Sending...'}`}
            inline
          />
        </div>
      );
    }

    if (!isProcessing && tx) {
      return (
        <div className="full-page d-flex flex-column align-items-center justify-content-center tx-center ht-100p">
          <BadgeIcon badgeSize={110} className="tx-64 mb-3">
            {tx.status === 1 ? '👍' : '🚫'}
          </BadgeIcon>
          {tx.status === 1 && (
            <div className="tx-semibold tx-28">
              <div>{assetReceive ? 'Transaction' : 'Transfer'} successful</div>
              <div className={`${assetReceive ? 'tx-success' : ''}`}>
                {assetReceive ? amountReceive : new BigNumber(amount || 0).times(-1).toNumber()}
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <TokenLogo
                  logo={(assetReceive || asset).imageUrl}
                  address={(assetReceive || asset).assetId}
                  name={(assetReceive || asset).assetName}
                  size={30}
                  containerClassName="me-2"
                />
                {(assetReceive || asset).assetSymbol}
              </div>
            </div>
          )}

          {tx.status === 0 && (
            <div>
              <div className="tx-semibold tx-28">Oops... Transfer failed</div>
            </div>
          )}

          <button className="btn btn-main-pink wd-230 my-4" onClick={() => navigate(PageRoutes.WALLET)}>
            Done
          </button>

          <div className="tx-13 tx-muted">
            Check tx in
            <a href={chainTxExplorer(network, txHash)} target="_blank" rel="noreferrer" className="tx-white ms-1">
              {chainTxExplorer(network, txHash).split('https://')[1].split('.')[0]}
            </a>
          </div>
        </div>
      );
    }

    if (!asset) {
      return (
        <div className="py-4">
          <div className="card tx-center mb-4">Error: Asset not found</div>

          <button className="btn btn-light wd-100p" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>
      );
    }

    return (
      <div className="py-4">
        <div className="card align-items-center py-4">
          <TokenLogo logo={asset.imageUrl} address={asset.assetId} name={asset.assetName} size={35} />
          <div className="tx-semibold mt-2">
            {amount} {asset.assetSymbol}
          </div>
          <FormattedNumber
            value={new BigNumber(amount || 0).times(asset?.price).toNumber()}
            suffix="$"
            className="tx-13 tx-muted"
            decimals={6}
          />
        </div>

        <div className="swap-separator between-cards">
          <img src={swapArrow} alt="Swap" className="wd-45 ht-45" />
        </div>

        {!assetReceive && (
          <div className="card align-items-center py-4 mb-4">
            <img
              src={chainLogo(network || 'ethereum')}
              alt={network || 'ethereum'}
              className="wd-35 ht-35 mn-wd-35 mb-2"
            />
            <div className="tx-semibold tx-13">{address}</div>
            <div className="tx-13 tx-muted">{getEllipsisTxt(address || '', 5)}</div>
          </div>
        )}

        {assetReceive && (
          <div className="card align-items-center py-4 mb-4">
            <TokenLogo
              logo={assetReceive.imageUrl}
              address={assetReceive.assetId}
              name={assetReceive.assetName}
              size={35}
            />
            <div className="tx-semibold mt-2">
              {amountReceive} {assetReceive.assetSymbol}
            </div>
            <FormattedNumber
              value={new BigNumber(amountReceive || 0).times(assetReceive.price).toNumber()}
              suffix="$"
              className="tx-13 tx-muted"
              decimals={6}
            />
          </div>
        )}

        {assetReceive && (
          <div className="d-flex justify-content-center align-items-center mb-4 tx-semibold">
            <span>1 {asset.assetSymbol}</span>
            <span className="mx-1">=</span>
            <FormattedNumber
              value={new BigNumber(asset?.price).div(assetReceive.price).toNumber()}
              postfix={assetReceive.assetSymbol}
              subZeros
              decimals={6}
            />
          </div>
        )}

        <div className="card">
          <div className="d-flex justify-content-end align-items-center tx-semibold">
            <div className="ms-0 me-auto">Total:</div>
            <div className="word-break lh-1 ps-2">
              {amount}0000000000000000000000000000 {asset.assetSymbol}
            </div>
            <FormattedNumber
              value={new BigNumber(amount || 0).times(asset?.price).toNumber()}
              suffix="($"
              postfix=")"
              className="tx-muted ms-1"
              decimals={6}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13 tx-muted my-2">
            <img src={gasIcon} alt="Estimated Fee" />
            <div className="ms-2 me-auto">Estimated Fee</div>
            <FormattedNumber
              value={feeAmount}
              decimals={6}
              postfix={nativeToken?.assetSymbol}
              className="me-1 tx-white"
            />
            <FormattedNumber
              value={feeAmount && gas ? new BigNumber(feeAmount).times(gas.nativeCoinUsdPrice).toNumber() : null}
              suffix="($"
              postfix=")"
            />
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
            <img src={gasIcon} alt="Max Fee" />
            <div className="ms-2 me-auto">Max Fee</div>
            <FormattedNumber
              value={maxFeeAmount}
              decimals={6}
              postfix={nativeToken?.assetSymbol}
              className="me-1 tx-white"
            />
            <FormattedNumber
              value={maxFeeAmount && gas ? new BigNumber(maxFeeAmount).times(gas.nativeCoinUsdPrice).toNumber() : null}
              suffix="($"
              postfix=")"
            />
          </div>
        </div>

        <button className="btn btn-main-pink wd-100p mt-4" onClick={handleSubmit} disabled={isSending}>
          <IconWithLoading isLoading={isSending} />
          Confirm
        </button>

        <button
          className="btn btn-transparent wd-100p mt-4"
          onClick={() => {
            showPopup({
              title: 'Cancel Transaction',
              message: assetReceive
                ? 'Do you really want to cancel the currency exchange'
                : 'Do you really want to cancel the currency sending funds to another wallet',
              buttons: [
                {
                  text: 'Close',
                  id: 'close',
                },
                {
                  text: 'Yes',
                  type: 'destructive',
                  id: 'cancel',
                },
              ],
            }).then((result) => {
              if (result === 'cancel') {
                navigate(-1);
              }
            });
          }}
        >
          Cancel
        </button>
      </div>
    );
  }
);

export default SendConfirm;
