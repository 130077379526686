import React, { useMemo, useState } from 'react';
import { ReactComponent as Search } from '@assets/icons/search.svg';
import debounce from 'lodash/debounce';

//types
import { IconPosition, InputSize } from '@interfaces/input.types';
import { ButtonShape, ButtonSize, ButtonType } from '@interfaces/button.types';

//img
import close from '@assets/icons/close.svg';

//constants
import { ChainId } from '../../../constants';

//components
import { NetworkSelection } from '@components/NetworkSelection';
import { Button } from '@components/common/Button';
import { Input } from '@components/common/Input';

interface ResearchHeaderProps {
  onChange: (value: string) => void;
}

export const ResearchHeader = ({ onChange }: ResearchHeaderProps) => {
  const [isSearch, setIsSearch] = useState(false);

  const debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);

  return (
    <div className="d-flex align-items-center pb-3 w-100 gap-2 mn-ht-10p">
      {isSearch ? (
        <Input
          placeholder="Search"
          onChange={(e) => debouncedOnChange(e.target.value)}
          iconPosition={IconPosition.Left}
          inputSize={InputSize.Small}
          onClick={() => setIsSearch(false)}
          onClickIcon={close}
          icon={<Search className="wd-16 ht-16" />}
        />
      ) : (
        <>
          <NetworkSelection buttonClassName="flex-1" network={ChainId.POLYGON} onChange={() => {}} />
          <Button
            size={ButtonSize.Auto}
            type={ButtonType.Secondary}
            onClick={() => {
              setIsSearch((prevState) => !prevState);
            }}
            shape={ButtonShape.Round}
          >
            <Search className="wd-20 ht-20 tx-muted" />
          </Button>
        </>
      )}
    </div>
  );
};
