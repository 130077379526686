import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

//hooks
import { useStores } from '@hooks/useStores';
import { useResponseHandler } from '@hooks/useResponseHandler';
import { useNotification } from '@hooks/useNotification';

//constants
import { PageRoutes } from '../../constants';

//img
import lock from '@assets/images/lock.svg';

//styles
import style from '@styles/components/modules/login.module.scss';

//components
import { PinInput } from '@components/common/PinInput';
import { ImageCard } from '@components/common/ImageCard';
import { Preloader } from '@components/common/Preloader';

interface CreateWalletProps {
  isImport?: boolean;
}

export const CreateWallet = observer(({ isImport = false }: CreateWalletProps) => {
  const { accountStore } = useStores();
  const { profile } = accountStore;
  const navigate = useNavigate();
  const notify = useNotification();
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const handleResponse = useResponseHandler();

  const handleSubmit = () => {
    if (isImport) {
      navigate(PageRoutes.IMPORT_WALLET, { replace: true, state: { pin } });
      return;
    }

    accountStore
      .register(pin)
      .then(() => {
        accountStore.loadUser().then(() => {
          navigate(PageRoutes.WALLET_CREATED, { replace: true });
        });
      })
      .catch((e) => {
        setPin('');
        setConfirmPin('');
        setIsInvalid(true);
        handleResponse(e.response);
      });
  };

  const handleComplete = (value: string) => {
    if (!pin) {
      setPin(value);
    } else {
      if (pin !== value) {
        setIsInvalid(true);
        setPin('');
        notify('PIN codes do not match', { type: 'popup-danger' });
      } else {
        setConfirmPin(value);
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (!isImport && confirmPin && pin === confirmPin && profile) {
      navigate(PageRoutes.WALLET_CREATED, { replace: true });
    }
  }, [pin, confirmPin, profile, isImport]);

  if (confirmPin && pin === confirmPin) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader
          inline
          iconSize={64}
          className="d-flex flex-column align-items-center"
          textClass="d-block mt-2"
          iconClass="d-block"
          text=""
        />
        <div className="tx-muted mt-3">
          Great! We are creating your wallet
          <br />
          «BlackBunny Wallet»
        </div>
      </div>
    );
  }

  return (
    <div className="tx-center full-page d-flex flex-column pb-0 position-relative gap-4">
      <div className={!pin ? style.blurOrangeHeader : style.blurGreenHeader} />
      <div className="mt-5">
        <ImageCard
          imageSrc={lock}
          title={!pin ? 'Create Your PIN' : 'Confirm Your PIN'}
          subtitle={!pin ? 'Keep your wallet safe with a unique PIN' : 'Double-check the PIN code you entered'}
        />
      </div>
      <PinInput length={4} onChange={() => setIsInvalid(false)} onComplete={handleComplete} isInvalid={isInvalid} />
    </div>
  );
});
