import React from 'react';
import classNames from 'classnames';

//helpers
import { chainLogo, chainToken } from '@helpers/chains';

//constants
import { ChainId } from '../../../constants';

//types
import { ButtonSize, ButtonType } from '@interfaces/button.types';

// components
import { Button } from '@components/common/Button';

interface AccountFilterPanelProps {
  onSelect: (accountId: ChainId) => void;
  currentTab: string;
  filterList: ChainId[];
}

export const AccountFilterPanel = ({ onSelect, filterList, currentTab }: AccountFilterPanelProps) => (
  <div className="d-flex overflow-auto w-100">
    <div className="d-flex flex-nowrap gap-2">
      {filterList.map((tab, i) => {
        const isActive = tab === currentTab;
        const isAllTab = tab === ChainId.ALL;

        return (
          <Button
            key={String(i)}
            type={isActive ? ButtonType.SecondaryDark : ButtonType.SecondaryLight}
            size={ButtonSize.Auto}
            className={classNames('flex-shrink-0 px-2 py-2', {
              'border-pink': isActive,
              'px-4': isAllTab,
            })}
            onClick={() => onSelect(tab)}
            icon={!isAllTab && <img src={chainLogo(tab)} alt="Chain logo" loading="lazy" className="wd-20 ht-20" />}
          >
            {chainToken(tab) === 'ETH' ? 'EVM' : chainToken(tab) || 'All'}
          </Button>
        );
      })}
    </div>
  </div>
);
