import axios, {AxiosResponse} from "axios";
import {ENV} from "../constants";
// import {applyAuthTokenInterceptor} from "axios-jwt";

export const axiosInstance = axios.create({
  baseURL: `${ENV.API_URL}/api/v1`,
  // headers: {
  //   'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
  // },
});

export interface ApiError {
  error?: string;
  serviceName?: string;
}

export interface ResponseData<T> extends ApiError {
  data?: T;
  status: boolean;
}

export interface StatusResponse<T> {
  status: number;
  data?: ResponseData<T>;
}

// applyAuthTokenInterceptor(axiosInstance, {
//   requestRefresh: () => {
//     return new Promise((resolve, reject) => {
//       resolve(localStorage.getItem('token') || '');
//     });
//   },  // async function that takes a refreshToken and returns a promise the resolves in a fresh accessToken
//   header: "Authorization",  // header name
//   headerPrefix: "Bearer ",  // header value prefix
// });

interface ApiProps {
  method: 'post' | 'get' | 'delete' | 'put' | 'patch';
  path: string;
  data?: any;
  v2?: boolean;
}

export enum ApiServer {
  DEFAULT = 'default',
  DEX = 'dex',
}

export default function api<T>({ method, path, data }: ApiProps, server = ApiServer.DEFAULT): Promise<ResponseData<T>> {
  let baseURL = ENV.API_URL;
  switch (server) {
    case ApiServer.DEX:
      baseURL = ENV.DEX_API_URL
      break;
    default:
      baseURL = ENV.API_URL;
      break;
  }
  baseURL += '/api/v1';
  const url = data &&  method === 'get' ? `${path}?${new URLSearchParams(data)}` : path;
  return new Promise((resolve, reject) => {
    axiosInstance<T>({
      method,
      url,
      data,
      baseURL,
    }).then((response) => {
      if (response.data !== undefined && (response.status === 200 || response.status === 204)) {
        // @ts-ignore
        resolve(response.data);
      } else {
        reject(response);
      }
    }).catch((response) => {
      reject(response);
    });
  });
}

export function apiRaw<T>({ method, path, data }: ApiProps): Promise<AxiosResponse<ResponseData<T>>> {
  const url = method === 'get' ? `${path}?${new URLSearchParams(data)}` : path;
  return new Promise((resolve, reject) => {
    axiosInstance<ResponseData<T>>({
      method,
      url,
      data,
    }).then((response) => {
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    }).catch((response) => {
      reject(response);
    });
  });
}
