import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageRoutes } from '../constants';

interface RouteWrapperProps {
  children: React.ReactNode;
}

export const RouteWrapper = observer(({ children }: RouteWrapperProps) => {
  const { accountStore } = useStores();
  const { profile, checkDone, redirectTo, isAppInitialized } = accountStore;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isAppInitialized) return;
    if (checkDone === 200 && profile) {
      // console.log('redirect to wallet');
      accountStore.setAppInitialized(true);
      navigate(PageRoutes.WALLET, { replace: true });
    } else if (checkDone === 401) {
      // console.log('redirect to login');
      navigate(PageRoutes.LOGIN, { replace: true });
    }
  }, [isAppInitialized, checkDone, profile]);

  useEffect(() => {
    if (isAppInitialized && redirectTo && redirectTo !== PageRoutes.WALLET && pathname === PageRoutes.WALLET) {
      accountStore.setRedirectTo(null);
      // console.log('redirect to 2', redirectTo);
      navigate(redirectTo);
    }
    // accountStore.setRedirectTo(null);
  }, [redirectTo, isAppInitialized, pathname]);

  return (
    // <motion.main
    //   initial="initial"
    //   animate="enter"
    //   exit="exit"
    //   variants={transitionVariants}
    // >
    <>{children}</>
    // </motion.main>
  );
});
