import classNames from 'classnames';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { getRandomInt } from '@helpers/numbers';

//img
import { ReactComponent as Check } from '@assets/icons/green-check.svg';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';

interface NotificationProps {
  type?: 'popup-success' | 'popup-danger';
  duration?: number;
}

export const useNotification = () => {
  const handleRemove = useCallback((id: string, text: string, type: string) => {
    toast.custom(
      <div className={`notification notification-out px-3 py-2 rounded-5 tx-13 tx-white alert-box-shadow bg-${type}`}>
        {text}
      </div>,
      { id }
    );

    setTimeout(() => {
      toast.remove(id);
    }, 300);
  }, []);

  return useCallback(
    (
      text: string,
      { type = 'popup-success', duration = 5000 }: NotificationProps = { type: 'popup-success', duration: 5000 }
    ) => {
      const id = `${Date.now()}${getRandomInt(100)}`;
      let timeout: NodeJS.Timeout | undefined;

      if (duration) {
        timeout = setTimeout(() => {
          handleRemove(id, text, type);
        }, duration);
      }

      const isSuccess = type === 'popup-success';

      toast.custom(
        <div
          className={classNames('notification w-100 p-3 tx-16', {
            'rounded-5 tx-success': isSuccess,
            'rounded-2 tx-white': !isSuccess,
            [`bg-${type}`]: true,
          })}
          onClick={() => {
            if (timeout) {
              clearTimeout(timeout);
            }
            handleRemove(id, text, type);
          }}
        >
          <div className="d-flex gap-2 align-items-start px-1">
            {isSuccess ? (
              <Check className="wd-24 h-24 flex-shrink-0" />
            ) : (
              <Warning className="wd-24 h-24 flex-shrink-0" />
            )}
            {text}
          </div>
        </div>,
        { id }
      );
    },
    [handleRemove]
  );
};
