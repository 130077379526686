import React from 'react';
import regi from '@assets/images/welcome.svg';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { Preloader } from '@components/common/Preloader';
import Onboarding from '@pages/Onboarding';

const Welcome = observer(() => {
  const { accountStore } = useStores();
  const { checkDone } = accountStore;
  const navigate = useNavigate();

  return checkDone === 404 || checkDone === 403 ? (
    <Onboarding />
  ) : (
    <div className="tx-center pb-5">
      <div>
        <img src={regi} alt="Registration" className="wd-100p px-0" />
      </div>
      <h2 className="tx-28">
        First Web3 Wallet
        <br />
        on Telegram
      </h2>
      <p className="tx-16 my-3 tx-muted">
        BlackBunny Wallet on Telegram - simplest way for check, purchase and track web3
      </p>

      {!checkDone && (
        <Preloader
          inline
          iconSize={64}
          className="d-flex flex-column mt-5"
          textClass="d-block mt-2"
          iconClass="d-block"
        />
      )}

      {(checkDone === 404 || checkDone === 403) && (
        <div>
          <button className="btn btn-light wd-100p" onClick={() => navigate(PageRoutes.CREATE_WALLET)}>
            Create Wallet
          </button>

          <button className="btn btn-secondary wd-100p mt-3" onClick={() => navigate(PageRoutes.IMPORT_PIN)}>
            Import Wallet
          </button>
        </div>
      )}
    </div>
  );
});

export default Welcome;
