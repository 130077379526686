import React, { useRef } from 'react';

//img
import colorKey from '@assets/icons/key-color.svg';

//components
import { DetailModal } from '@components/common/DetailModal';
import { CopyButton } from '@components/common/CopyButton';

export interface CopyModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  textToCopy: string;
  secondaryText?: string;
  onSecondary?: () => void;
}

export const CopyModal = ({
  show,
  onClose,
  title,
  subtitle,
  textToCopy,
  secondaryText,
  onSecondary,
}: CopyModalProps) => {
  const copyButtonRef = useRef<{ triggerCopy: () => void } | null>(null);

  const handleCopy = () => {
    if (copyButtonRef.current) {
      copyButtonRef.current.triggerCopy();
    }
  };

  return (
    <DetailModal
      show={show}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      imageSrc={colorKey}
      secondaryText={secondaryText}
      onSecondary={onSecondary}
      primaryText="Copy"
      onPrimary={handleCopy}
    >
      <CopyButton ref={copyButtonRef} text={textToCopy} noIcon>
        <div className="card tx-muted tx-14 mb-5 text-break border-gray-800 bd-1">{textToCopy}</div>
      </CopyButton>
    </DetailModal>
  );
};
