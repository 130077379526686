import React, { useEffect, useRef, useState } from 'react';
import { Preloader } from '@components/common/Preloader';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@hooks/useStores';
import { useResponseHandler } from '@hooks/useResponseHandler';
import { PinInput } from '@components/common/PinInput';
import { observer } from 'mobx-react-lite';
import SeedPhrase from '@pages/Wallet/SeedPhrase';

const ExportSeedPhrase = observer(() => {
  const { accountStore } = useStores();
  const [mnemonic, setMnemonic] = useState<string>('');
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const inputRefSecond = useRef(null);
  const [pin, setPin] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const handleResponse = useResponseHandler();

  useEffect(() => {
    if (pin && !mnemonic) {
      accountStore
        .loadMnemonic(pin)
        .then((response) => {
          if (response && response.data?.mnemonic) {
            setMnemonic(response.data.mnemonic);
          } else {
            setIsInvalid(true);
            setPin('');
            setError(response.response.data.error);
          }
        })
        .catch((e) => {
          setPin('');
          setMnemonic('');
          setIsInvalid(true);
          handleResponse(e.response);
          setError('');
        });
    }
  }, [pin, mnemonic]);

  useEffect(() => {
    return () => {
      accountStore.resetMnemonic();
    };
  }, []);

  if (pin && !mnemonic) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader
          inline
          iconSize={64}
          className="d-flex flex-column align-items-center"
          textClass="d-block mt-2"
          iconClass="d-block"
        />
      </div>
    );
  }

  if (!pin) {
    return (
      <div className="tx-center full-page d-flex flex-column">
        <h1 className="tx-28 mt-5">PIN</h1>

        <div className="tx-muted my-3">Please enter your PIN code</div>

        {isInvalid && !!error && <div className="tx-bold tx-danger mb-2">{error}</div>}

        <div className={`flex-1 ${isInvalid ? 'invalid-pin' : ''}`}>
          <PinInput length={4} onChange={() => setIsInvalid(false)} onComplete={setPin} isInvalid={isInvalid} />
        </div>
      </div>
    );
  }

  return <SeedPhrase />;
});

export default ExportSeedPhrase;
