import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { referralLevels, ReferralLevelType } from '@pages/Referral/constants';
import ReferralBadge from '@pages/Referral/components/ReferralBadge';
import Progress from '@components/common/Progress';
import { FormattedNumber } from '@components/common/FormattedNumber';
import { numberWithCommas } from '@helpers/numbers';

interface ReferralLevelModalProps {
  type: ReferralLevelType | null;
  valueNow: number;
  onClose: () => void;
}

const ReferralLevelModal = ({ type, valueNow, onClose }: ReferralLevelModalProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [lastType, setLastType] = useState<ReferralLevelType>();

  const level = useMemo(() => {
    return referralLevels.find((level) => level.type === lastType) || referralLevels[0];
  }, [lastType]);

  const progressBg = useMemo(() => {
    switch (lastType) {
      case ReferralLevelType.BRONZE:
        return 'beige';
      case ReferralLevelType.SILVER:
        return 'silver';
      case ReferralLevelType.GOLD:
        return 'gold';
      case ReferralLevelType.PLATINUM:
        return 'white';
      case ReferralLevelType.DIAMOND:
        return 'diamond-gradient';
      case ReferralLevelType.MASTER:
        return 'master-gradient';
      default:
        return 'beige';
    }
  }, [lastType]);

  useEffect(() => {
    if (type && lastType !== type) {
      setLastType(type);
    }
    setShow(!!type);
  }, [lastType, type]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{level.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card mb-4 bg-semi-transparent-10">
          <div className="d-flex flex-row justify-content-start align-items-center">
            <div className="wd-65 ht-65 mn-wd-65 mx-wd-65">
              <ReferralBadge type={level.type} />
            </div>
            <div className="d-flex flex-column ms-3">
              <div className={`tx-17 tx-semibold ${valueNow > level.turnover ? 'tx-success' : ''}`}>{level.label}</div>
              <div className="tx-13">Turnover {numberWithCommas(level.turnover, false, 4, false, 0)} ETH</div>
            </div>
          </div>
        </div>

        <div className="card mb-4 bg-semi-transparent-10">
          <div className="d-flex flex-column wd-100p">
            <div className="tx-17 tx-semibold mb-3">Referral bonus</div>

            <div className="d-flex justify-content-between align-items-center tx-15">
              <div className="tx-muted">Invited by you:</div>
              <div>{level.refPercent1Line}%</div>
            </div>

            <div className="d-flex justify-content-between align-items-center tx-15">
              <div className="tx-muted">Invited by your friends:</div>
              <div>{level.refPercent2Line}%</div>
            </div>

            <div className="d-flex justify-content-between align-items-center tx-15">
              <div className="tx-muted">Total bonus:</div>
              <div>{level.refPercent2Line + level.refPercent1Line}%</div>
            </div>
          </div>
        </div>

        <div className="card mb-4 bg-semi-transparent-10">
          <div className="tx-17 tx-semibold">Turnover {level.turnover} ETH</div>
          <Progress
            value={!level.turnover || valueNow > level.turnover ? 100 : valueNow}
            max={level.turnover || 100}
            forceColor={progressBg}
            className="mt-2 wd-100p"
          />
          <div className="tx-12 tx-muted d-flex align-items-center">
            <FormattedNumber value={valueNow} postfix="ETH" decimals={2} floor className="me-1" useFormat />
            of
            <FormattedNumber value={level.turnover} postfix="ETH" decimals={2} floor className="mx-1" useFormat />
            to
            <button className="btn btn-transparent p-0 tx-right tx-capitalize tx-12 tx-muted text-decoration-underline ms-1">
              {level.label}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReferralLevelModal;
