import _ from 'lodash';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import api, { apiRaw, axiosInstance } from '@helpers/api';
import {
  ApiAllowanceParams,
  ApiBlockchainTransaction,
  ApiCalculateGas,
  ApiConnectWallet,
  ApiGasPrice,
  ApiGetAllowanceParams,
  ApiQueue,
  ApiQueueParams,
  ApiSendTokenData,
  ApiSimulateParams,
  ApiSnipeSettings,
  ApiSwapParams,
  ApiTransactionHash,
  AssetsRaw,
  BlockchainGas,
  BlockchainTransaction,
  HotToken,
  IAsset,
  ISnipeSettings,
  JWT,
  Mnemonic,
  OpenPositionParams,
  Portfolio,
  PositionModel,
  TokenDetails,
  TransactionDetails,
  UpdatePinParams,
  UserAccount,
  UserProfile,
  WalletBalance,
} from '../types';
import { ApiWalletTransactions, ApiWalletTransactionsData } from '@interfaces/transactions.types';
import { ChainId } from '../constants';
import { ApiReferral } from '@pages/Referral/constants';
import { Strategy, StrategyMainData, StrategyRaw } from '@pages/AutoTrade/constants';

export class AccountStore {
  @observable
  private _network: ChainId = ChainId.ALL;

  @observable
  private _address: string | null = null;

  @observable
  private _redirectTo: string | null = null;

  @observable
  private _token: string | null = null;

  @observable
  private _lockedToken: string | null = null;

  @observable
  private _profile: UserProfile | null = null;

  @observable
  private _account: UserAccount | null = null;

  @observable
  private _userAccounts: UserAccount[] | null = null;

  @observable
  private _portfolio: Portfolio | null = null;

  @observable
  private _assets: IAsset[] = [];

  @observable
  private _transactions: ApiWalletTransactionsData[] = [];

  @observable
  private _balance: WalletBalance | null = null;

  @observable
  private _connectWallet: ApiConnectWallet | null = null;

  @observable
  private _snipeSettings: ApiSnipeSettings | null = null;

  @observable
  private _isLoggedIn: boolean = false;

  @observable
  private _checkDone: number = 0;

  @observable
  private _isAppInitialized: boolean = false;

  @observable
  private _mnemonic: string = '';

  @observable
  private _assetsLoaded: boolean = false;

  @observable
  private _transactionsLoaded: boolean = false;

  @observable
  private _lastLogin: number = 0;

  @action
  public loadAccountInfo() {
    if (!this._token) {
      return;
    }

    if (!this._profile) {
      this.loadUser();
    }

    // if (this._web3Enabled) {
    //   getSubscription(this._account)
    //     .then((response) => {
    //       this._subscription = response;
    //     })
    //     .catch((e) => {
    //       console.error(e);
    //     });
    //   allowance(this._account)
    //     .then((response) => {
    //       this._allowance = response;
    //     })
    //     .catch((e) => {
    //       console.error(e);
    //     });
    // }
  }

  constructor() {
    makeAutoObservable(this);
    this.setToken(localStorage.getItem('token'));
  }

  @action
  public handleGetParams(searchParams: URLSearchParams) {
    if (searchParams.has('token')) {
      this._lockedToken = searchParams.get('token');
    }
    if (localStorage.getItem('token') && (!searchParams.has('force') || searchParams.get('force') !== 'true')) {
      this.setToken(localStorage.getItem('token'));
      this.loadAccountInfo();
    } else if (searchParams.has('token')) {
      this.setToken(searchParams.get('token'));
      this.loadAccountInfo();
    }
  }

  @action
  addMockAsset(asset: IAsset) {
    this._assets.push(asset);
  }

  @action
  setNetwork = (value: ChainId) => {
    this._network = value;
    this.performLoadActions(this._account?.id!, value === ChainId.ALL ? undefined : value);
  };

  @action
  logout() {
    this.setAddress(null);
  }

  @action
  setAddress(value: string | null) {
    this._address = value;
  }

  @action
  setAccount = (accountId: string) => {
    this._account = _.find(this._profile?.accounts, { id: accountId })!;
    this.performLoadActions(this.account?.id!, this.account?.blockchain!);
  };

  get redirectTo() {
    return this._redirectTo;
  }

  @action
  setRedirectTo(value: string | null) {
    this._redirectTo = value;
  }

  @action
  setToken(value: string | null) {
    this._token = value;

    if (value) {
      localStorage.setItem('token', value);
      // setAuthTokens({
      //   accessToken: value,
      //   refreshToken: value,
      // });
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${value}`;
      // } else {
      //   this._profile = null;
      //   this._isLoggedIn = false;
      //   localStorage.removeItem('token');
      //   // clearAuthTokens();
      //   axiosInstance.defaults.headers.common['Authorization'] = ``;
    }
  }

  get network() {
    return this._network;
  }

  @computed
  get address() {
    if (!this._profile?.accounts) return '';

    const blockchain = _.includes([ChainId.TON, ChainId.SOL], this._network) ? this._network : ChainId.ETHER;

    const selectedAddress = _.get(_.find(this._profile.accounts, { blockchain }), 'address', '');

    return selectedAddress;
  }

  get token() {
    return this._token;
  }

  get account() {
    return this._account;
  }

  get accounts() {
    return this._userAccounts;
  }

  get lockedToken() {
    return this._lockedToken;
  }

  get isLoggedIn() {
    return this._isLoggedIn;
  }

  get profile() {
    return this._profile;
  }

  @computed
  get portfolio() {
    if (this._network === ChainId.ALL) {
      return _.get(this._portfolio, 'summarize', null);
    } else {
      return _.get(
        _.find(this._portfolio?.balances, (p) => p.portfolio.network === this.network),
        'portfolio',
        null
      );
    }
  }

  get balance() {
    return this._balance;
  }

  get checkDone() {
    return this._checkDone;
  }

  get isAppInitialized() {
    return this._isAppInitialized;
  }

  @action
  setAppInitialized(value: boolean) {
    this._isAppInitialized = value;
  }

  get mnemonic() {
    return this._mnemonic;
  }

  @action
  resetMnemonic() {
    this._mnemonic = '';
  }

  @computed
  get assets() {
    return _.filter(
      this._assets,
      (asset) => asset.flags.displayable && (this.network === ChainId.ALL || asset.assetChain === this.network)
    );
  }

  @computed
  get transactions() {
    return this.network === ChainId.ALL ? this._transactions : _.filter(this._transactions, { chain: this.network });
  }

  get assetsLoaded() {
    return this._assetsLoaded;
  }

  get transactionsLoaded() {
    return this._transactionsLoaded;
  }

  get connectWalletInfo() {
    return this._connectWallet;
  }

  get snipeSettings() {
    return this._snipeSettings;
  }

  @action
  async register(pin: string, mnemonic?: string) {
    let data: any = { pin };
    if (mnemonic) {
      data = { pin, mnemonic };
    }
    return api<Mnemonic>({ method: 'post', path: '/wallet/register', data }).then((response) => {
      if (response.data) {
        this.setToken(response.data.jwt);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.jwt}`;
        this._mnemonic = response.data.mnemonic;
        this.loadAccountInfo();
        this._isLoggedIn = true;
      }
      return response;
    });
  }

  @action
  setHeader() {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
  }

  @action
  async login(pin: string) {
    return api<JWT>({ method: 'post', path: '/wallet/unlock', data: { pin } }).then((response) => {
      if (response.data) {
        this.setToken(response.data.jwt);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.jwt}`;
        this.loadAccountInfo();
        this._isLoggedIn = true;
      }
      return response;
    });
  }

  @action
  async loadData() {
    this.performLoadActions(this.account?.id!, this.account?.blockchain!);
    this.loadConnectWalletInfo();
    this.loadSnipeSettings();
    // this.loadBalance(); getting it via loadPortfolio
  }

  @action
  async loadUser() {
    return apiRaw<UserProfile>({ method: 'get', path: '/user' })
      .then((response) => {
        this._profile = response.data.data || null;
        this._checkDone = response.status;
        this._userAccounts = this._profile?.accounts ?? null;
        this._account = this._profile?.accounts[0] ?? null;
        if (response.status && response.data.data) {
          this.loadData();
        }
      })
      .catch((response) => {
        this._checkDone = response.response.status;
        if (response.response.status === 401) {
          this.logout();
        }
        if (response.response.status === 403 || response.response.error === 'User not registered') {
          this.setToken(this.lockedToken);
        }
        console.error(response.response.data);
      });
  }

  // @action
  // async loadBalance() {
  //   return apiRaw<WalletBalance>({ method: 'get', path: `/wallet/ethereum/${this.address}/balance` })
  //     .then((response) => {
  //       this._balance = response.data.data || null;
  //     })
  //     .catch((response) => {
  //       if (response.response.status === 401) {
  //         this.logout();
  //       }
  //       console.error(response.response.data);
  //     });
  // }

  @action
  async getBlockchainTransactions(walletAddress: string) {
    this.setHeader();
    return api<BlockchainTransaction[]>({
      method: 'get',
      path: `/blockchain/transactions/${walletAddress}`,
    })
      .then((response) => {
        return response.data || [];
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async getBlockchainGas(network: string = ChainId.ETHER) {
    this.setHeader();
    return api<BlockchainGas>({
      method: 'get',
      path: `/blockchain/gas/${network}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async calculateGas(data: ApiCalculateGas) {
    this.setHeader();
    return api<ApiGasPrice>({
      method: 'post',
      path: `/token/send/simulate`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        
        if (response?.response?.data?.error.includes('insufficient funds')) {
          return {
            data: {
              error: 'Insufficient funds to cover the transaction and gas fees',
              message: 'Insufficient funds to cover the transaction and gas fees.',
            },
          };
        }

        if (response?.response?.data?.error.includes('execution reverted')) {
          return {
            data: {
              error: 'Execution reverted: transfer amount exceeds balance',
              message: 'Execution reverted: transfer amount exceeds balance.',
            },
          };
        }

        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async calculateAllowanceGas(data: ApiSimulateParams) {
    this.setHeader();
    return api<ApiGasPrice>({
      method: 'post',
      path: `/token/allowance/simulate`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async getQueue(data: ApiQueueParams) {
    this.setHeader();
    return api<ApiQueue>({
      method: 'get',
      path: `/token/quote`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async getAllowance(data: ApiGetAllowanceParams) {
    this.setHeader();
    return api<{ allowance: string }>({
      method: 'get',
      path: `/token/allowance`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async setAllowance(data: ApiAllowanceParams) {
    this.setHeader();
    return api<{ transactionHash: string }>({
      method: 'post',
      path: `/token/allowance`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async sendToken(data: ApiSendTokenData) {
    this.setHeader();
    return api<ApiTransactionHash>({
      method: 'post',
      path: `/token/send`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async swapTokens(data: ApiSwapParams) {
    this.setHeader();
    return api<ApiTransactionHash>({
      method: 'post',
      path: `/token/swap`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async getBlockchainTransaction(transactionHash: string, network: ChainId) {
    this.setHeader();
    return api<TransactionDetails>({
      method: 'get',
      path: `/blockchain/transaction/${network}/${transactionHash}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async getApiTransaction(transactionHash: string) {
    this.setHeader();
    return api<ApiBlockchainTransaction>({
      method: 'get',
      path: `/blockchain/transaction/${transactionHash}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async getTokenDetails(address: string) {
    this.setHeader();
    return api<TokenDetails>({
      method: 'get',
      path: `/token/details/${address}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async getPosition(positionId: string) {
    this.setHeader();
    return api<PositionModel>({
      method: 'get',
      path: `/snipe-engine/position/${positionId}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async getSnipePositions() {
    this.setHeader();
    return api<PositionModel[]>({
      method: 'get',
      path: `/snipe-engine/positions`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async getHotTokens() {
    this.setHeader();
    return api<HotToken[]>({
      method: 'get',
      path: `/top`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async openPosition(data: OpenPositionParams) {
    this.setHeader();
    return api<PositionModel>({
      method: 'post',
      path: `/snipe-engine/position`,
      data: {
        ...data,
        walletId: this.profile?.accounts[0].id,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async updatePosition(id: string, data: ISnipeSettings) {
    this.setHeader();
    return api<PositionModel>({
      method: 'post',
      path: `/snipe-engine/position/${id}`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async sellPosition(id: string, amount: number) {
    this.setHeader();
    return apiRaw<undefined>({
      method: 'post',
      path: `/snipe-engine/position/${id}/sell`,
      data: {
        amount,
      },
    })
      .then((response) => {
        return response.status === 204;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async cancelPosition(id: string) {
    this.setHeader();
    return apiRaw<undefined>({
      method: 'post',
      path: `/snipe-engine/position/${id}/cancel`,
    })
      .then((response) => {
        return response.status === 204;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async searchTokens(query: string) {
    this.setHeader();
    const data: any = {};
    data['filter[search_query]'] = query;
    return api<IAsset[]>({
      method: 'get',
      path: `/token/search`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async getReferralData() {
    this.setHeader();
    return api<ApiReferral>({
      method: 'get',
      path: `/referral`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async claimReferral() {
    this.setHeader();
    return api<ApiTransactionHash>({
      method: 'post',
      path: `/referral/claim`,
      data: {
        walletId: this.profile?.accounts[0].id,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async loadConnectWalletInfo() {
    this.setHeader();
    api<ApiConnectWallet>({
      method: 'get',
      path: `/wallet/connect`,
    })
      .then((response) => {
        if (response && response.data?.addresses?.length) {
          this._connectWallet = response.data;
        }
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async loadSnipeSettings() {
    this.setHeader();
    api<ApiSnipeSettings>({
      method: 'get',
      path: `/snipe-engine/position/settings`,
    })
      .then((response) => {
        if (response && response.data?.label) {
          this._snipeSettings = response.data;
        }
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async saveSnipeSettings(data: ISnipeSettings) {
    this.setHeader();
    return api<ApiSnipeSettings>({
      method: 'put',
      path: `/snipe-engine/position/settings`,
      data,
    })
      .then((response) => {
        if (response && response.data?.label) {
          this._snipeSettings = response.data;
        }
        return response;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async loadMnemonic(pin: string) {
    this.setHeader();
    return api<Mnemonic>({
      method: 'post',
      path: `/user/mnemonic`,
      data: { pin },
    })
      .then((response) => {
        if (response && response.data?.mnemonic) {
          this._mnemonic = response.data.mnemonic;
        }
        return response;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async updatePin(data: UpdatePinParams) {
    this.setHeader();
    return api<null>({
      method: 'patch',
      path: `/user/pin`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async verifyPin(pin: string) {
    this.setHeader();
    return apiRaw<null>({
      method: 'post',
      path: `/user/pin/verify`,
      data: { pin },
    })
      .then((response) => {
        return response.status === 200;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response;
      });
  }

  @action
  async getStrategyList() {
    this.setHeader();
    return api<Strategy[]>({
      method: 'get',
      path: `/strategy`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async getStrategyItem(id: string) {
    this.setHeader();
    return api<Strategy>({
      method: 'get',
      path: `/strategy/${id}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async createStrategy(data: StrategyRaw) {
    this.setHeader();
    return api<Strategy>({
      method: 'post',
      path: `/strategy`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async updateStrategy(id: string, data: StrategyMainData) {
    this.setHeader();
    return api<Strategy>({
      method: 'patch',
      path: `/strategy/${id}`,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  async deleteStrategy(id: string) {
    this.setHeader();
    return apiRaw<undefined>({
      method: 'delete',
      path: `/strategy/${id}`,
    })
      .then((response) => {
        return response.status === 204;
      })
      .catch((response) => {
        if (response.response.status === 401) {
          this.logout();
        }
        console.error(response.response.data);
        return response.response;
      });
  }

  @action
  resetStore() {
    localStorage.removeItem('token');
    // clearAuthTokens();
    this._profile = null;
    this._address = null;
    this._isLoggedIn = false;
    this._token = null;
  }

  performLoadActions(accountId: string, blockchain?: ChainId) {
    this._assetsLoaded = true;
    this._transactionsLoaded = true;

    Promise.all([
      apiRaw<Portfolio>({
        method: 'get',
        path: `/portfolio/balances/${accountId}/${blockchain ?? ''}`,
      }),
      apiRaw<AssetsRaw>({
        method: 'get',
        path: `/portfolio/assets/${accountId}/${blockchain ?? ''}`,
      }),
      apiRaw<ApiWalletTransactions>({
        method: 'get',
        path: `/portfolio/transactions/${accountId}/${blockchain ?? ''}`,
      }),
    ])
      .then(([portfolioResponse, assetsResponse, transactionsResponse]) => {
        this._portfolio = portfolioResponse.data.data || null;
        this._assets = assetsResponse.data.data?.positions || [];
        this._transactions = transactionsResponse?.data?.data?.transactions ?? [];

        this._network = blockchain ?? ChainId.ALL;
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          this.logout();
        }
        console.error(error.response?.data);
      })
      .finally(() => {
        this._assetsLoaded = false;
        this._transactionsLoaded = false;
      });
  }
}
