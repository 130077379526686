import React, { useCallback, forwardRef, useImperativeHandle, type PropsWithChildren } from 'react';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

//hooks
import { useNotification } from '@hooks/useNotification';

// img
import { ReactComponent as Copy } from '@assets/icons/copy.svg';

export interface CopyButtonProps extends PropsWithChildren {
  text: string;
  alertMessage?: string;
  className?: string;
  noIcon?: boolean;
  iconPosition?: 'left' | 'right';
  iconColor?: 'tx-muted' | 'tx-white';
  iconSize?: number;
}

export const CopyButton = forwardRef(function CopyButton(
  {
    text,
    alertMessage = 'Copied',
    className,
    noIcon = false,
    iconPosition = 'right',
    iconColor = 'tx-muted',
    iconSize = 16,
    children = text,
  }: CopyButtonProps,
  ref
) {
  const notify = useNotification();

  const handleCopy = useCallback(() => {
    notify(alertMessage + '', { duration: 5000 });
  }, [alertMessage]);

  useImperativeHandle(ref, () => ({
    triggerCopy: () => {
      navigator.clipboard.writeText(text).then(() => {
        handleCopy();
      });
    },
  }));

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <div className={classNames('d-flex align-items-center gap-2 cur-pointer tx-muted', className)}>
        {!noIcon && iconPosition === 'left' && (
          <Copy width={iconSize} height={iconSize} className={classNames(iconColor)} />
        )}
        {children}
        {!noIcon && iconPosition === 'right' && (
          <Copy width={iconSize} height={iconSize} className={classNames(iconColor)} />
        )}
      </div>
    </CopyToClipboard>
  );
});
