import { useEffect, useMemo } from 'react';
import { smallerThan, greaterThan } from '@helpers/bignumber';
import { useNotification } from '@hooks/useNotification';

interface ExchangeRange {
  minAmount: number;
  maxAmount: number | null;
}

export const useAmountValidation = (amount: string, rangeData: ExchangeRange | null) => {
  const notify = useNotification();
  const { minAmount, maxAmount } = rangeData ?? {};

  const isWithinRange = useMemo(() => {
    const isAboveMin = !smallerThan(amount, minAmount ?? '0');
    const isBelowMax = maxAmount == null || !greaterThan(amount, maxAmount);

    return isAboveMin && isBelowMax;
  }, [amount, minAmount, maxAmount]);

  useEffect(() => {
    if (amount === '0') {
      return;
    }

    if (smallerThan(amount, minAmount ?? '0')) {
      notify(`Amount should be greater than ${minAmount}`, {
        duration: 2000,
        type: 'popup-danger',
      });
    }

    if (maxAmount != null && greaterThan(amount, maxAmount)) {
      notify(`Amount should be less than ${maxAmount}`, {
        duration: 2000,
        type: 'popup-danger',
      });
    }
  }, [rangeData, amount]);

  return {
    isWithinRange,
  };
};
