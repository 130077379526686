import { useMutation } from '@tanstack/react-query';
import { apiRaw } from '@helpers/api';

import type { AxiosError } from 'axios';
import type { SwapRequestParams } from '@pages/Bridge/hooks/useFetchParameters';

export interface CreateSwap extends SwapRequestParams {
  fromAccount?: string;
  toAccount?: string;
}

export const useCreateSwap = () => {
  return useMutation<any, AxiosError<any>, CreateSwap>({
    mutationFn: (data) =>
      apiRaw({
        method: 'post',
        path: '/swap/create',
        data,
      }).then((response) => response.data),
  });
};
