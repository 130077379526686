import React, { useEffect, useState } from 'react';
import { Preloader } from '@components/common/Preloader';
import { useStores } from '@hooks/useStores';
import { useResponseHandler } from '@hooks/useResponseHandler';
import { PinInput } from '@components/common/PinInput';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { BadgeIcon } from '@components/common/BadgeIcon';
import { PageRoutes } from '../../constants';
import { useNotification } from '@hooks/useNotification';

const ChangePin = observer(() => {
  const { accountStore } = useStores();
  const navigate = useNavigate();
  const [oldPin, setOldPin] = useState<string>('');
  const [pin, setPin] = useState<string>('');
  const [confirmPin, setConfirmPin] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isSucceeded, setIsSucceeded] = useState<boolean>(false);
  const [oldPinChecked, setOldPinChecked] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const notify = useNotification();

  const checkPin = (pin: string) => {
    setIsProcessing(true);
    accountStore
      .verifyPin(pin)
      .then((response) => {
        setIsProcessing(false);
        if (response === true) {
          setOldPinChecked(true);
        } else if (response.hasOwnProperty('response') && response.response.data.error) {
          handleResponse(response.response);
          setIsInvalid(true);
        } else {
          setError('Invalid PIN code');
          setIsInvalid(true);
        }
      })
      .catch((e) => {
        setIsProcessing(false);
        handleResponse(e.hasOwnProperty('response') ? e.response.response : e);
        setIsInvalid(true);
      });
  };

  const handleSubmit = () => {
    setIsProcessing(true);
    if (!oldPinChecked) {
      return;
    }

    accountStore
      .updatePin({ newPin: pin, currentPin: oldPin })
      .then((response) => {
        setIsProcessing(false);
        setPin('');
        setOldPin('');
        setConfirmPin('');
        setIsInvalid(false);
        setError('');
        if (response.hasOwnProperty('response') && response.response.data.error) {
          handleResponse(response.response);
        } else {
          setIsSucceeded(true);
        }
      })
      .catch((e) => {
        setIsProcessing(false);
        setPin('');
        setOldPin('');
        setConfirmPin('');
        setIsInvalid(true);
        setError('');
        handleResponse(e.hasOwnProperty('response') ? e.response.response : e);
      });
  };

  const handleComplete = (value: string) => {
    if (!oldPinChecked) {
      setOldPin(value);
      checkPin(value);
    } else if (!pin) {
      setPin(value);
    } else {
      if (pin !== value) {
        setIsInvalid(true);
        setPin('');
        setError('PIN codes do not match');
      } else {
        setConfirmPin(value);
        handleSubmit();
      }
    }
  };

  // useEffect(() => {
  //   if (oldPin && !oldPinChecked) {
  //     handleSubmit();
  //   }
  // }, [oldPin, oldPinChecked]);

  useEffect(() => {
    if (isInvalid && error) {
      notify(error, { type: 'popup-danger' });
    }
  }, [isInvalid, error]);

  if (confirmPin && pin === confirmPin) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader
          inline
          iconSize={64}
          className="d-flex flex-column align-items-center"
          textClass="d-block mt-2"
          iconClass="d-block"
          text="Processing PIN change"
        />
      </div>
    );
  }

  if (isSucceeded) {
    return (
      <div className="full-page d-flex flex-column align-items-center justify-content-center tx-center ht-100p">
        <BadgeIcon badgeSize={110} className="tx-64 mb-3">
          👍
        </BadgeIcon>
        <div className="tx-semibold tx-28">Successful</div>
        <div className="tx-muted tx-13 px-4">Congratulations! Your PIN has been successfully changed</div>

        <button className="btn btn-light wd-160 my-4" onClick={() => navigate(PageRoutes.WALLET)}>
          Okay
        </button>
      </div>
    );
  }

  return (
    <div className="tx-center full-page d-flex flex-column">
      <h1 className="tx-28 mt-5">{!oldPinChecked ? 'PIN' : !pin ? 'New PIN' : 'Repeat new PIN'}</h1>

      <div className="tx-muted my-3">
        {!oldPinChecked
          ? 'Please enter your current PIN code'
          : !pin
            ? 'Please enter your new PIN code'
            : 'Please repeat your new PIN code'}
      </div>

      <div className={`flex-1 ${isInvalid ? 'invalid-pin' : ''}`}>
        {!oldPinChecked && (
          <PinInput
            length={4}
            onChange={() => setIsInvalid(false)}
            onComplete={handleComplete}
            isInvalid={isInvalid}
            disabled={isProcessing}
          />
        )}
        {oldPinChecked && !pin && (
          <PinInput length={4} onChange={() => setIsInvalid(false)} onComplete={handleComplete} isInvalid={isInvalid} />
        )}
        {oldPinChecked && !!pin && (
          <PinInput length={4} onChange={() => setIsInvalid(false)} onComplete={handleComplete} isInvalid={isInvalid} />
        )}
      </div>
    </div>
  );
});

export default ChangePin;
