import React from 'react';
import WhaleSignalsPanel from "@pages/WhaleSignals/components/WhaleSignalsPanel";
import blackBunnyIcon from "@assets/images/logo/logo.svg";

const WhaleSignals = () => {
  return (
    <div className="pb-3">
      <WhaleSignalsPanel bot={{tg_id: 'dex_wallet123_bot', name: 'BlackBunny', icon: blackBunnyIcon, disabled: false}} />
    </div>
  );
};

export default WhaleSignals;
