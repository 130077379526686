// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet_walletCard__djctA {
  min-height: 225px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.21);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
  background-color: #000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.wallet_walletCard__djctA .wallet_blurWhiteHeader__6bvW4 {
  position: absolute;
  top: -20px;
  right: calc(50% - 90px);
  width: 80px;
  height: 80px;
  background-color: #fff;
  filter: blur(72px);
}`, "",{"version":3,"sources":["webpack://./src/styles/components/modules/wallet.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mBAAA;EACA,2CAAA;EACA,4CAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,UAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;AACJ","sourcesContent":[".walletCard {\n  min-height: 225px;\n  border-radius: 16px;\n  border: 1px solid rgba(255, 255, 255, 0.21);\n  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);\n  background-color: #000;\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  position: relative;\n\n  .blurWhiteHeader {\n    position: absolute;\n    top: -20px;\n    right: calc(50% - 90px);\n    width: 80px;\n    height: 80px;\n    background-color: #fff;\n    filter: blur(72px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletCard": `wallet_walletCard__djctA`,
	"blurWhiteHeader": `wallet_blurWhiteHeader__6bvW4`
};
export default ___CSS_LOADER_EXPORT___;
