import React from 'react';
import classNames from 'classnames';

//types
import type { IAsset } from '../../../types';

//components
import { TokenLogo } from '@components/common/TokenLogo';
import { FormattedNumber } from '@components/common/FormattedNumber';

interface ListItemProps {
  asset: IAsset;
  hide?: boolean;
  handleNavigate?: () => void;
}

export const AssetItem = ({ asset, hide, handleNavigate }: ListItemProps) => {
  const { assetId, value, change1d, change1dInPercent, assetName, quantity, assetSymbol, assetChain, imageUrl } = asset;

  return (
    <div
      className={classNames({ 'd-none': hide }, 'd-flex justify-content-between align-items-center wd-100p pb-4', {
        'cur-pointer with-hover': Boolean(handleNavigate),
      })}
      onClick={handleNavigate ? handleNavigate : undefined}
    >
      <TokenLogo logo={imageUrl} address={assetId} name={assetName} size={45} chain={assetChain} />

      <div className="d-flex flex-column align-items-start ms-2 me-auto">
        <div className="tx-14 tx-medium mb-1">{assetName}</div>
        <FormattedNumber
          value={quantity}
          postfix={assetSymbol}
          decimals={6}
          subZeros
          className="tx-12 tx-muted word-break"
        />
      </div>

      <div className="ms-auto me-0 d-flex flex-column align-items-end">
        <div className="tx-14 tx-medium">
          <FormattedNumber suffix="$" value={value} subZeros className="word-break" />
        </div>
        <div className="tx-12">
          <FormattedNumber
            value={change1d}
            postfix="$"
            className={classNames('word-break', {
              'tx-success': change1d >= 0,
              'tx-danger': change1d < 0,
            })}
            withSign={change1d !== 0}
            subZeros
          />
          <FormattedNumber
            value={change1dInPercent}
            suffix=" ("
            postfix="%)"
            className={classNames('word-break', {
              'tx-success': change1dInPercent >= 0,
              'tx-danger': change1dInPercent < 0,
            })}
          />
        </div>
      </div>
    </div>
  );
};
