export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  SecondaryLight = 'secondary-light',
  SecondaryDark = 'secondary-dark',
  Transparent = 'transparent',
}

export enum ButtonSize {
  Full = 'full',
  Auto = 'auto',
}

export enum ButtonShape {
  Pill = 'pill',
  Round = 'round',
  Normal = 'normal',
}

export enum IconPosition {
  Left = 'left',
  Right = 'right',
}

export enum ContentAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}
