import { useQuery } from '@tanstack/react-query';
import { apiRaw } from '@helpers/api';

import type { SwapData } from '../../../types';
import type { SwapNetworkParams } from '@stores/reducers/bridgeReducer';
import type { AxiosError } from 'axios';

export interface SwapRequestParams extends SwapNetworkParams {
  fromAmount: number;
}

function parametersQueryOptions(data: SwapRequestParams, isWithinRange: boolean) {
  return {
    queryKey: ['parameters', data.toCurrency, data.fromAmount, isWithinRange],
    queryFn: () =>
      apiRaw<any>({
        method: 'get',
        path: `/swap/parameters`,
        data,
      }).then((re) => re.data.data),

    enabled: !!data.fromAmount && !!data.toNetwork && !!data.toCurrency && isWithinRange,
    retry: 2,
  };
}

export const useFetchParameters = (data: SwapRequestParams, isWithinRange: boolean) => {
  return useQuery<SwapData, AxiosError<any>>(parametersQueryOptions(data, isWithinRange));
};
