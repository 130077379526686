import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { ReactComponent as Shield } from '@assets/icons/shield.svg';

//TODO remake for real data
const slidesData = [
  {
    icon: <Shield />,
    title: 'Wallet Security',
    subtitle: 'Your security is our priority',
  },
  {
    icon: <Shield />,
    title: 'Fast Transactions',
    subtitle: 'Experience quick transfers',
  },
  {
    icon: <Shield />,
    title: 'Global Access',
    subtitle: 'Use your wallet anywhere',
  },
]; // test data

interface SlideData {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
}

interface WalletCarouselProps {
  slides?: SlideData[];
}

export const WalletCarousel = ({ slides = slidesData }: WalletCarouselProps) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  if (!slides?.length) return null;

  return (
    <Carousel controls={false} activeIndex={index} onSelect={handleSelect} className="my-3">
      {slides.map(({ icon, title, subtitle }, idx) => (
        <Carousel.Item key={String(idx)}>
          <div className="d-flex gap-3 align-items-center">
            <div className="tx-success">{icon}</div>
            <div className="d-flex flex-column">
              <span className="tx-14 tx-medium">{title}</span>
              <span className="tx-12 tx-muted">{subtitle}</span>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
