import React from 'react';

//types
import { ButtonType, ContentAlignment } from '@interfaces/button.types';
import { InputShape, InputType } from '@interfaces/input.types';
import { Position } from '@interfaces/imageCard.type';

//hooks
import { useModal } from '@hooks/useModal';

//helpers
import { chainLogo } from '@helpers/chains';

//img
import download from '@assets/icons/download.svg';
import colorKey from '@assets/icons/key-color.svg';
import asterisk from '@assets/icons/asterisk-color.svg';

//components
import { Input } from '@components/common/Input';
import { Button } from '@components/common/Button';
import { DetailModal } from '@components/common/DetailModal';

export const ModalManagerImport = () => {
  const { isOpen: isImport, toggleModal: toggleImport } = useModal();
  const { isOpen: isImportWallet, toggleModal: toggleImportWallet } = useModal();
  const { isOpen: isPrivateKey, toggleModal: togglePrivateKey } = useModal();
  const { isOpen: isSeedPhrase, toggleModal: toggleSeedPhrase } = useModal();

  const handleImportWallet = () => {
    toggleImport();
    toggleImportWallet();
  };
  const handlePrivateKey = () => {
    toggleImportWallet();
    togglePrivateKey();
  };
  const handleSeedPhrase = () => {
    toggleImportWallet();
    toggleSeedPhrase();
  };

  return (
    <>
      <Button type={ButtonType.Secondary} onClick={toggleImport} icon={<img src={download} alt="Download icon" />}>
        Import Wallet
      </Button>

      <DetailModal
        show={isImport}
        onClose={toggleImport}
        alignment={Position.Left}
        title="Import Wallet"
        subtitle="Select the blockchain network for wallet import"
      >
        {/*TODO: map with real data */}
        <div className="d-flex w-100 flex-column gap-3">
          <Button
            type={ButtonType.Secondary}
            onClick={handleImportWallet}
            icon={<img src={chainLogo('binance-smart-chain')} alt="ethereum" className="wd-40 ht-40 me-2" />}
            contentAlignment={ContentAlignment.Left}
            className="px-3"
          >
            BNB
          </Button>
          <Button
            type={ButtonType.Secondary}
            onClick={handleImportWallet}
            icon={<img src={chainLogo('ethereum')} alt="ethereum" className="wd-40 ht-40 me-2" />}
            contentAlignment={ContentAlignment.Left}
            className="px-3"
          >
            ETH
          </Button>
        </div>
      </DetailModal>
      <DetailModal
        show={isImportWallet}
        onClose={toggleImportWallet}
        imageSrc={chainLogo('ethereum')}
        title="Import Solana Wallet"
        subtitle="Are you sure you want to Import a new Solana wallet?"
        secondaryText="Seed Phrase"
        onSecondary={handleSeedPhrase}
        primaryText="Private Key"
        onPrimary={handlePrivateKey}
      />
      <DetailModal
        show={isPrivateKey}
        onClose={togglePrivateKey}
        imageSrc={colorKey}
        alignment={Position.Left}
        title="Paste Private Key"
        subtitle="Enter your wallet's private key"
        secondaryText="Cancel"
        primaryText="Add Wallet"
        onSecondary={togglePrivateKey}
        onPrimary={() => {
          console.log('Add wallet');
        }}
      >
        <Input
          onChange={() => {}}
          placeholder="Enter key"
          shape={InputShape.Default}
          inputType={InputType.SecondaryLight}
          className="mb-4"
        />
      </DetailModal>
      <DetailModal
        show={isSeedPhrase}
        onClose={toggleSeedPhrase}
        imageSrc={asterisk}
        alignment={Position.Left}
        title="Seed Phrase"
        subtitle="Enter your seed phrase"
        secondaryText="Cancel"
        primaryText="Add Wallet"
      />
    </>
  );
};
