import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CopyModal } from '@pages/Account/components/CopyModal';

//types
import { ButtonType, ContentAlignment } from '@interfaces/button.types';
import { Position } from '@interfaces/imageCard.type';

//helpers
import { chainLogo } from '@helpers/chains';

//constants
import { PageRoutes } from '../../../constants';

//hooks
import { useModal } from '@hooks/useModal';

//components
import { Button } from '@components/common/Button';
import { DetailModal } from '@components/common/DetailModal';

export const ModalManagerCreate = () => {
  const navigate = useNavigate();

  const { isOpen: isCreate, toggleModal: toggleCreate } = useModal();
  const { isOpen: isCreateWallet, toggleModal: toggleCreateWallet } = useModal();
  const { isOpen: isCreatedWallet, toggleModal: toggleCreatedWallet } = useModal();

  const handleCreateWallet = () => {
    toggleCreate();
    toggleCreateWallet();
  };

  const handleCreateNewWallet = () => {
    toggleCreateWallet();
    toggleCreatedWallet();
  };
  const handleContinue = () => {
    toggleCreatedWallet();
    navigate(PageRoutes.SUCCESS, { state: { description: 'A new account has been added to your wallet.' } });
  };

  return (
    <>
      <Button onClick={toggleCreate}>Create Wallet</Button>

      <DetailModal
        show={isCreate}
        onClose={toggleCreate}
        alignment={Position.Left}
        title="Create Wallet"
        subtitle="Select the blockchain network for new wallet"
      >
        {/*TODO: map with real data */}
        <div className="d-flex w-100 flex-column gap-3">
          <Button
            type={ButtonType.Secondary}
            onClick={handleCreateWallet}
            icon={<img src={chainLogo('binance-smart-chain')} alt="ethereum" className="wd-40 ht-40 me-2" />}
            contentAlignment={ContentAlignment.Left}
            className="px-3"
          >
            BNB
          </Button>
          <Button
            type={ButtonType.Secondary}
            onClick={handleCreateWallet}
            icon={<img src={chainLogo('ethereum')} alt="ethereum" className="wd-40 ht-40 me-2" />}
            contentAlignment={ContentAlignment.Left}
            className="px-3"
          >
            ETH
          </Button>
        </div>
      </DetailModal>

      <DetailModal
        show={isCreateWallet}
        onClose={toggleCreateWallet}
        imageSrc={chainLogo('ethereum')}
        title="Create Solana Wallet"
        subtitle="Are you sure you want to Create a new Solana wallet?"
        secondaryText="Cancel"
        primaryText="Create"
        onPrimary={handleCreateNewWallet}
      />

      <CopyModal
        show={isCreatedWallet}
        onClose={toggleCreatedWallet}
        title="Your Private Key"
        subtitle="Now you have a private key. If you want to import a wallet, you will be able to do so thanks to this key"
        textToCopy="x4B6C5aA04E2459175390eD97Db66d3B5760b6ab4dfghjkl567mnbgv4567fghjkl"
        secondaryText="Continue"
        onSecondary={handleContinue}
      />
    </>
  );
};
