import { useEffect } from 'react';

//hooks
import { useNotification } from '@hooks/useNotification';

//types
import type { AxiosError } from 'axios';

interface BridgeErrorEffectProps {
  error: AxiosError<any, any> | null;
  callback?: () => void;
}

export const BridgeErrorEffect = ({ error, callback }: BridgeErrorEffectProps) => {
  const notify = useNotification();

  useEffect(() => {
    if (error?.response?.data?.error) {
      notify(error.response.data.error, {
        duration: 2000,
        type: 'popup-danger',
      });

      callback?.();
    }
  }, [error]);

  return null;
};
