import React, {useEffect, useState} from "react";
import BigNumber from "bignumber.js";

type NumericType = string | number | null;

interface NumericInputProps {
  placeholder?: string;
  disabled?: boolean;
  onChange: (_v: NumericType) => void;
  isInvalid?: boolean;
  value: string | null;
  className?: string;
  asString?: boolean;
}

const NumericInput = ({ value, placeholder, disabled, onChange, isInvalid, asString, className = 'form-control appearance-none' }: NumericInputProps) => {
  const [inputValue, setInputValue] = useState<string | null>(value);

  useEffect(() => {
    let resultValue: NumericType = inputValue;
    if (inputValue) {
      if (inputValue.indexOf('.') === inputValue.length - 1) {
        onChange(inputValue.split('.')[0]);
        return;
      }
      const numberValue = new BigNumber((inputValue || '0').replace(',', '.'));
      resultValue = inputValue !== '' ? (asString ? numberValue.toString() : numberValue.toNumber()) : null;
    }
    onChange(resultValue);
  }, [asString, inputValue]);

  return (
    <input
      type="text"
      inputMode="decimal"
      className={`${className} ${isInvalid ? 'is-invalid' : ''}`}
      placeholder={placeholder}
      value={inputValue === undefined || inputValue === null ? '' : inputValue}
      onChange={(e) => {
        const value = e.target.value !== '' ? e.target.value : null;
        setInputValue(value);
      }}
      disabled={disabled}
    />
  );
};

export default NumericInput;
