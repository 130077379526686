export enum InputType {
  SecondaryLight = 'secondary-light',
  Secondary = 'secondary',
}

export enum InputShape {
  Pill = 'pill',
  Default = 'default',
}

export enum IconPosition {
  Left = 'left',
  Right = 'right',
}

export enum InputSize {
  Small = 'sm',
  Large = 'lg',
}
