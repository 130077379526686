import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { HistoryLogo } from '@pages/Wallet/components/HistoryLogo';
import { PageRoutes } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import {
  minMaxValue,
  Strategy,
  strategyFiltersKeys,
  strategyFiltersViewKeys,
  strategyToFlat,
} from '@pages/AutoTrade/constants';
import ether from '@assets/images/chains/ether.svg';

interface StrategySettingsModalProps {
  data: Strategy;
  show: boolean;
  isLoading: boolean;
  setShow: (value: boolean) => void;
  handleDelete: () => void;
  toggleActive: () => void;
}

interface EnabledFilter {
  label: string;
  value: boolean | number | string | null;
  secondValue?: number | string | null;
  unit?: string;
}

const StrategyDetailsModal = observer(
  ({ data, show, setShow, handleDelete, toggleActive, isLoading }: StrategySettingsModalProps) => {
    const navigate = useNavigate();
    const { filters: filtersRaw } = data;
    const filters = strategyToFlat(filtersRaw);

    const availableFilters = useMemo(() => {
      const groups: EnabledFilter[][] = [];
      strategyFiltersViewKeys.forEach(({ items }) => {
        const group: EnabledFilter[] = [];
        items.forEach((item) => {
          const { label, key, secondKey, type, variables, unit } = item;
          const value = (filters as any)[key];
          const value2 = secondKey ? (filters as any)[secondKey] : null;
          if (type === 'toggle' && value) {
            group.push({ label, value: true });
          } else if (type === 'value' && (value !== null || value2 !== null)) {
            group.push({ label, value, secondValue: value2, unit });
          } else if (type === 'variable' && variables) {
            variables.forEach((variable) => {
              const value = (filters as any)[key.replace('%key', variable)];
              const value2 = secondKey ? (filters as any)[secondKey.replace('%key', variable)] : null;
              if (value !== null || value2 !== null) {
                group.push({ label: `${label} ${variable}`, value, secondValue: value2, unit });
              }
            });
          }
        });
        if (group.length) {
          groups.push(group);
        }
      });
      return groups;
    }, [strategyFiltersKeys, filters]);

    const handleClose = () => {
      setShow(false);
    };

    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{data.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row justify-content-start align-items-center wd-100p pb-3">
            <HistoryLogo mainLogo={ether} symbolIn={data.name} size={45} />
            <div className="tx-17 ms-2">{data.name}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2 ms-auto me-0"
              checked={data.active}
              disabled={isLoading}
              onChange={toggleActive}
            />
          </div>

          {!availableFilters.length && (
            <div className="card py-3 bg-semi-transparent-10 my-3">
              <div className="tx-15">No filters enabled</div>
            </div>
          )}

          {availableFilters.map((group, groupIndex) => (
            <div
              className="card py-0 bg-semi-transparent-10 my-3"
              key={`strategy-details-group-${data.name}-${groupIndex}`}
            >
              {group.map(({ label, value, secondValue, unit }, rowIndex) => (
                <div
                  className={`py-3 ${rowIndex && 'border-top border-semi-transparent'}`}
                  key={`strategy-details-item-${data.name}-${groupIndex}-row-${rowIndex}`}
                >
                  <div className="tx-muted tx-13">{label}</div>
                  <div className="tx-15">
                    {value === true && 'Enabled'}
                    {(typeof value === 'number' || value === null) &&
                      (typeof secondValue === 'number' || secondValue === null) &&
                      minMaxValue(value, secondValue, unit)}
                    {(typeof value === 'string' || value === null) &&
                      (typeof secondValue === 'string' || secondValue === null) &&
                      minMaxValue(value, secondValue, unit)}
                  </div>
                </div>
              ))}
            </div>
          ))}

          <div className="d-flex justify-content-between align-items-center gap-2">
            <button
              className="btn py-2 btn-outline-danger tx-danger wd-50p"
              onClick={handleDelete}
              disabled={isLoading}
            >
              Delete strategy
            </button>
            <button
              className="btn py-2 btn-secondary bg-semi-transparent-10 wd-50p"
              onClick={() => navigate(PageRoutes.AUTO_TRADE_EDIT, { state: { strategy: data } })}
              disabled={isLoading}
            >
              Edit strategy
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

export default StrategyDetailsModal;
