import React from 'react';
import QRCode from 'qrcode.react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

//types
import { ButtonType } from '@interfaces/button.types';

//hooks
import { useStores } from '@hooks/useStores';

//constants
import { ChainId, PageRoutes } from '../../constants';

//helpers
import { getEllipsisTxt } from '@helpers/formatters';
import { chainName, getAccountData } from '@helpers/chains';

//img
import { ReactComponent as Share } from '@assets/icons/share.svg';

//styles
import styles from '@styles/components/modules/pink.module.scss';

//components
import { WalletIdentity } from '@components/common/WalletIdentity';
import { Button } from '@components/common/Button';
import { CopyButton } from '@components/common/CopyButton';

export const Receive = observer(() => {
  const navigate = useNavigate();

  const { accountStore } = useStores();
  const { address, network, account } = accountStore;
  const canShare = !!navigator.canShare && !!navigator.share;
  const actualNetwork = network === ChainId.ALL ? ChainId.ETHER : network;

  const handleShare = async () => {
    try {
      await navigator.share({
        title: `My BlackBunny Wallet ${chainName(actualNetwork)} address`,
        text: `${address}`,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { accountName } = getAccountData(account);

  const handleIdentityTitleClick = () => {
    navigate(`${PageRoutes.ACCOUNT}?hideActions=true`);
  };

  return (
    <div className="vh-100 d-flex flex-column py-3 gap-2 position-relative">
      <div className={styles.blurPinkHeader} />
      <WalletIdentity
        address={address}
        isHideAddress
        walletName={accountName}
        onWalletNameClick={handleIdentityTitleClick}
      />

      <div className="bg-transparent d-flex qr-code mt-4 mb-3 wd-100p justify-content-center">
        <QRCode value={address} size={500} bgColor="transparent" fgColor="white" />
      </div>

      <div className="card tx-center rounded-3 flex-row mb-2">
        <div className="d-flex flex-column gap-2 flex-1 text-start">
          <span className="tx-muted tx-12">Your Ethereum address</span>
          <span className="tx-14">{getEllipsisTxt(address, 12)}</span>
        </div>
        <CopyButton
          iconColor="tx-white"
          iconPosition="left"
          text={address}
          className="btn btn-main-light py-0 px-3 rounded-pill d-flex align-items-center justify-content-between gap-2"
        >
          <span className="tx-white">Copy</span>
        </CopyButton>
      </div>

      <div className="tx-muted px-3 lh-6 text-center">
        ETH Tokens received on this network can't be used or traded on other networks like Base, Solana, Binance Smart
        Chain, Polygon, Avalanche, Arbitrum, or TON.
      </div>

      {canShare && (
        <div className="d-flex flex-1 w-100 align-items-end justify-content-center">
          <Button icon={<Share />} onClick={handleShare} type={ButtonType.Secondary}>
            Share
          </Button>
        </div>
      )}
    </div>
  );
});
