import React from 'react';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';

//helpers
import { formatNumber, numberWithCommas } from '@helpers/numbers';

//components
import { Placeholder } from '@components/common/Placeholder';
import { IconWithLoading } from '@components/common/IconWithLoading';

export interface FormattedNumberProps {
  value?: string | number | null;
  decimals?: number;
  floor?: boolean;
  className?: string;
  withSign?: boolean;
  postfix?: string;
  postfixClass?: string;
  suffix?: string;
  suffixClass?: string;
  subZeros?: boolean;
  useSpinner?: boolean;
  useFormat?: boolean;
  noSpace?: boolean;
  shorten?: number;
  onClick?: () => void;
}

export const FormattedNumber = ({
  value: originalValue,
  decimals = 2,
  floor,
  className,
  withSign,
  postfix,
  postfixClass,
  suffix,
  suffixClass,
  subZeros = false,
  useSpinner = false,
  useFormat = false,
  noSpace = false,
  shorten,
  onClick,
}: FormattedNumberProps) => {
  const containerClass = classNames(className);
  const computedSuffixClass = classNames(suffixClass);
  const computedPostfixClass = classNames(postfixClass);

  if (originalValue === undefined || originalValue === null) {
    return (
      <span className={containerClass}>
        {useSpinner ? <IconWithLoading className="" isLoading={true} /> : <Placeholder />}
      </span>
    );
  }

  const value = new BigNumber(originalValue).absoluteValue().toNumber();

  return (
    <span className={containerClass} onClick={onClick}>
      {suffix === '$' && withSign && <>{new BigNumber(originalValue).toNumber() >= 0 ? '+' : '-'}</>}
      {!!suffix && <span className={computedSuffixClass}>{suffix}</span>}

      {suffix !== '$' && withSign && <>{new BigNumber(originalValue).toNumber() >= 0 ? '+' : '-'}</>}
      {useFormat ? formatNumber(value, '', decimals) : numberWithCommas(value, floor, decimals, subZeros, shorten)}
      {!!postfix && (
        <span className={computedPostfixClass}>
          {noSpace || ['$', '%', '(', ')'].some((v) => postfix.includes(v)) ? '' : ' '}
          {postfix}
        </span>
      )}
    </span>
  );
};
