import React, { useEffect, useMemo, useState } from 'react';
import { ChainId, ethRegex } from '../../constants';
import { observer } from 'mobx-react-lite';
import SendTokenSelect from '@pages/Send/SendTokenSelect';
import SendForm from '@pages/Send/SendForm';
import { IAsset, PositionType, UserAccount, WalletStats } from '../../types';
import { chainToken, nativeChainToken, toWei } from '@helpers/chains';
import { useStores } from '@hooks/useStores';
import SendConfirm, { SendConfirmProps } from '@pages/Send/SendConfirm';
import { useLocation, useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { useNotification } from '@hooks/useNotification';
interface SendParams {
  network: ChainId;
}

export enum SendPageType {
  SEND = 'send',
  CONFIRM = 'confirm',
}
interface SendPageProps {
  type?: SendPageType;
}
export interface SendTokenSelectParams extends SendParams {
  setAsset: (asset: IAsset | null) => void;
  isReceiveSelection: boolean;
}
export interface SendFormParams extends SendParams {
  setNetwork: (network: ChainId) => void;
  handleSetAsset: (asset: IAsset) => void;
  calculateGasFee: () => Promise<void>;
  amount: string;
  numericAmount: string;
  setAmount: (amount: string) => void;
  address: string;
  portfolio: WalletStats | null;
  setAddress: (address: string) => void;
  asset: IAsset;
  assetSend?: IAsset | null;
  assets: IAsset[];
  accounts?: UserAccount[] | null;
  assetReceive: IAsset | null;
  nativeToken?: IAsset;
  slippage: string;
  setSlippage: (value: string) => void;
}

export const Send = observer(({ type = SendPageType.SEND }: SendPageProps) => {
  const { accountStore, swapStore } = useStores();
  const { assets, portfolio, accounts, network: currentNetwork } = accountStore;
  const { isReceiveSelection, isTokenSelection } = swapStore;
  const navigate = useNavigate();
  const [network, setNetwork] = useState<ChainId>(currentNetwork || ChainId.ETHER);
  const [assetSend, setAssetSend] = useState<IAsset>();
  const [assetReceive, setAssetReceive] = useState<IAsset | null>(null);
  const [amount, setAmount] = useState('');
  const [address, setAddress] = useState('');
  const [slippage, setSlippage] = useState('');
  const [navAsset, setNavAsset] = useState<IAsset | null>();
  const [navAssetReceive, setNavAssetReceive] = useState<IAsset | null>();
  const location = useLocation();
  const notify = useNotification();

  const [feeInETH, setFeeInETH] = useState<string | null>(null);
  const [accountId, setAccountId] = useState<string | null>(null);

  useEffect(() => {
    if (accountStore.profile && accountStore.profile.accounts.length > 0) {
      const id = accountStore.profile.accounts[0].id;
      setAccountId(id);
    }
  }, [accountStore.profile]);

  const numericAmount = useMemo(() => {
    return amount !== '' ? new BigNumber(amount.replace(',', '.')).toString() : '';
  }, [amount]);

  const networkTokens = useMemo(() => {
    return assets.filter((asset) => asset.assetChain === network);
  }, [assets, network]);

  const nativeToken = useMemo(() => {
    const networkToken = nativeChainToken(network); // ARB
    return networkTokens.find((asset) => asset.assetSymbol.toLowerCase() === networkToken.toLowerCase()); // [assetSymbol: USDT, ETH]
  }, [networkTokens, network]);

  const handleTokenChange = (newAsset: IAsset | null) => {
    if (!newAsset) {
      swapStore.setIsTokenSelection(false);
      swapStore.setIsReceiveSelection(false);
      return;
    }
    if (!isReceiveSelection && newAsset.assetId !== assetSend?.assetId) {
      setAmount('');
    }
    if (isReceiveSelection) {
      setAssetReceive(newAsset);
    } else {
      setAssetSend(newAsset);
    }
    swapStore.setIsTokenSelection(false);
    swapStore.setIsReceiveSelection(false);
  };

  const handleNetworkChange = (newNetwork: ChainId) => {
    if (newNetwork !== network) {
      setNavAsset(null);
      setNavAssetReceive(null);
      setAmount('');
      setAssetReceive(null);
    }
    setNetwork(newNetwork);
  };

  const handleSetAsset = (asset: IAsset) => {
    if (asset) {
      setAssetSend(asset);
    }
  };

  useEffect(() => {
    if (location.pathname.includes('0x')) {
      const addresses = location.pathname.split('/').filter((p) => ethRegex.test(p));
      if (addresses.length) {
        navigate('/swap', {
          state: { from: addresses[0], to: addresses.length > 1 ? addresses[1] : undefined },
          replace: true,
        });
      }
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (navAsset === undefined) {
      if (location.state?.asset) {
        const newNavAsset = JSON.parse(location.state?.asset);
        if (newNavAsset && newNavAsset?.assetChain) {
          setNavAsset(newNavAsset);
          setNetwork(newNavAsset.assetChain);
        }
      } else {
        setNavAsset(null);
      }
    }
  }, [navAsset, location.state, assets]);

  useEffect(() => {
    if (navAssetReceive === undefined) {
      if (location.state?.assetReceive) {
        const newNavAssetReceive = JSON.parse(location.state?.assetReceive);
        if (newNavAssetReceive && newNavAssetReceive?.assetChain) {
          setNavAssetReceive(newNavAssetReceive);
        }
      } else {
        setNavAssetReceive(null);
      }
    }
  }, [navAssetReceive, location.state, assets]);

  useEffect(() => {
    if (!assetSend && navAsset !== undefined) {
      setAssetSend(navAsset || nativeToken || assets[0]);
    }
  }, [assetSend, nativeToken, assets, navAsset]);

  useEffect(() => {
    if (!nativeToken && assets.length) {
      notify(
        `You have no native token on this network to make transactions. You need to send some ${chainToken(network)} tokens to your wallet first.`,
        { type: 'popup-danger' }
      );
      if (assetSend) {
        setNetwork(assetSend.assetChain);
      } else {
        setNetwork(assets[0].assetChain);
        setAssetSend(assets[0]);
      }
    }
  }, [nativeToken, assets, network, assetSend]);

  useEffect(() => {
    if (nativeToken && (assetSend?.assetChain !== nativeToken.assetChain || !assetSend) && navAsset !== undefined) {
      setAssetSend(navAsset || nativeToken);
    }
  }, [nativeToken, assetSend, navAsset]);

  useEffect(() => {
    if (!assetReceive && navAssetReceive !== undefined) {
      setAssetReceive(navAssetReceive);
    }
  }, [assetReceive, navAssetReceive]);

  console.log(JSON.stringify(navAsset), 'navAsset');

  const selectProps: SendTokenSelectParams = {
    network,
    setAsset: handleTokenChange,
    isReceiveSelection,
  };

  const calculateGasFee = async () => {
    console.log(JSON.stringify(assetSend), '____assetSend____');
    const tokenPrice = assetSend?.price || 2354.12; // Mock data change to price from API (asset.priceInUSD)
    console.log(tokenPrice, 'tokenPrice');
    const tokenAmount = new BigNumber(numericAmount).div(tokenPrice); // розраховуємо кількість токенів
    try {
      const gasEstimateResponse = await accountStore.calculateGas({
        walletAddress: accountStore.address,
        tokenAmount: toWei(tokenAmount.toString(), assetSend?.decimals), // change to send only value without toWei
        tokenAddress: assetSend?.contractAddress || '0x0000000000000000000000000000000000000000',
        network,
        receiverAddress: address,
      });
      const gasPriceInWei = new BigNumber(gasEstimateResponse.gasPrice.medium).times(10 ** 9);
      const gasLimit = new BigNumber(gasEstimateResponse.optimalGasLimit);
      const feeInWei = gasLimit.times(gasPriceInWei);
      const feeInETH = feeInWei.div(10 ** 18);
      setFeeInETH(feeInETH.toString());
    } catch (error) {
      notify('An error when calculating the cost of gas.', { type: 'popup-danger' });
    }
  };

  const isEnoughETH = useMemo(() => {
    if (!nativeToken || !feeInETH) return false;
    return new BigNumber(nativeToken.quantity).minus(feeInETH).isGreaterThanOrEqualTo(0);
  }, [nativeToken, feeInETH]);

  console.log(JSON.stringify(assetSend), 'assetSend');

  const formProps: SendFormParams = {
    network,
    setNetwork: handleNetworkChange,
    handleSetAsset,
    asset: assetSend!,
    assets,
    assetSend,
    portfolio,
    calculateGasFee,
    assetReceive,
    amount,
    accounts,
    numericAmount,
    setAmount,
    address,
    setAddress,
    nativeToken,
    slippage,
    setSlippage,
  };

  const confirmProps: SendConfirmProps = {
    address,
    amount: numericAmount,
    assetSend: assetSend!,
    assetReceive,
    slippage,
    network,
  };

  return (
    <>
      {type !== SendPageType.CONFIRM && (
        <>
          {isTokenSelection || isReceiveSelection ? (
            <SendTokenSelect {...selectProps} />
          ) : type === SendPageType.SEND ? (
            <SendForm {...formProps} />
          ) : null}
        </>
      )}
      {type === SendPageType.CONFIRM && <SendConfirm {...confirmProps} />}
    </>
  );
});
