import React from 'react';
import iconBadge from 'assets/images/icon-badge.svg';

interface BadgeIconProps {
  children: React.ReactNode;
  className?: string;
  badgeSize?: number;
}

export const BadgeIcon = ({ children, className = '', badgeSize = 76 }: BadgeIconProps) => {
  return (
    <div className={`badge-icon tx-center ${className}`}>
      <img src={iconBadge} width={badgeSize} alt="badge" />
      <div className="badge-icon__icon">{children}</div>
    </div>
  );
};
