import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

// styles
import styles from '@styles/components/modules/login.module.scss';

interface GreenHeaderGradientWrapperProps extends PropsWithChildren<{ className?: string }> {}

export const GreenHeaderGradientWrapper = ({ children, className }: GreenHeaderGradientWrapperProps) => (
  <div className={classNames('tx-center full-page d-flex flex-column pb-0 position-relative gap-3', className)}>
    <div className={styles.blurGreenHeader} />
    {children}
  </div>
);
