import React from 'react';
import { ImageCard } from '@components/common/ImageCard';
import { useLocation, useNavigate } from 'react-router-dom';

//img
import check from '@assets/icons/green-check.svg';

//styles
import styles from '@styles/components/modules/success.module.scss';

export const SuccessPage = () => {
  const l = useLocation();
  const navigate = useNavigate();

  const description = l.state?.description;

  if (!description) {
    navigate('/');
    return null;
  }

  return (
    <div className="d-flex vh-100 flex-column justify-content-end text-center">
      <div className="position-relative overflow-hidden pt-3">
        <div className={styles.blurGreenHeader} />
        <ImageCard imageSrc={check} title="Success!" subtitle={description}>
          <div className="px-3 pb-4 w-100">
            <a href="/" className="btn btn-main-pink w-100 rounded-pill tx-16 tx-bold" type="button">
              Continue
            </a>
          </div>
        </ImageCard>
      </div>
    </div>
  );
};
