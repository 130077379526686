import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';

import { PreviewModal } from '@pages/Bridge/components/PreviewModal';
import { BridgeWidget } from '@pages/Bridge/components/BridgeWidget';
import { BridgeErrorEffect } from '@pages/Bridge/components/BridgeErrorEffect';

//types
import type { CreateSwap } from '@pages/Bridge/hooks/useCreateSwap';

//helpers
import { getAccountData } from '@helpers/chains';

//constants
import { ChainId, PageRoutes } from '../../constants';

//img
import info from '@assets/icons/info.svg';
import swapIcon from '@assets/icons/swap-modal-icon.svg';

//hooks
import { useModal } from '@hooks/useModal';
import { useFetchCurrencies } from '@pages/Bridge/hooks/useFetchCurrencies';
import { useFetchParameters } from '@pages/Bridge/hooks/useFetchParameters';
import { useBridgeState } from '@pages/Bridge/hooks/useBridgeState';
import { useDebounce } from '@hooks/useDebounce';
import { useFetchExchangeRange } from '@pages/Bridge/hooks/useFetchExchangeRange';
import { useAmountValidation } from '@pages/Bridge/hooks/useAmountValidation';

//components
import { SideGradientWrapper } from '@components/common/SideGradientWrapper';
import { Button } from '@components/common/Button';
import { WalletIdentity } from '@components/common/WalletIdentity';
import { DetailModal } from '@components/common/DetailModal';
import { PageLoader } from '@components/PageLoader';

export const Bridge = observer(() => {
  const navigate = useNavigate();

  const { accountStore } = useStores();
  const { address, account, assets, assetsLoaded, profile, setNetwork } = accountStore;

  const bridgeState = useBridgeState(account, assets);

  const { toNetwork, fromNetwork, fromCurrency, toCurrency, amount } = bridgeState;

  const { data: rangeData, isLoading: isLoadingRangeData } = useFetchExchangeRange({
    fromNetwork,
    toNetwork,
    fromCurrency,
    toCurrency,
  });
  const { isLoading, data } = useFetchCurrencies(fromCurrency, fromNetwork);
  const { isWithinRange } = useAmountValidation(useDebounce(amount, 750), rangeData ?? null);
  const {
    data: swapData,
    error,
    isLoading: isSwapLoading,
    isFetching,
  } = useFetchParameters(
    {
      fromNetwork,
      toNetwork,
      fromCurrency,
      toCurrency,
      fromAmount: Number(useDebounce(amount, 1000)),
    },
    isWithinRange
  );

  const { accountName, accountChain } = getAccountData(account);

  const { isOpen: isOpenInfo, toggleModal: toggleInfo } = useModal();
  const { isOpen: isOpenPreview, toggleModal: togglePreview } = useModal();

  useEffect(() => {
    if (!data) {
      return;
    }

    bridgeState.onToChainList(data, bridgeState.toNetwork);
  }, [data, bridgeState.toNetwork]);

  const toCurrencyList = useMemo(
    () => data?.filter((currency) => currency.network === toNetwork) ?? [],
    [data, toNetwork]
  );
  const toCoinIcon = toCurrencyList.find((currency) => currency.symbol === toCurrency)?.logo;
  const fromCoinAsset = assets.find((asset) => asset.assetSymbol === fromCurrency);

  if (assetsLoaded || isLoading) {
    return <PageLoader text="Loading wallet..." />;
  }

  const handleIdentityTitleClick = () => {
    navigate(`${PageRoutes.ACCOUNT}?hideActions=true`);
  };

  //TODO: temporary solution for 'toAccount' while 2nd account would not be added
  const previewSwapData: CreateSwap = {
    fromNetwork,
    toNetwork,
    fromCurrency,
    toCurrency,
    fromAmount: Number(amount),
    fromAccount: account?.id,
    toAccount: profile?.accounts.find((account) => {
      const secondAccount =
        toNetwork === ChainId.TON ? ChainId.TON : toNetwork === ChainId.SOL ? ChainId.SOL : ChainId.ETHER;

      return account.blockchain === secondAccount;
    })?.id,
  };

  return (
    <SideGradientWrapper className="p-3">
      <div className="flex-1 d-flex flex-column gap-3">
        <WalletIdentity
          address={address}
          isHideAddress
          walletName={accountName}
          onWalletNameClick={handleIdentityTitleClick}
        >
          <span className="cur-pointer p-2">
            <img src={info} alt="Info icon" loading="lazy" onClick={toggleInfo} />
          </span>
        </WalletIdentity>
        <BridgeWidget
          toCurrencyList={toCurrencyList}
          fromAssets={assets}
          swapData={swapData}
          toCoinIcon={toCoinIcon}
          accountChain={accountChain}
          isLoadingRangeData={isLoadingRangeData}
          rangeData={rangeData ?? null}
          setNetwork={setNetwork}
          {...bridgeState}
        />
      </div>
      <Button onClick={togglePreview} disabled={isSwapLoading || !Boolean(swapData) || isFetching}>
        Preview
      </Button>
      <DetailModal
        show={isOpenInfo}
        onClose={toggleInfo}
        title="You can now move crypto between blockchains"
        subtitle="Bridging lets you move crypto to another blockchain network, such as Ethereum to Polygon."
        onPrimary={toggleInfo}
        imageSrc={swapIcon}
        primaryText="Continue"
      />
      <PreviewModal
        show={isOpenPreview}
        onClose={togglePreview}
        previewData={swapData}
        toCoinIcon={toCoinIcon}
        fromCoinAsset={fromCoinAsset}
        previewSwapData={previewSwapData}
      />
      <BridgeErrorEffect error={error} callback={() => bridgeState.onAmountChange('0')} />
    </SideGradientWrapper>
  );
});
