import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { ImageCard } from '@components/common/ImageCard';
import { Button } from '@components/common/Button';
import { SideGradientWrapper } from '@components/common/SideGradientWrapper';
import IdentIcon from '@components/common/IndetIcon';

import confetti from 'canvas-confetti';
import congratulation from '@assets/images/congratulation.svg';

// TODO: EVM Account 1 was hardcoded change it to real name

export const WalletCreated = observer(() => {
  const navigate = useNavigate();
  const { accountStore } = useStores();
  const { address } = accountStore;

  useEffect(() => {
    confetti();
  }, []);

  return (
    <SideGradientWrapper className="tx-center pb-3">
      <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center w-100">
        <ImageCard
          imageSrc={congratulation}
          title="Wallet Created"
          subtitle="Congratulations, your wallet has been created and is ready to use!"
        >
          <div className="w-100">
            <div className="d-flex gap-3 justify-content-center align-items-center bg-grey-350 rounded-pill p-2 mb-3">
              <IdentIcon string={address} className="identicon rounded-circle" size={32} />
              <span className="tx-center tx-medium tx-14">EVM Account 1</span>
            </div>
            <p className="tx-muted tx-small">You can create multiple wallets in your profile</p>
          </div>
        </ImageCard>
      </div>
      <Button onClick={() => navigate(PageRoutes.WALLET)}>Continue</Button>
    </SideGradientWrapper>
  );
});
