import React, {useEffect, useMemo, useState} from "react";
import Canvas from "@components/common/Canvas";
import {format, formatDistanceToNow} from "date-fns";
import {ENV} from "../../constants";
import QRCode from "qrcode.react";
import logo from "@assets/images/logo/logo-dark.svg";
import profitBg from "./profitBg.png";
import {formatNumber} from "@helpers/numbers";

// interface Sources {
//   [key: string]: any;
// }

async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}

// function loadImages(sources: any, callback: (images: HTMLImageElement[]) => void) {
//   var images: Sources = {};
//   var loadedImages = 0;
//   var numImages = 0;
//   // get num of sources
//   for(var src in sources) {
//     numImages++;
//   }
//   for(var src in sources) {
//     images[src] = new Image();
//     images[src].onload = () => {
//       if(++loadedImages >= numImages) {
//         callback(images as HTMLImageElement[]);
//       }
//     };
//     images[src].src = sources[src];
//     images[src].crossOrigin = "";
//   }
// }

interface ShareProfitProps {
  data: {
    profit: string;
    duration: string;
    token: string;
    isPositive: boolean;
    refId: string;
  } | null,
  onShare: () => void;
}

const ShareProfit = ({data, onShare}: ShareProfitProps) => {
  const {profit, duration, token, isPositive, refId} = data || {
    profit: '',
    duration: '',
    token: '',
    isPositive: true,
    refId: '',
  };

  const [timeAgo, setTimeAgo] = useState<string>('');
  const [today, setToday] = useState<string>('');

  const formattedProfit = useMemo(() => {
    return formatNumber(profit, '%', 2)
  }, [profit]);

  useEffect(() => {
    if (data) {
      setTimeAgo(formatDistanceToNow(new Date(duration), {includeSeconds: false, addSuffix: false}));
    } else {
      setTimeAgo('');
    }
    setToday(format(new Date(), 'dd MMM yyyy'));
  }, [data, duration]);

  const referralLink = useMemo(() => {
    return `${ENV.BOT_URL}?start=ref-${refId}`;
  }, [refId]);

  const handleDraw = (context: CanvasRenderingContext2D, _frameCount: number) => {
    //Our first draw
    context.fillStyle = '#627EEA';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.drawImage(document.getElementById('bg') as HTMLImageElement, 0, 0, 750, 520);
    // const v = Canvg.fromString(context, testSvg);
    // v.start();

    // Token name
    context.font = 'bold 28px "SF Pro Display"';
    context.fillStyle = '#FFF';
    context.fillText(token, 60, 150);

    // Profit %
    context.font = 'bold 34px "SF Pro Display"';
    context.fillStyle = isPositive ? '#32D74B' : '#FF453A';
    context.fillText(formattedProfit, 60, 190);

    // Profit label
    context.font = 'bold 28px "SF Pro Display"';
    context.fillStyle = '#FFF';
    context.fillText('Profit Rate', 60, 230);

    // Profit label
    context.font = 'bold 28px "SF Pro Display"';
    context.fillStyle = '#FFF';
    context.fillText('In ' + timeAgo, 60, 260);

    // Date
    context.font = '13px "SF Pro Display"';
    context.fillStyle = '#626262';
    context.fillText('Date: ' + today, 60, 330);

    // Referral link
    context.font = '13px "SF Pro Display"';
    context.fillStyle = '#626262';
    context.fillText(referralLink.replace('https://', ''), 132, 445);


    // var sources = {
    //   tokenLogo,
    // };

    // context.drawImage(document.getElementById('tokenLogo') as HTMLImageElement, 450, 75, 32, 32);
    context.drawImage(document.getElementById('qr-code') as HTMLCanvasElement, 64, 405, 51, 51);

    // if (!logoDrawn) {
    //   loadImages(sources, function(images) {
    //     // @ts-ignore
    //     // context.drawImage(images['testSvgFile'], 0, 0, 750, 520);
    //     // @ts-ignore
    //     // context.drawImage(images['tokenLogo'], 450, 75, 32, 32);
    //     setLogoDrawn(true);
    //   });
    // }
  };

  const handleClick = () => {
    const dataUrl = (document.getElementById('share-canvas') as HTMLCanvasElement)?.toDataURL('image/png');
    dataUrlToFile(dataUrl, token + '.png').then(async (file) => {
      const shareData = {
        url: referralLink,
        // text: "Learn web development on MDN!",
        // url: "https://developer.mozilla.org",
        files: [file],
      };
      try {
        await navigator.share(shareData);
        onShare();
      } catch (err) {
        const link = document.createElement('a');
        link.download = 'filename.png';
        link.href = dataUrl;
        link.click();
        onShare();
      }
    });
  };

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        handleClick();
      }, 100);
    }
  }, [data]);

  return (
    <div>
      <img src={profitBg} id="bg" alt="ID" className="d-none"/>
      <div className="bg-white rounded-10 d-flex p-4 qr-code my-4 wd-70p mx-auto pos-relative d-none">
        <QRCode
          value={referralLink}
          size={200}
          bgColor="#FFFFFF"
          fgColor="#252525"
          imageSettings={{src: logo, excavate: true, width: 49, height: 37}}
          id="qr-code"
        />
        <img src={logo} alt="BlackBunny Wallet" className="qr-code-logo" id="qr-logo"/>
      </div>
      <Canvas draw={handleDraw} width={750} height={520} onClick={handleClick} id="share-canvas" className="d-none"/>
    </div>
  )
};


export default ShareProfit;
