// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pink_blurPinkHeader__4I2Uy {
  position: absolute;
  top: -100px;
  left: calc(50% - 116px);
  width: 232px;
  height: 232px;
  background-color: rgb(239, 42, 123);
  filter: blur(117px);
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/modules/pink.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,mCAAA;EACA,mBAAA;EACA,WAAA;AACF","sourcesContent":[".blurPinkHeader {\n  position: absolute;\n  top: -100px;\n  left: calc(50% - 116px);\n  width: 232px;\n  height: 232px;\n  background-color: rgba(239, 42, 123, 1);\n  filter: blur(117px);\n  z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blurPinkHeader": `pink_blurPinkHeader__4I2Uy`
};
export default ___CSS_LOADER_EXPORT___;
