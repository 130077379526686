import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

//types
import { Position } from '@interfaces/imageCard.type';

export interface ImageCardProps extends PropsWithChildren {
  imageSrc?: string;
  title?: string;
  subtitle?: string;
  alignment?: Position;
  className?: string;
}

export const ImageCard = ({
  imageSrc,
  title,
  subtitle,
  alignment = Position.Center,
  children,
  className,
}: ImageCardProps) => {
  const isTextAlignedLeft = alignment === Position.Left && (!title || !subtitle);

  return (
    <div className={classNames('w-100 d-flex flex-column align-items-center', className)}>
      <div
        className={classNames('d-flex w-100 gap-3', {
          'flex-column align-items-center': alignment === Position.Center,
          'flex-row align-items-center': isTextAlignedLeft,
          'flex-row align-items-start': alignment === Position.Left && title && subtitle,
        })}
      >
        {imageSrc && (
          <div className="imageCircle flex-shrink-0">
            <img src={imageSrc} alt="icon" className="wd-24 ht-27" loading="lazy" />
          </div>
        )}
        {(title || subtitle) && (
          <div
            className={classNames('w-100', {
              'text-center': alignment === Position.Center,
              'text-start': alignment === Position.Left,
            })}
          >
            {title && <h1 className={classNames('tx-20 tx-bold', { 'mb-0': !subtitle })}>{title}</h1>}
            {subtitle && <div className="tx-muted mb-4">{subtitle}</div>}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
