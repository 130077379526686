import React from 'react';
import classNames from 'classnames';
import toLower from 'lodash/toLower';
import capitalize from 'lodash/capitalize';

//img
import arrowUp from '@assets/icons/arrow-t-up.svg';

//constants
import { ChainId } from '../../../constants';

import type { ApiWalletTransactionsData } from '@interfaces/transactions.types';
//type
import { OperationType } from '@interfaces/transactions.types';

//components
import { getEllipsisTxt } from '@helpers/formatters';
import { FormattedNumber } from '@components/common/FormattedNumber';
import { CopyButton } from '@components/common/CopyButton';

interface HistoryProps {
  network: ChainId;
  transactions: Array<ApiWalletTransactionsData>;
}

export const History = ({ network, transactions }: HistoryProps) => {
  if (!transactions.length) return null;
  return (
    <div className="d-flex align-items-center gap-3 flex-column w-100">
      {transactions.map(({ operation, value, transactionHash, fee, meta }, i) => {
        const operationLower = toLower(operation);

        return (
          <div className="d-flex w-100 gap-3" key={String(i)}>
            <div className="card rounded-pill w-auto flex-shrink-1 p-2 bd-grey-light">
              <img
                src={arrowUp}
                alt="Coin icon"
                loading="lazy"
                className={classNames('p-1', { 'rotate-180': operationLower === OperationType.Receive })}
              />
            </div>
            <div className="flex-1 d-flex flex-column gap-2">
              <span className="tx-14 tx-medium">{capitalize(operationLower)}</span>
              <CopyButton text={transactionHash} alertMessage="Transaction hash was copied" iconSize={12}>
                <div className="tx-12">{`Id: ${getEllipsisTxt(transactionHash)}`}</div>
              </CopyButton>
            </div>
            <div className="d-flex flex-column gap-2 text-end">
              <span className="d-flex align-items-center gap-2">
                <img src={meta.tokenImgUrl} alt="Token icon" loading="lazy" className="wd-16 ht-16 rounded-circle" />
                <FormattedNumber value={value} postfix={meta.token} />
              </span>
              <span className="tx-muted tx-12">Fee: {fee ?? 0}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
