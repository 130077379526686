import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

import infoIcon from "@assets/whale-signals/info.svg";

const SignalAnomaly = ({signal}) => {
  const tooltip = (
    <Tooltip id="signal-anomaly-tooltip">
      <div>
        🐳 Big players
      </div>
      <div>
        ⭐️ Influencer wallets
      </div>
      <div>
        🍀 New holders
      </div>
      <div>Quick growth in a young token can indicate insider trading.</div>
    </Tooltip>
  );

  return (
    <>
      <div className="d-block d-lg-none mt-3">
        <div className="tx-17 tx-semibold">Anomaly&nbsp;
          <OverlayTrigger overlay={tooltip}>
             <img src={infoIcon} alt={'Info'} width='16'/>
          </OverlayTrigger>
        </div>
        <div className="row g-2 g-sm-2">
          <div className="col-4 col-sm-12">
            <div className="card bg-semi-transparent-10 px-2 py-2 flex-row align-content-center justify-content-center">
              <div>
                🐳 {signal.whales}
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-12">
            <div className="card bg-semi-transparent-10 px-2 py-2 flex-row align-content-center justify-content-center">
              <div>
                ⭐️ {signal.kyc}
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-12">
            <div className="card bg-semi-transparent-10 px-2 py-2 flex-row align-content-center justify-content-center">
              <div>
                🍀 {signal.freshWallets}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignalAnomaly;

