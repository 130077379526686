import React, { type PropsWithChildren, useEffect } from 'react';
import classNames from 'classnames';
import { useAnimate } from 'framer-motion';
import { randomNumber } from '@helpers/numbers';

//types
import { ButtonShape } from '@interfaces/button.types';

export interface TabCardItem {
  value: string | number;
  label?: string;
  imgSrc?: string;
}

interface TabsCardProps extends PropsWithChildren {
  list: TabCardItem[];
  active?: string | number;
  onClick?: (value: any) => void;
  className?: string;
  tabClassName?: string;
  tabShape?: ButtonShape;
}

export function TabsCard({
  list,
  active,
  onClick,
  className = '',
  tabClassName = 'py-1',
  children,
  tabShape = ButtonShape.Normal,
}: TabsCardProps) {
  const number = randomNumber();
  const [scope, animate] = useAnimate();
  const activeIndex = list.findIndex(({ value }) => value === active);
  const width = 100 / list.length;

  useEffect(() => {
    animate(`#tab-indicator-${number}`, { left: `calc(${width * activeIndex}% + 4px)`, zIndex: 1 });
    list.forEach(({ value }) => {
      const isActive = value === active;
      if (document.querySelector(`.tab-element.tab-${value}`)) {
        animate(
          `.tab-element.tab-${value}`,
          { opacity: isActive ? 1 : 0, display: isActive ? 'block' : 'none' },
          { duration: 0.3, delay: isActive ? 0.2 : 0 }
        );
      }
    });
  }, [list, activeIndex, animate]);

  return (
    <div ref={scope}>
      <div
        className={classNames(
          'card flex-row p-1 justify-content-between mg-b-20',
          {
            'bg-semi-transparent-10': !className,
          },
          className
        )}
      >
        <div
          className={classNames('bg-semi-transparent position-absolute', {
            'rounded-1': tabShape === ButtonShape.Normal,
            'rounded-pill': tabShape === ButtonShape.Pill,
          })}
          style={{ width: `calc(${width}% - 8px)`, height: 'calc(100% - 6px)', top: '3px' }}
          id={`tab-indicator-${number}`}
        />
        {list.map(({ value, label, imgSrc }, index) => (
          <div
            className={classNames(
              'tx-14 tx-medium rounded-2 tx-center cur-pointer wd-100p d-flex gap-2 align-items-center justify-content-center',
              { 'tx-muted': value !== active },
              tabClassName
            )}
            onClick={() => onClick && onClick(value)}
            key={`tab-card-${value}-${index}`}
            style={{ zIndex: 2 }}
          >
            {imgSrc && <img src={imgSrc} alt="Tan icon" loading="lazy" />}
            {label || value}
          </div>
        ))}
      </div>
      <div className="tab-content position-relative">{children}</div>
    </div>
  );
}
