import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Check } from '@assets/icons/check.svg';

//img
import arrow from '@assets/icons/arrow-down.svg';

//constants
import { ChainId } from '../constants';

//helpers
import { chainLogo, chainName } from '@helpers/chains';

//hooks
import { useModal } from '@hooks/useModal';

interface NetworkSelectionProps extends PropsWithChildren {
  network: ChainId;
  onChange: (network: ChainId) => void;
  hasHeader?: boolean;
  isHideCoinIcon?: boolean;
  backGround?: string;
  buttonClassName?: string;
  excludedChains?: ChainId[];
}

export const NetworkSelection = ({
  network,
  onChange,
  children,
  isHideCoinIcon = false,
  buttonClassName,
  excludedChains = [],
  hasHeader = true,
}: NetworkSelectionProps) => {
  const { toggleModal, isOpen, closeModal, openModal } = useModal();

  const handleChange = (value: ChainId) => {
    closeModal();
    if (onChange) {
      onChange(value);
    }
  };

  const availableChains = Object.values(ChainId).filter((value) => !excludedChains.includes(value));

  return (
    <>
      <button className={classNames('d-flex btn btn-transparent p-0 tx-left', buttonClassName)} onClick={openModal}>
        {children ? (
          children
        ) : (
          <div className="d-flex align-items-center tx-12 bg-grey-350 rounded-pill p-2 gap-2 w-100">
            <div className="d-flex align-items-center ps-1 gap-2 flex-1">
              {!isHideCoinIcon && <img src={chainLogo(network)} alt={network} className="wd-24 ht-24" />}
              <span className="tx-14 tx-normal">{chainName(network)}</span>
            </div>
            <img src={arrow} alt="Choose network" className="pe-1" />
          </div>
        )}
      </button>

      <Modal show={isOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <div className="flex flex-column">
            {hasHeader && (
              <>
                <Modal.Title className="tx-20 tx-bold">Networks</Modal.Title>
                <span className="tx-12 tx-muted">Select a blockchain network for this wallet.</span>
              </>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          {availableChains.map((value) => {
            const isCurrentNetwork = network === value;

            return (
              <button
                className={classNames(
                  'btn btn-secondary d-flex align-items-center p-12 wd-100p mb-3 tx-16 tx-medium rounded-pill bg-gray-900 gap-3',
                  { 'border-d-pink bg-transparent': isCurrentNetwork, 'border-transparent': !isCurrentNetwork }
                )}
                onClick={() => handleChange(value)}
                key={`network-selection-item-${value}`}
              >
                <div className="d-flex align-items-center justify-content-center rounded-circle">
                  <img src={chainLogo(value)} alt={value} width={44} />
                </div>
                <span className="flex-1 text-start">{chainName(value)}</span>
                {isCurrentNetwork && (
                  <div className="tx-pink px-3">
                    <Check width={24} height={24} />
                  </div>
                )}
              </button>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};
