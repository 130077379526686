import React from 'react';
import { Preloader } from '@components/common/Preloader';

interface PageLoaderProps {
  text?: string;
}

export const PageLoader = ({ text }: PageLoaderProps) => {
  return (
    <div className="py-3">
      <Preloader className="d-flex flex-column" iconSize={65} textClass="mt-3" text={text} inline />
    </div>
  );
};
