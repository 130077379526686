import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { greaterThan } from '@helpers/bignumber';
import { useStores } from '@hooks/useStores';
import { useCloudStorage, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { BottomNav } from '@components/BottomNav/BottomNav';
import {
  autoTradeRoutes,
  PageRoutes,
  portfolioRoutes,
  profileRoutes,
  researchRoutes,
  tokenTradeRoutes,
  sendRoutes,
} from '../constants';
import { Header } from 'layouts/common/Header';

interface MainLayoutProps {
  children: React.ReactNode;
}

const fullPageRoutes = [PageRoutes.SUCCESS, PageRoutes.BRIDGE, PageRoutes.HOME];

const bottomNavRoutesExact = [
  ...autoTradeRoutes,
  ...portfolioRoutes,
  ...researchRoutes,
  ...profileRoutes,
  ...tokenTradeRoutes,
];

const headerNavRoutesExact = [...sendRoutes];

export const MainLayout = observer(({ children }: MainLayoutProps) => {
  const location = useLocation();
  const { settingsStore } = useStores();
  const { initialized, triggerUpdate, state } = settingsStore;
  const { setItem, getItems, getKeys } = useCloudStorage();
  const lastTriggerUpdateRef = useRef(0);
  const WebApp = useWebApp();

  useEffect(() => {
    if (WebApp && initialized && lastTriggerUpdateRef.current < triggerUpdate) {
      lastTriggerUpdateRef.current = triggerUpdate;
      if (WebApp.hasOwnProperty('CloudStorage') && greaterThan(WebApp.version, '6.0')) {
        state.forEach(({ value, key }, index) => {
          setItem(key, JSON.stringify(value)).then(() => {
            if (index === state.length - 1) {
              settingsStore.setSaved();
            }
          });
        });
      } else {
        state.forEach(({ value, key }) => {
          localStorage.setItem(key, JSON.stringify(value));
        });
        settingsStore.setSaved();
      }
    }
  }, [setItem, WebApp, state, initialized, triggerUpdate, lastTriggerUpdateRef, settingsStore]);

  useEffect(() => {
    if (WebApp && !initialized) {
      if (WebApp.hasOwnProperty('CloudStorage') && greaterThan(WebApp.version, '6.0')) {
        getKeys().then((keys) => {
          getItems(keys).then((items) => {
            settingsStore.restoreSettings(keys, Object.values(items));
          });
        });
      } else {
        const keys = Object.keys(localStorage);
        const values: string[] = [];
        keys.forEach((key) => {
          values.push(localStorage.getItem(key) || '');
        });
        settingsStore.restoreSettings(keys, values);
      }
    }
  }, [WebApp, initialized, getKeys, getItems, settingsStore]);

  const className = classNames('w-100', { 'px-3': !fullPageRoutes.some((route) => location.pathname === route) });

  const withBottomNav = useMemo(() => {
    return bottomNavRoutesExact.some((route) => location.pathname === route);
  }, [location.pathname]);

  const withHeader= useMemo(() => {
    return headerNavRoutesExact.some((route) => location.pathname === route);
  }, [location.pathname]);

  return (
    <>
      {/* {withHeader && <Header />} */}
      <div className={className}>
        {children}
        {withBottomNav && <BottomNav />}
      </div>
    </>
  );
});
