import { observer } from 'mobx-react-lite';
import Toggle from 'react-toggle';
import SnipeRow from '@pages/Profile/SnipeRow';
import SnipeColumn from '@pages/Profile/SnipeColumn';
import { numberRegex } from '../../constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISnipeSettings } from '../../types';
import { useStores } from '@hooks/useStores';
import { Preloader } from '@components/common/Preloader';
import { IconWithLoading } from '@components/common/IconWithLoading';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useNotification } from '@hooks/useNotification';
import { ApiError } from '@helpers/api';
import SettingsInput from '@components/common/SettingsInput';

interface SnipeSettingsProps {
  isBot?: boolean;
}

const SnipeSettings = observer(({ isBot }: SnipeSettingsProps) => {
  const { accountStore } = useStores();
  const { snipeSettings } = accountStore;
  const navigate = useNavigate();
  const [settings, setSettings] = useState<ISnipeSettings>();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const WebApp = useWebApp();
  const notify = useNotification();

  const handleClose = () => {
    if (isBot && WebApp) {
      WebApp.close();
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (snipeSettings && !settings) {
      const { createdAt, updatedAt, id, userId, label, ...mainData } = snipeSettings;
      setSettings(JSON.parse(JSON.stringify(mainData)));
    }
  }, [snipeSettings, settings]);

  useEffect(() => {
    if (settings && settings.trailingStopLossEnabled && !settings.stopLossEnabled) {
      setSettings({ ...settings, stopLossEnabled: true });
    }
  }, [settings]);

  const isInvalid = useCallback(
    (key: string, isReq = false) => {
      if (!settings) {
        return false;
      }
      // @ts-ignore
      const value = settings[key];
      if (isReq && (value === undefined || value === null)) {
        return true;
      }
      if (!isReq && (value === undefined || value === null)) {
        return false;
      }
      if (key.toLowerCase().includes('delta') && value && value < 0) {
        return true;
      }
      return isNaN(value) || !numberRegex.test(value.toString()) || !!(error && error.error?.includes(key));
    },
    [settings, error]
  );

  const handleOnChange = useCallback(
    (key: string, value: string | number | null) => {
      if (error && error.error?.includes(key)) {
        setError(null);
      }
    },
    [error]
  );

  if (!settings) {
    return (
      <div className="pb-3" id="snipe-settings">
        <div className="tx-28 tx-semibold my-3">Trade Settings</div>

        <Preloader inline />
      </div>
    );
  }

  const handleSaving = () => {
    const checks = [
      { check: isInvalid('stopLossPercent', settings.stopLossEnabled), label: 'Stop Loss' },
      { check: isInvalid('takeProfitPercent', settings.takeProfitEnabled), label: 'Take Profit' },
      { check: isInvalid('stopLossSellPercent', settings.stopLossEnabled), label: 'Stop Loss Amount' },
      { check: isInvalid('takeProfitSellPercent', settings.takeProfitEnabled), label: 'Take Profit Amount' },
      { check: isInvalid('sellSlippage', true), label: 'Sell Slippage' },
      { check: isInvalid('buySlippage', true), label: 'Buy Slippage' },
      { check: isInvalid('sellGasDelta'), label: 'Sell Priority Fee' },
      { check: isInvalid('buyGasDelta'), label: 'Buy Priority Fee' },
      { check: isInvalid('approveGasDelta'), label: 'Approve Priority Fee' },
    ];

    if (checks.some(({ check }) => check)) {
      notify(checks.find((c) => c.check)?.label + ' is invalid or empty', { type: 'popup-danger' });
      return;
    }

    const data = JSON.parse(JSON.stringify(settings));

    if (!settings.stopLossEnabled && !settings.stopLossPercent) {
      data.stopLossPercent = snipeSettings?.stopLossPercent || 0;
    }
    if (!settings.takeProfitEnabled && !settings.takeProfitPercent) {
      data.takeProfitPercent = snipeSettings?.takeProfitPercent || 0;
    }
    if (settings.stopLossPercent && settings.stopLossPercent > 0) {
      data.stopLossPercent = settings.stopLossPercent * -1;
    }

    setIsSaving(true);
    accountStore
      .saveSnipeSettings(data)
      .then((response) => {
        setIsSaving(false);
        if (response.data?.label) {
          notify('Trade settings saved successfully');
        } else {
          notify(response.response.data.error, { type: 'popup-danger' });
          setError(response.response.data);
        }
      })
      .catch((e) => {
        console.error(e);
        setIsSaving(false);
        notify('Error saving trade settings', { type: 'popup-danger' });
      });
  };

  return (
    <div className="pb-3" id="snipe-settings">
      <div className="tx-28 tx-semibold my-3">Trade Settings</div>

      <div>General settings</div>
      <div className="card mt-1">
        <SnipeRow>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="maxGasPrice"
            isInvalidHandler={isInvalid}
            placeholder="GWEI"
            onChange={handleOnChange}
            tooltip="The maximum gas price you're willing to pay for a transaction"
          >
            Max Gas Price
          </SettingsInput>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="approveGasDelta"
            isInvalidHandler={isInvalid}
            placeholder="GWEI"
            onChange={handleOnChange}
            tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
          >
            Approve Priority Fee
          </SettingsInput>
        </SnipeRow>

        <SnipeRow className="mt-3">
          <SnipeColumn>
            <div>MEV Guard</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.mevGuardEnabled}
              onChange={(e) => setSettings({ ...settings, mevGuardEnabled: e.target.checked })}
            />
          </SnipeColumn>
          <SnipeColumn>
            <div>Fail Guard</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.failGuard}
              onChange={(e) => setSettings({ ...settings, failGuard: e.target.checked })}
            />
          </SnipeColumn>
        </SnipeRow>

        {/*{!isBot && <SnipeRow className="mt-3">*/}
        {/*  <SnipeColumn>*/}
        {/*    <div className="tx-gray-300">Anti Rug</div>*/}
        {/*    <Toggle*/}
        {/*      icons={false}*/}
        {/*      className="styled-toggle my-2"*/}
        {/*      disabled*/}
        {/*    />*/}
        {/*  </SnipeColumn>*/}
        {/*  <SnipeColumn>*/}
        {/*    <div>MEV Guard</div>*/}
        {/*    <Toggle*/}
        {/*      icons={false}*/}
        {/*      className="styled-toggle my-2"*/}
        {/*      checked={settings.mevGuardEnabled}*/}
        {/*      onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}*/}
        {/*    />*/}
        {/*  </SnipeColumn>*/}
        {/*</SnipeRow>}*/}

        <SnipeRow className="mt-3">
          <SnipeColumn className="tx-12 tx-semibold">
            <div>Auto Approve</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.autoApprove}
              onChange={(e) => setSettings({ ...settings, autoApprove: e.target.checked })}
            />
          </SnipeColumn>
          <SnipeColumn>
            <div>Trailing Loss</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.trailingStopLossEnabled}
              onChange={(e) => setSettings({ ...settings, trailingStopLossEnabled: e.target.checked })}
            />
          </SnipeColumn>
        </SnipeRow>
      </div>

      <div className="mt-4">Take Profit & Stop Loss</div>
      <div className="card mt-1 mb-2">
        <SnipeRow>
          <SnipeColumn>
            <div>Take Profit</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.takeProfitEnabled}
              onChange={(e) => setSettings({ ...settings, takeProfitEnabled: e.target.checked })}
            />
          </SnipeColumn>
          <SnipeColumn>
            <div>Stop Loss</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.stopLossEnabled}
              onChange={(e) =>
                setSettings({ ...settings, stopLossEnabled: e.target.checked, trailingStopLossEnabled: false })
              }
            />
          </SnipeColumn>
        </SnipeRow>

        {settings.takeProfitEnabled && (
          <SnipeRow className="mt-2">
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="takeProfitPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of profit at which a sell will be executed"
              isRequired
            >
              Take Profit
            </SettingsInput>

            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="takeProfitSellPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of the total token amount to be sold when the specified profit level is reached"
            >
              Take Profit sell %
            </SettingsInput>
          </SnipeRow>
        )}

        {settings.stopLossEnabled && (
          <SnipeRow className="mt-2">
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="stopLossPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of loss at which a sell will be executed"
              isRequired
            >
              Stop Loss
            </SettingsInput>

            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="stopLossSellPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of the total token amount to be sold when the specified loss level is reached"
            >
              Stop Loss sell %
            </SettingsInput>
          </SnipeRow>
        )}
      </div>

      <div className="mt-4">Buy settings</div>
      <div className="card mt-1">
        <SnipeRow>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="buySlippage"
            isInvalidHandler={isInvalid}
            placeholder="1%"
            unit="%"
            onChange={handleOnChange}
            tooltip="The percentage of price change at which your buy will still be executed"
            isRequired
          >
            Slippage
          </SettingsInput>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="buyGasDelta"
            isInvalidHandler={isInvalid}
            placeholder="GWEI"
            onChange={handleOnChange}
            tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
          >
            Priority Fee
          </SettingsInput>
        </SnipeRow>
      </div>

      <div className="mt-4">Sell settings</div>
      <div className="card mt-1">
        <SnipeRow>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="sellSlippage"
            isInvalidHandler={isInvalid}
            placeholder="1%"
            unit="%"
            onChange={handleOnChange}
            tooltip="The percentage of price change at which your sale will still be executed"
            isRequired
          >
            Slippage
          </SettingsInput>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="sellGasDelta"
            isInvalidHandler={isInvalid}
            placeholder="GWEI"
            onChange={handleOnChange}
            tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
          >
            Priority Fee
          </SettingsInput>
        </SnipeRow>
      </div>

      <div className="mt-4">
        <button className="btn wd-100p btn-light" onClick={handleSaving} disabled={isSaving}>
          <IconWithLoading isLoading={isSaving} />
          Save Settings
        </button>

        <button className="btn btn-transparent tx-white wd-100p mt-2" onClick={handleClose} disabled={isSaving}>
          Close
        </button>
      </div>
    </div>
  );
});

export default SnipeSettings;
