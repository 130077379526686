import React from 'react';
import classNames from 'classnames';
import { formatNumber } from '@helpers/numbers';
import { isNumber } from '@helpers/bignumber';
import { FormattedNumber } from './FormattedNumber';

interface LabelValueCardProps {
  label: string;
  value: number | string;
  cryptoBalance?: number | string;
  unit?: string;
  className?: string;
  valueClassName?: string;
  labelClassName?: string;
  isScore?: boolean;
}

export const LabelValueCard = ({
  label,
  value,
  unit,
  className = 'bg-semi-transparent-10 p-2',
  labelClassName = 'tx-muted tx-10 tx-medium',
  
  valueClassName = '',
  cryptoBalance,
}: LabelValueCardProps) => {
  const formattedValue = isNumber(value) ? formatNumber(value as number, unit) : value;

  return (
    <div className={classNames('w-100 card d-flex flex-column tx-start gap-2 p-2 rounded-2', className)}>
      <div className={classNames(labelClassName, 'text-nowrap')}>{label}</div>
      <div className={classNames(valueClassName, 'text-nowrap')}>{formattedValue}</div>
      {cryptoBalance && (
        <div className={classNames(valueClassName, 'text-nowrap')}>
          <FormattedNumber
            value={cryptoBalance}
            postfix={'ETH'}
            withSign={Number(value) < 0}
            // subZeros={unit === 'ETH' ? new BigNumber(value).abs().isLessThan(0.0001) : true}
          />
        </div>
      )}
    </div>
  );
};
