import {
  PositionModel,
  PositionStatus,
  PositionTransactionStatus,
  PositionTransactionType,
  PositionTrigger
} from "../types";
import {useMemo} from "react";
import {mockPositionModel} from "../mocks";


const usePositionData = (data: PositionModel | null) => {
  const position = useMemo(() => {
    return data || {
      ...mockPositionModel,
      trigger: PositionTrigger.AUTOTRADE,
      transactions: [],
      status: PositionStatus.OPEN,
      curPrice: '0',
      buyPrice: '0',
      buyAmount: 0,
    } as PositionModel;
  }, [data]);

  const isRobot = useMemo(() => {
    return position.trigger === PositionTrigger.AUTOTRADE;
  }, [position.trigger]);

  const transactions = useMemo(() => {
    return position.transactions || [];
  }, [position]);

  const isOpen = useMemo(() => {
    return position.status === PositionStatus.OPEN;
  }, [position.status]);

  const buyTransaction = useMemo(() => {
    return transactions.find((tx) => {
      if (position.status === PositionStatus.COMPLETED) {
        return tx.type === PositionTransactionType.BUY && tx.status === PositionTransactionStatus.CONFIRMED
      }
      return tx.type === PositionTransactionType.BUY;
    });
  }, [transactions]);

  const sellTransaction = useMemo(() => {
    return transactions.find((tx) => {
      if (position.status === PositionStatus.COMPLETED) {
        return tx.type === PositionTransactionType.SELL && tx.status === PositionTransactionStatus.CONFIRMED
      }
      return tx.type === PositionTransactionType.SELL;
    });
  }, [transactions]);

  const approveTransaction = useMemo(() => {
    return transactions.find((tx) => {
      if (position.status === PositionStatus.COMPLETED) {
        return tx.type === PositionTransactionType.APPROVE && tx.status === PositionTransactionStatus.CONFIRMED
      }
      return tx.type === PositionTransactionType.APPROVE;
    });
  }, [transactions]);

  const gasCost = useMemo(() => {
    return parseFloat(buyTransaction?.meta.gasFeeInEth || "0")
      + parseFloat(approveTransaction?.meta.gasFeeInEth || "0")
      + parseFloat(sellTransaction?.meta.gasFeeInEth || "0");
  }, [buyTransaction, approveTransaction, sellTransaction]);

  const soldAmount = useMemo(() => {
    const value = parseFloat(sellTransaction?.meta.receivedEth || "0")
    if (value) {
      return value;
    }

    if (!position.curPrice || !position.buyPrice || !position.buyAmount || !sellTransaction || !sellTransaction.meta.sellAmountPercent) {
      return 0;
    }

    return position.buyAmount * parseFloat(position.curPrice) / parseFloat(position.buyPrice) * sellTransaction.meta.sellAmountPercent / 100;
  }, [position.buyAmount, sellTransaction]);

  const realizedProfitEth = useMemo(() => {
    return soldAmount ? soldAmount - position.buyAmount - gasCost : 0;
  }, [soldAmount]);

  const positionEthValue = useMemo(() => {
    return realizedProfitEth
      ? realizedProfitEth
      : position.buyAmount * parseFloat(position.curPrice || "0") / parseFloat(position.buyPrice || "1")
  }, [position, realizedProfitEth]);

  const positionDiffPercent = useMemo(() => {
    return realizedProfitEth
      ? realizedProfitEth / position.buyAmount * 100
      : (parseFloat(position.curPrice || "0") / parseFloat(position.buyPrice || "1") - 1) * 100
  }, [position, realizedProfitEth]);

  return {
    isRobot,
    isOpen,
    buyTransaction,
    sellTransaction,
    approveTransaction,
    gasCost,
    soldAmount,
    realizedProfitEth,
    positionEthValue,
    positionDiffPercent,
  };
}

export default usePositionData;
