import Badge from "@components/common/Badge";

export interface SideNavItemProps {
  icon: string;
  title: string;
  onClick?: () => void;
  label?: string;
  isLast?: boolean;
}

export default function SideNavItem({icon, title, onClick, label, isLast}: SideNavItemProps) {
  return (
    <div className={`sidenav-item d-flex justify-content-start align-items-center ${onClick ? 'cur-pointer' : ''}`} onClick={onClick}>
      <div className="sidenav-icon mx-3">
        <img src={icon} alt={title} width={20} />
      </div>
      <div className={`d-flex justify-content-between align-items-center wd-100p py-3 ${!isLast ? 'border-bottom border-gray': ''} pe-3`}>
        <div>{title}</div>
        {!!label && <Badge label={label} />}
      </div>
    </div>
  );
};
